import { createSelector } from "reselect";

const flagsDomain = () => (state) => state.accountFlags;
const accountFlagsDomain = () => (state) => state.accountFlags.accounts;
const adminUser = () => (state) => state.user;
const selectedAccountIDDomain = () => (state) =>
    state.selectedMember.get("accountID");
const selectedUserAccountFlags = () =>
    createSelector(
        accountFlagsDomain(),
        selectedAccountIDDomain(),
        (accounts, selectedAccountID) => {
            return accounts[selectedAccountID] || [];
        }
    );

const selectedUserAccountFlagsChanged = () =>
    createSelector(
        accountFlagsDomain(),
        selectedAccountIDDomain(),
        (accounts, selectedAccountID) => {
            return Boolean(
                accounts[selectedAccountID] &&
                    accounts[selectedAccountID].find((f) => f.action)
            );
        }
    );

const selectAccountFlagsContainer = () =>
    createSelector(
        flagsDomain(),
        adminUser(),
        selectedAccountIDDomain(),
        selectedUserAccountFlags(),
        selectedUserAccountFlagsChanged(),
        (
            subState,
            adminUser,
            selectedAccountID,
            selectedAccountFlags,
            userAccountFlagsChanged
        ) =>
            Object.assign({}, subState, {
                adminUser,
                selectedAccountID,
                selectedAccountFlags,
                userAccountFlagsChanged,
            })
    );

export default selectAccountFlagsContainer;
