import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";

import MouseIcon from "@material-ui/icons/Mouse";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { noselect } from "../../styles/common.css";

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles2();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  toggleContainer: {
    marginLeft: "0px",
  },
  table: {
    fontSize: "15px",
  },
  tableBody: {
    "& .MuiTableRow-root:nth-child(even)": {
      backgroundColor: "rgba(220, 220, 220, .5)",
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      fontSize: "15px",
    },
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "17px",
      color: "white",
    },
  },
  actionColumn: {
    "& .event-type-container": {
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      transition: "all 0.15s ease",
      "&:hover": {
        opacity: "0.5",
      },
    },
    "& .event-type-container .MuiSvgIcon-root": {
      marginRight: "10px",
    },
  },
  eventColumn: {
    width: "130px",
    "& .event-type-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "& .event-type-container .MuiSvgIcon-root": {
      marginRight: "15px",
    },
    "& .event-type-container .event-type-empty-icon": {
      width: "20px",
      height: "20px",
      marginRight: "15px",
    },
  },
});

const getEventTypeLabel = (eventType) => {
  switch (eventType) {
    case "emailClick":
      return (
        <span className="event-type-container">
          <MouseIcon />
          <span className="event-type">Clicked a link</span>
        </span>
      );
    case "emailSend":
      return (
        <span className="event-type-container">
          <MailOutlineIcon />
          <span className="event-type">Send an email</span>
        </span>
      );
    case "emailUnSubscribe":
      return (
        <span className="event-type-container">
          <MailOutlineIcon />
          <span className="event-type">Unsubscribed</span>
        </span>
      );
    case "emailSubscribe":
      return (
        <span className="event-type-container">
          <MailOutlineIcon />
          <span className="event-type">Subscribed</span>
        </span>
      );
    case "emailBounce":
      return (
        <span className="event-type-container">
          <MailOutlineIcon />
          <span className="event-type">Bounced</span>
        </span>
      );
    case "inAppSend":
      return (
        <span className="event-type-container">
          <CheckCircleIcon />
          <span className="event-type">In App Send</span>
        </span>
      );
    case "pushSend":
      return (
        <span className="event-type-container">
          <CheckCircleIcon />
          <span className="event-type">Push Send</span>
        </span>
      );
    default:
      return (
        <span className="event-type-container">
          <span className="event-type-empty-icon"></span>
          <span className="event-type">{eventType}</span>
        </span>
      );
  }
};

const getEventTypeDetails = (event) => {
  switch (event.eventType) {
    case "emailSend":
      return event.emailTemplate.subject;
    case "customEvent":
      return event.eventName;
    default:
      return "";
  }
};

const columns = [
  "Created At",
  "Event Type",
  "Channel",
  "Campaign",
  "Details",
  "Action",
];

export default function EventsTable(props) {
  const classes = useStyles();
  const {
    events,
    totalEvents,
    toggleEventFilter,
    setToggleEventFilter,
    openEmailView,
    channelCollection,
    campaignCollection,
  } = props;

  const rows = events.map((event) => {
    const createdDate = new Date(event.createdAt);
    return {
      createdDate: moment(createdDate).format("MMMM Do YYYY, h:mm:ss a"),
      eventType: event.eventType,
      messageId: event.messageId,
      channelName:
        event.channelId || event.channelIds
          ? getChannelNames(channelCollection, event)
          : "",
      details: getEventTypeDetails(event),
      campaignName: campaignCollection.hasOwnProperty(event.campaignId)
        ? campaignCollection[event.campaignId].name
        : "",
      campaignId: event.campaignId,
    };
  });
  function getChannelNames(channelCollection, event) {
    if (channelCollection.hasOwnProperty(event.channelId)) {
      return channelCollection[event.channelId].name;
    } else if (event.hasOwnProperty("channelIds")) {
      if (event.channelIds.length > 1) {
        const channelNames = event.channelIds
          .filter((channelId) => {
            if (channelCollection[channelId]) return true;
            return false;
          })
          .map((channelId, index) => {
            return <li key={index}>{channelCollection[channelId].name}</li>;
          });
        return <ul>{channelNames}</ul>;
      } else if (event.channelIds.length === 1) {
        const channelId = event.channelIds.pop();

        if (channelCollection[channelId])
          return channelCollection[channelId].name;
      }
    }
    return "";
  }
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleFilter = () => {
    setPage(0);
    setToggleEventFilter(!toggleEventFilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      style={{ maxWidth: "1300px", marginBottom: "100px" }}
      component={Paper}
    >
      {totalEvents != 0 ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={!toggleEventFilter}
              onChange={handleFilter}
              name="checkedA"
            />
          }
          label={`View All Elements (${totalEvents})`}
          className={`${classes.toggleContainer} ${noselect}`}
        />
      ) : (
        <span className={`${classes.toggleContainer} ${noselect}`}>
          No Events Found
        </span>
      )}
      {events.length > 0 && campaignCollection && channelCollection && (
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow className={`${classes.tableHeaderRow} ${noselect}`}>
              {columns.map((col, index) => {
                return <TableCell key={index}>{col}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow className={classes.tableRow} key={row.createdDate}>
                <TableCell
                  style={{ width: 180 }}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.createdDate}
                </TableCell>
                <TableCell classes={{ root: classes.eventColumn }}>
                  {getEventTypeLabel(row.eventType)}
                </TableCell>
                <TableCell style={{ width: 250 }} align="left">
                  {row.channelName}
                </TableCell>
                <TableCell style={{ width: 200 }} align="left">
                  {row.campaignName}
                </TableCell>
                <TableCell style={{ width: 200 }} align="left">
                  {row.details}
                </TableCell>
                <TableCell
                  classes={{ root: classes.actionColumn }}
                  align="left"
                >
                  {row.eventType === "emailSend" && (
                    <span
                      className="event-type-container"
                      onClick={() => openEmailView(row.messageId)}
                    >
                      <VisibilityIcon />
                      <span className="event-type">View</span>
                    </span>
                  )}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={columns.length}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </TableContainer>
  );
}
