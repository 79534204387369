import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestPrices() {
    return {
        type: constants.REQUEST_PRICES,
        isFetching: true,
    };
}

function recievePrices(data) {
    return {
        type: constants.RECEIVE_PRICES,
        isFetching: false,
        data,
    };
}

const zones = ["LZ_HOUSTON", "LZ_NORTH", "LZ_SOUTH", "LZ_WEST"];
const zoneByIndex = {
    LZ_HOUSTON: 0, LZ_NORTH: 1, LZ_SOUTH: 2, LZ_WEST: 3,
};

export default function fetchPrices(member) {
    return (dispatch) => {
        dispatch(requestPrices());
        let apiCount = zones.length;
        const path = constants.GET_PRICES;
        const prices = zones.slice();
        const date = new Date().toISOString();
        const searchObj = {
            date,
            memberID: member,
        };
        let idx = 0;

        zones.forEach((v) => {
            searchObj.settlement_point = v;
            fetch(path, searchObj).then((data) => {
                idx = zoneByIndex[v];
                prices[idx] = {};
                const abbreviatedZone = v.slice(3);
                prices[idx].zone = abbreviatedZone;
                prices[idx].price = data.now.price_display;
                prices[idx].price_sign = data.now.price_display_sign;
                prices[idx].value_score = data.now.value_score;
                apiCount -= 1;
                if (apiCount === 0) {
                    return dispatch(recievePrices(prices));
                }
            });
        });
    };
}
