import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl } from "@material-ui/core/";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import MaskedInput from "react-text-mask";
import MemberSearchResult from "../components/members/MemberSearchResult";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { confirmationError } from "../styles/common.css";

import {
  search,
  resultsNextPage,
  setCurrentMember,
  clearSearchResults,
  setIsLoading,
} from "../actions/search";
import styles from "../styles/members.css";
import { hrText } from "../styles/common.css";

const fieldStyle = { margin: "20px" };

function PhoneNumberMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /[1-9]/,
        " ",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}

const MemberStyles = (theme) => ({
  badge: {
    top: 0,
    right: -30,
    borderRadius: 4,
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  searchButtonPanel: {
    "& .MuiButton-root:nth-child(1)": {
      marginRight: "20px",
    },
  },
  primaryButton: {
    backgroundColor: "#3f51b5",
    color: "white",
  },
  disableButton: {
    cursor: "default",
    backgroundColor: "rgba(0,0,0,0.2)",
    color: "rgba(0,0,0,0.5)",
  },
});

class Members extends Component {
  constructor() {
    super();
    this.searchResultSection = React.createRef();
    this.state = {
      memberID: "",
      esiID: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      streetAddress: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      userId: "",
      showSnackbar: false,
      toggleSelectMemberButton: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.clearFields = this.clearFields.bind(this);
  }
  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown.bind(this));
    this.props.clearResults();
  }

  prepareSubmit() {
    const phoneCleaned = `+${this.state.phone.replace(/\D/g, "")}`;
    const phoneField = ["+", "+1"].includes(phoneCleaned) ? "" : phoneCleaned;

    const searchFields = {
      user_id: this.state.userId,
      esiID: this.state.esiID,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone: phoneField,
      address: {
        street_address: this.state.streetAddress,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        postal_code: this.state.postalCode,
      },
    };

    return searchFields;
  }

  scrollToPos(pos) {
    window.scroll({
      top: pos,
      left: 0,
      behavior: "smooth",
    });
  }

  handleSubmit() {
    const { onSearch, setIsLoading } = this.props;
    const fields = this.prepareSubmit();
    if (fields) {
      setIsLoading(true);
      onSearch(fields);
    }
  }

  handleNextClick(page) {
    const { loadNextPage, setIsLoading } = this.props;
    const fields = this.prepareSubmit();
    if (fields) {
      setIsLoading(true);
      loadNextPage({ fields, page });
    }
  }

  clearFields() {
    this.setState({
      memberID: "",
      toggleSelectMemberButton: false,
      userId: "",
      esiID: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      streetAddress: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    });
  }

  handlePhoneFocusChange = (name) => (event) => {
    const phone = this.state.phone;
    event.persist();

    if (name === "focus" && phone === "") {
      this.setState({ phone: "1" }, () => {
        event.target.selectionStart = 3;
        event.target.secctionEnd = 3;
      });
    } else if (name === "blur" && phone === "1") {
      this.setState({ phone: "" });
    }
  };

  handleKeyDown(event) {
    switch (event.keyCode) {
      case 13: // Enter Key
        if (this.state.memberID === "") {
          this.handleSubmit();
        } else {
          window.location = `/members/${this.state.memberID}/details`;
        }
        break;
      default:
        break;
    }
  }

  handleChange = (name) => (event) => {
    if (name === "memberID") {
      this.setState({
        toggleSelectMemberButton: event.target.value === "" ? false : true,
      });
    }
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  render() {
    const {
      guest,
      selectCurrentMember,
      results,
      selectedMember,
    } = this.props;
    const { classes } = this.props;
    const isLoading = results.get("isLoading");
    const members = results.get("members");
    const hasMoreResults = results.get("hasNext");
    const searchPage = Number(results.get("page")) + 1;

    return (
      <div className={styles.membersWrapper}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.showSnackbar}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <span id="message-id" className={confirmationError}>
            {this.state.validation}
          </span>
        </Snackbar>
        <div>
          <TextField
            autoFocus
            label="Member ID"
            id="memberID"
            style={fieldStyle}
            onChange={this.handleChange("memberID")}
            value={this.state.memberID}
            type="text"
          />
          {
            <Button
              classes={{
                root: this.state.toggleSelectMemberButton
                  ? classes.primaryButton
                  : classes.disableButton,
              }}
              variant="contained"
              onClick={() => {
                if (this.state.toggleSelectMemberButton)
                  window.location = `/members/${this.state.memberID}/details`;
              }}
            >
              {"Select Member"}
            </Button>
          }
        </div>
        <hr className={hrText} data-content="Search Using Account Info" />
        <div>
          <TextField
            autoFocus
            label="ESI ID"
            id="esiID"
            style={fieldStyle}
            onChange={this.handleChange("esiID")}
            value={this.state.esiID}
            type="text"
          />
        </div>
        <div>
          <TextField
            label="Account Number"
            id="user_id"
            style={fieldStyle}
            onChange={this.handleChange("userId")}
            value={this.state.userId}
            type="text"
          />
        </div>
        <hr className={hrText} data-content="OR" />
        <div>
          <TextField
            label="First Name"
            id="first_name"
            style={fieldStyle}
            onChange={this.handleChange("firstName")}
            value={this.state.firstName}
            type="text"
          />
          <TextField
            label="Last Name"
            id="last_name"
            style={fieldStyle}
            onChange={this.handleChange("lastName")}
            value={this.state.lastName}
            type="text"
          />
        </div>
        <div>
          <FormControl style={fieldStyle}>
            <InputLabel htmlFor="phone">Phone</InputLabel>
            <Input
              id="phone"
              onChange={this.handleChange("phone")}
              value={this.state.phone}
              onFocus={this.handlePhoneFocusChange("focus")}
              onBlur={this.handlePhoneFocusChange("blur")}
              type="text"
              inputComponent={PhoneNumberMask}
            />
          </FormControl>
          <TextField
            label="Email"
            id="email"
            style={fieldStyle}
            onChange={this.handleChange("email")}
            value={this.state.email}
            type="text"
          />
        </div>
        <div>
          <TextField
            label="Street Address"
            id="street_address"
            style={{ margin: "20px", width: "30%" }}
            onChange={this.handleChange("streetAddress")}
            value={this.state.streetAddress}
            type="text"
          />
        </div>
        <div>
          <TextField
            label="City"
            id="city"
            style={fieldStyle}
            onChange={this.handleChange("city")}
            value={this.state.city}
            type="text"
          />
          <TextField
            label="State"
            id="state"
            style={fieldStyle}
            onChange={this.handleChange("state")}
            value={this.state.state}
            type="text"
          />
        </div>
        <div>
          <TextField
            label="Country"
            id="country"
            style={fieldStyle}
            onChange={this.handleChange("country")}
            value={this.state.country}
            type="text"
          />
          <TextField
            label="Zip Code"
            id="postal_code"
            style={fieldStyle}
            onChange={this.handleChange("postalCode")}
            value={this.state.postalCode}
            type="text"
          />
        </div>
        <div className={classes.searchButtonPanel}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleSubmit()}
          >
            {"Search"}
          </Button>
          <Button variant="contained" onClick={() => this.clearFields()}>
            {"Clear"}
          </Button>
        </div>
        <div ref={this.searchResultSection} style={{overflowX: "auto"}}>
          <MemberSearchResult
            guest={guest}
            members={members}
            selectCurrentMember={selectCurrentMember}
          />
        </div>
        {hasMoreResults && (
          <Button
            color="primary"
            varian="raised"
            onClick={() => {
              this.handleNextClick(searchPage);
            }}
          >
            More results
          </Button>
        )}
        <Button
          color="secondary"
          varian="raised"
          onClick={() => this.scrollToPos(0)}
        >
          Move To Top
        </Button>
        <Backdrop className={classes.backdropStyle} open={isLoading}>
          <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
      </div>
    );
  }
}

Members.propTypes = {
  members: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  selectCurrentMember: PropTypes.func.isRequired,
  clearResults: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

Members.defaultProps = {
  members: [],
};

const mapStateToProps = (state) => ({
  results: state.memberSearchResult,
  guest: state.guest,
  selectedMember: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (fields) => dispatch(search(fields)),
  selectCurrentMember: (member) => dispatch(setCurrentMember(member)),
  loadNextPage: (opts) => dispatch(resultsNextPage(opts)),
  clearResults: () => dispatch(clearSearchResults()),
  setIsLoading: (isLoading) => dispatch(setIsLoading(isLoading)),
});

const ConnectedMember = connect(mapStateToProps, mapDispatchToProps)(Members);

export default withStyles(MemberStyles)(ConnectedMember);
