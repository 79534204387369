import { createSelector } from "reselect";

const signupDomain = () => (state) => state.signup;
const adminUser = () => (state) => state.user;
const isUserCanBeCreated = () =>
    createSelector(signupDomain(), (subState) =>
        Boolean(
            !subState.userID && subState.email 
        )
    );

const isMemberCanBeCreated = () =>
    createSelector(signupDomain(), (subState) =>
        Boolean(
            subState.userID && !subState.memberID && subState.birthday && subState.first_name && subState.last_name
        )
    );

const selectSignupContainer = () =>
    createSelector(
        signupDomain(),
        adminUser(),
        isUserCanBeCreated(),
        isMemberCanBeCreated(),
        (subState, adminUser, canCreateUser, canCreateMember) =>
            Object.assign({}, subState, { adminUser, canCreateUser, canCreateMember }),
    );

export default selectSignupContainer;
