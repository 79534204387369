import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { StripeProvider } from 'react-stripe-elements';
import routes from "../routes";

const Root = ({ store, history }) => (
    <StripeProvider apiKey="pk_live_V0odfXwKAbNa9ZDKPqqHMhsG">
    <Provider store={ store }>
        <Router history={ history } routes={ routes } />
    </Provider>
    </StripeProvider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default Root;
