import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import JSONInput from "react-json-ide";
import locale from "react-json-ide/locale/en";
import IconButton from "@material-ui/core/IconButton";
import PlayArrow from "@material-ui/icons/PlayArrow";
import LinkIcon from "@material-ui/icons/Link";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import AssignMeterTransaction from "./AssignMeterTransaction";
import ConfirmTransactionProcessing from "./ConfirmTransactionProcessing";

const MeterTransactionDetail = ({
    selectedTran,
    selectedIndex,
    transactionData,
    handleChange,
    handleEditorChange,
    setSelectedTransaction,
    postUpdateTransaction,
    transactionIsModified,
    processTransaction,
    openAssignMeter,
    searchESI,
    searchMeterResult,
    searchMeters,
    updateState,
    isProcessing,
    openConfirmTransactionProcessing,
    assignMeterToTransaction,
    handelConfirmProcessTransaction,
    classes,
}) => {
    const [showAllFields, setShowAllFields] = useState(false);
    const [editorJsonData, setEditorJsonData] = useState({});
    const [processedTransaction, setProcessedTransaction] = useState(
        selectedTran.processingStatus
    );
    const getJsonData = (json) => {
        let copyJson = JSON.parse(JSON.stringify(json));
        if (!showAllFields) {
            Object.values(copyJson).forEach((o) =>
                Object.entries(o).forEach(([k, v]) => {
                    if (v === "") {
                        delete o[k];
                    }
                })
            );
        }
        return copyJson;
    };

    useEffect(() => {
        setEditorJsonData(getJsonData(transactionData));
    }, [showAllFields]);

    //create hook to re-render when editordata is changed when showAllFields is toggled
    useEffect(() => {}, [editorJsonData]);
    return (
        <Dialog
            open={selectedIndex !== -1}
            contentlabel="Transaction Raw Data"
            ariaHideApp={false}
            fullWidth={true}
            maxWidth={"lg"}
            classes={{
                paper: classes.dialog,
            }}
        >
            <DialogTitle>
                Transaction Information: {selectedTran.transactionTypeName}{" "}
                {selectedTran.meterID === "0" ? "(unassigned)" : ""}
                <div className={classes.icons}>
                    {selectedTran.meterID === "0" &&
                        selectedTran.processingStatus !== "Yes" && (
                            <IconButton
                                aria-label="Link Meter"
                                size="large"
                                title="Link Meter"
                                classes={{ label: classes.iconButtonLabel }}
                                color="primary"
                                onClick={() => {
                                    updateState({
                                        openAssignMeter: true,
                                        searchESI:
                                            editorJsonData.utility_account_number,
                                    }),
                                        searchMeters(
                                            editorJsonData.utility_account_number
                                        );
                                }}
                            >
                                <LinkIcon fontSize="large" />
                            </IconButton>
                        )}
                    {selectedTran.processingStatus !== "Yes" &&
                        selectedTran.direction === "I" && (
                            <IconButton
                                aria-label="process transaction"
                                size="large"
                                title="Process"
                                classes={{ label: classes.iconButtonLabel }}
                                color="primary"
                                onClick={() =>
                                    processTransaction(selectedTran.id)
                                }
                            >
                                <PlayArrow fontSize="large" />
                            </IconButton>
                        )}
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container>
                        <Grid item md={3}>
                            <List>
                                <ListItem>
                                    <TextField
                                        size="small"
                                        style={{
                                            width: "80%",
                                        }}
                                        label="Transaction Date"
                                        value={selectedTran.transactionDate}
                                        type="string"
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        style={{
                                            width: "80%",
                                        }}
                                        size="small"
                                        label="Utility Account No"
                                        value={
                                            selectedTran.utilityAccountNumber
                                        }
                                        type="string"
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        select
                                        label="Type"
                                        disabled
                                        size="small"
                                        style={{
                                            width: "80%",
                                        }}
                                        value={selectedTran.transactionTypeName}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "transactionTypeName",
                                        }}
                                    >
                                        <MenuItem value="Enrollment">
                                            Enrollment
                                        </MenuItem>
                                        <MenuItem value="Switch">
                                            Switch
                                        </MenuItem>
                                        <MenuItem value="Drop">Drop</MenuItem>
                                        <MenuItem value="Cancel Switch">
                                            Cancel Switch
                                        </MenuItem>
                                        <MenuItem value="Date Change">
                                            Date Change
                                        </MenuItem>
                                        <MenuItem value="Drop Enrollment">
                                            Drop Enrollment
                                        </MenuItem>
                                        <MenuItem value="Drop Enrollment">
                                            Drop
                                        </MenuItem>
                                        <MenuItem value="Move In">
                                            Move In
                                        </MenuItem>
                                        <MenuItem value="CSA">CSA</MenuItem>
                                        <MenuItem value="ESI Maintenance">
                                            ESI Maintenance
                                        </MenuItem>
                                        <MenuItem value="CSA Move In">
                                            CSA Move In
                                        </MenuItem>
                                        <MenuItem value="Move Out">
                                            Move Out
                                        </MenuItem>
                                        <MenuItem
                                            value="Historical
                                    Usage"
                                        >
                                            Historical Usage
                                        </MenuItem>
                                        <MenuItem value="Completed Unexecutable">
                                            Completed Unexecutable
                                        </MenuItem>
                                        <MenuItem value="Permit Required">
                                            Permit Required
                                        </MenuItem>
                                        <MenuItem value="Customer Maintenance">
                                            Customer Maintenance
                                        </MenuItem>
                                        <MenuItem value="Invoice">
                                            Invoice
                                        </MenuItem>
                                        <MenuItem value="SO Disconnect">
                                            SO Disconnect
                                        </MenuItem>
                                        <MenuItem value="SO Reconnect">
                                            SO Reconnect
                                        </MenuItem>
                                        <MenuItem value="SO Switch Hold">
                                            SO Switch Hold
                                        </MenuItem>
                                        <MenuItem value="SO Meter Maintenance SO">
                                            SO Meter Maintenance SO
                                        </MenuItem>
                                        <MenuItem value="SO Meter Test">
                                            SO Meter Test
                                        </MenuItem>
                                        <MenuItem value="SO Obligation Inquiry SO">
                                            SO Obligation Inquiry SO
                                        </MenuItem>
                                        <MenuItem value="SO Inquiry">
                                            SO Inquiry
                                        </MenuItem>
                                        <MenuItem value="SO Change Order">
                                            SO Change Order
                                        </MenuItem>
                                        <MenuItem value="SO Read">
                                            SO Read
                                        </MenuItem>
                                        <MenuItem value="SO Facilities Investigation">
                                            SO Facilities Investigation
                                        </MenuItem>
                                        <MenuItem value="Suspension of Delivery">
                                            Suspension of Delivery
                                        </MenuItem>
                                    </TextField>
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        select
                                        label="Sub-Type"
                                        disabled
                                        size="small"
                                        style={{
                                            width: "80%",
                                        }}
                                        disabled
                                        value={selectedTran.transactionSubType}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "transactionSubType",
                                        }}
                                    >
                                        <MenuItem value="Request">
                                            Request
                                        </MenuItem>
                                        <MenuItem value="Accept">
                                            Accept
                                        </MenuItem>
                                        <MenuItem value="Reject">
                                            Reject
                                        </MenuItem>
                                    </TextField>
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        select
                                        label="Direction"
                                        disabled
                                        size="small"
                                        style={{
                                            width: "80%",
                                        }}
                                        disabled
                                        value={selectedTran.direction}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "direction",
                                        }}
                                    >
                                        <MenuItem value="I">Inbound</MenuItem>
                                        <MenuItem value="O">Outbound</MenuItem>
                                    </TextField>
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        select
                                        label="Processing Status"
                                        disabled
                                        size="small"
                                        style={{
                                            width: "80%",
                                        }}
                                        value={selectedTran.processingStatus}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "processingStatus",
                                        }}
                                        disabled={
                                            processedTransaction === "Yes" ||
                                            processedTransaction === "Sent"
                                        }
                                    >
                                        <MenuItem
                                            value="Yes"
                                            disabled={
                                                selectedTran.meterID === "0"
                                            }
                                        >
                                            Yes
                                        </MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                        <MenuItem value="Hold">Hold</MenuItem>
                                        <MenuItem value="Error">Error</MenuItem>
                                        <MenuItem value="Ignore">
                                            Ignore
                                        </MenuItem>
                                        <MenuItem value="Sent">Sent</MenuItem>
                                    </TextField>
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        name="reason"
                                        size="small"
                                        style={{
                                            marginRight: "20px",
                                            width: "80%",
                                        }}
                                        label="Reason"
                                        value={selectedTran.reason}
                                        type="string"
                                        onChange={handleChange}
                                        disabled
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        name="processingError"
                                        style={{
                                            marginRight: "20px",
                                            width: "80%",
                                        }}
                                        label="Processing Error"
                                        value={selectedTran.processingError}
                                        type="string"
                                        onChange={handleChange}
                                        multiline
                                        rows={5}
                                        readonly
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={9}>
                            <h4>Data Editor</h4>

                            <JSONInput
                                id="transactionData"
                                placeholder={editorJsonData}
                                locale={locale}
                                height="460px"
                                width="100%"
                                viewOnly
                                reset
                                onChange={handleEditorChange}
                            />

                            <div style={{ flex: "1 0 0" }} />
                        </Grid>
                    </Grid>
                </DialogContentText>
                {openAssignMeter && (
                    <AssignMeterTransaction
                        open={openAssignMeter}
                        searchESI={searchESI}
                        searchMeters={searchMeters}
                        searchMeterResult={searchMeterResult}
                        handleChange={updateState}
                        handleAssignMeter={assignMeterToTransaction}
                        classes={classes}
                        isProcessing={isProcessing}
                    />
                )}
                {openConfirmTransactionProcessing && (
                    <ConfirmTransactionProcessing
                        handleProcessTransaction={
                            handelConfirmProcessTransaction
                        }
                        classes={classes}
                        handleClose={() => {
                            updateState({
                                openConfirmTransactionProcessing: false,
                            });
                        }}
                    />
                )}
                <Backdrop className={classes.backdrop} open={isProcessing}>
                    <ClipLoader
                        sizeUnit="px"
                        size={100}
                        color="#ED0874"
                        loading
                    />
                </Backdrop>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        postUpdateTransaction();
                        setProcessedTransaction(selectedTran.processingStatus);
                    }}
                    color="secondary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 10,
                        marginBottom: 10,
                    }}
                    disabled={!transactionIsModified}
                >
                    Save
                </Button>
                <Button
                    onClick={() => {
                        setSelectedTransaction(-1);
                    }}
                    color="primary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 20,
                        marginBottom: 10,
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

MeterTransactionDetail.propTypes = {
    handleChange: PropTypes.func,
    handleEditorChange: PropTypes.func,
    processTransaction: PropTypes.func,
    selectedIndex: PropTypes.number,
    selectedTran: PropTypes.shape({
        direction: PropTypes.any,
        id: PropTypes.any,
        processingError: PropTypes.any,
        processingStatus: PropTypes.string,
        reason: PropTypes.any,
        transactionDate: PropTypes.any,
        transactionSubType: PropTypes.any,
        transactionTypeName: PropTypes.any,
    }),
    setSelectedTransaction: PropTypes.func,
    transactionData: PropTypes.any,
    transactionIsModified: PropTypes.any,
};

export default MeterTransactionDetail;
