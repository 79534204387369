import * as C from "../../constants";

const initialState = {
  user: {},
  messages: [],
  events: [],
  emailView: [],
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
  isProcessing: false,
  channels: [],
  campaignCollection: {},
  channelCollection: {},
  templateCollection: {}
};

const clearState = {
  user: {},
  messages: [],
  events: [],
  emailView: [],
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
  isProcessing: false,
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

const updateState = (state, action) => {
  return {
    ...state,
    ...action.updatedInfo,
  };
};

const updateUserInfo = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const updateEmailView = (state, action) => {
  return {
    ...state,
    emailView: action.payload,
  };
};

const updateTemplateCollection = (state, action) => {
  let { templateId, template } = action.payload;
  const templates = state.templateCollection;
  templates[templateId] = template;
  return {
    ...state,
    templateCollection: templates,
  };
};

const updateChannelCollection = (state, action) => {
  return {
    ...state,
    ["channelCollection"]: action.payload,
  };
};

const updateCampaignCollection = (state, action) => {
  return {
    ...state,
    ["campaignCollection"]: action.payload,
  };
};

const clearUserState = (state) => {
  return {...state, ...clearState}
}
export default function iterable(state = initialState, action) {
  switch (action.type) {
    case C.ITERABLE_CLOSE_SNACKBAR:
      return closeSnackBar(state);
    case C.ITERABLE_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case C.ITERABLE_UPDATE_USER_INFO:
      return updateUserInfo(state, action);
    case C.ITERABLE_UPDATE_STATE:
      return updateState(state, action);
    case C.ITERABLE_REQUEST_SUCCESS:
      return updateUserInfo(state, action);
    case C.ITERABLE_SET_EMAIL_VIEW:
      return updateEmailView(state, action);
    case C.ITERABLE_SET_CAMPAIGN_COLLECTION:
      return updateCampaignCollection(state, action);
    case C.ITERABLE_SET_CHANNEL_COLLECTION:
      return updateChannelCollection(state, action);
    case C.ITERABLE_ADD_TO_TEMPLATES:
      return updateTemplateCollection(state, action);
    case C.CLEAR_ITERABLE_DATA :
      return clearUserState(state);
    default:
      return state;
  }
}
