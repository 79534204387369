import Immutable from "immutable";
import * as constants from "../../constants";

const defaultBillingSummaryState = Immutable.fromJS({
  group_items: [],
  rechargeList: [],
  membership: {},
  chargeDetails: [],
  usageDetails: [],
  usageSummaries: {},
  isFetching: false,
});

const defaultTransactionsState = {
  transactions: [],
  deposits: [],
  page: 0,
  hasNext: false,
  next: 0,
  isFetching: false,
};

const defaultbillingAdjustmentMsg = Immutable.fromJS({});
const defaultBillingUsageReport = Immutable.fromJS({});

export function billingSummaryResult(
  state = defaultBillingSummaryState,
  action
) {
  switch (action.type) {
    case constants.RECEIVE_BILLING_SUMMARY:
      return Immutable.fromJS({
        ...state.toJS(),
        ...action.data,
        isFetching: action.isFetching,
      });
    case constants.REQUEST_BILLING_SUMMARY:
      return Immutable.fromJS({
        ...state.toJS(),
        isFetching: action.isFetching,
      });
    case constants.RECEIVE_BILLING_SUMMARY_DETAILS:
      return Immutable.fromJS({
        ...state.toJS(),
        ...action.payload,
      });
    case constants.RECEIVE_BILLING_SUMMARY_USAGE_DETAILS:
      // usageSummaries
      return Immutable.fromJS({
        ...state.toJS(),
        usageSummaries: {
          ...state.get("usageSummaries")?.toJS(),
          ...action.payload,
        },
      });
    case constants.SET_BILLING_FETCHING_STATE:
      return Immutable.fromJS({
        ...state.toJS(),
        isFetching: action.isFetching,
      });
    default:
      return state;
  }
}

export function billingAdjustmentMsg(
  state = defaultbillingAdjustmentMsg,
  action
) {
  switch (action.type) {
    case constants.RECEIVE_BILLING_ADJUSTMENT:
      return Immutable.fromJS({
        response: action.response,
        showSnackBar: true,
        isFetching: action.isFetching,
      });
    case constants.HIDE_SNACKBAR:
      return state.merge(Immutable.fromJS({ showSnackBar: false }));
    default:
      return state;
  }
}

export function billingUsageReport(state = defaultBillingUsageReport, action) {
  switch (action.type) {
    case constants.RECEIVE_BILLING_USAGE_REPORT:
      return state.merge(Immutable.fromJS(action.data));
    default:
      return state;
  }
}

export function billingTransactions(state = defaultTransactionsState, action) {
  switch (action.type) {
    case constants.RECEIVED_ALL_BILLING_TRANSACTIONS: {
      const { transactions, page, has_next: hasNext, next } = action.payload;
      const nextState = {
        transactions:
          Number(page) === 1
            ? transactions
            : state.transactions.concat(transactions),
        deposits: state.deposits,
        page: Number(page),
        hasNext,
        next,
        isFetching: false,
      };
      return nextState;
    }
    case constants.RECEIVED_BILLING_TRANSACTIONS: {
      const { deposits, page, has_next: hasNext, next } = action.payload;
      const nextState = {
        ...state,
        transactions: state.transactions,
        deposits:
          Number(page) === 1 ? deposits : state.deposits.concat(deposits),
        page: Number(page),
        hasNext,
        next,
      };
      return nextState;
    }
    case constants.REQUEST_BILLING_TRANSACTIONS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case constants.CLEAR_DISPUTE_INFO:
      return defaultTransactionsState;
    default:
      return state;
  }
}