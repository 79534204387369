import React, { useRef, useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { noselect } from "../../styles/common.css";
import BillingUsageRows from "./BillingUsageRows";

const useStyles = makeStyles({
  tableContainer: {
    margin: "15px",
    width: "100%",
    paddingRight: "30px",
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      padding: "10px",
      fontSize: "14px",
      color: "white",
      backgroundColor: "#333",
      whiteSpace: "pre",
    },
  },
  chargesTotalRow: {
    borderTop: "2px solid rgba(0, 0, 0, 0.35)",
    "& #total-label-cell": {
      fontWeight: "bold",
      textAlign: "end",
    },
    "& #total-label-cell, #total-cell": {
      fontSize: "15px",
      paddingLeft: "10px",
    },
  },
  overflowFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: "10px",
    position: "relative",
    top: "-100px",
    marginBottom: "-100px",
    "& #overflowObject": {
      height: "100px",
      width: "100%",
      transition: "all 0.3s linear",
      backgroundImage: "linear-gradient(180deg, transparent, white)",
    },
    "& #overflowOptions": {
      background: "white",
      display: "flex",
      justifyContent: "flex-end",
      padding: "5px 0px",
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        color: "black",
        transition: "all 0.3s ease",
        "&:hover": {
          color: "grey",
        },
      },
    },
  },
});

const ChargesByTransactionTable = (props) => {
  const classes = useStyles();
  const {
    memberID,
    details,
    money,
    doRequestUsageSummaryByDate,
    usageSummaries,
  } = props;
  const tableView = useRef();
  const [toggleOverflowFooter, setToggleOverFlowFooter] = useState(false);
  const TABLE_HEIGHT = 550;
  useEffect(() => {
    // Add fading footer if the scroll height exceeds the table height
    const scrollHeight = tableView.current.scrollHeight;
    if (scrollHeight > TABLE_HEIGHT) {
      setToggleOverFlowFooter(true);
    } else {
      setToggleOverFlowFooter(false);
    }
  }, [tableView]);

  // When reaching the end of the scroll amount, clear out the fading footer
  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === TABLE_HEIGHT) {
      setToggleOverFlowFooter(false);
    } else {
      setToggleOverFlowFooter(true);
    }
  };

  return (
    <div>
      <div
        style={{ height: "550px", overflow: "auto" }}
        onScroll={(e) => handleScroll(e)}
        ref={tableView}
      >
        <Table className={classes.tableContainer} stickyHeader>
          <TableHead className={`${classes.tableHeaderRow} ${noselect}`}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Transaction Date</TableCell>
              <TableCell align="center">Electricity Usage</TableCell>
              <TableCell align="center">Ancillary Services</TableCell>
              <TableCell align="center">TDU Delivery Charges</TableCell>
              <TableCell align="center">Griddy Membership</TableCell>
              <TableCell align="center">Taxes &amp; Fees</TableCell>
              <TableCell align="center">Other</TableCell>
              <TableCell align="center">Deposit</TableCell>
              <TableCell align="center">End of Day Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details && details.length > 0 ? (
              details.map((transaction, index) => {
                return (
                  <BillingUsageRows
                    key={index}
                    index={index}
                    usageSummaries={usageSummaries}
                    money={money}
                    transaction={transaction}
                    doRequestUsageSummaryByDate={doRequestUsageSummaryByDate}
                    memberID={memberID}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9}>No Records Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {/* Footer Fade Tempoarily Removed due to different browser views */}
      {false && (
        <div className={classes.overflowFooter}>
          <div id="overflowObject"></div>
        </div>
      )}
    </div>
  );
};

export default ChargesByTransactionTable;
