import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table";
import detailStyles from "../styles/memberDetails.css";
import { fetchRateClasses, fetchMeter, updateGriddyRateClass, overrideRateClass, removeOverride } from "../actions/meters";
import { commafy, capitalizeFirstLetter } from "../util/utilities";

class MemberBusiness extends Component {
    constructor(props) {
        super(props);

        this.state = {
            avgUsage: "",
            membershipOverride: "",
        };
    }

    componentWillMount() {
        const { doFetchRateClasses, doFetchMeter } = this.props;
        const meterQuery = {
            memberID: this.props.params.memberId,
            include_inactive: false,
        };
        doFetchMeter(meterQuery);
        doFetchRateClasses();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.tierName !== nextProps.tierName ||
            this.props.monthlyMembership !== nextProps.monthlyMembership ||
            this.props.avgMonthlyUsage !== nextProps.avgMonthlyUsage) {
            this.forceUpdate();
        }
    }

    updateAvgUsageValue(evt) {
        this.setState({
            avgUsage: evt.target.value,
        });
    }

    updateMembershipOverrideValue(evt) {
        this.setState({
            membershipOverride: evt.target.value,
        });
    }

    updateAvgUsageClicked(avgUsage, meterId) {
        const { doUpdateGriddyRateClass, rateClasses } = this.props;
        const membershipAmount = rateClasses.filter(rateClass =>
            (parseInt(rateClass.min_usage, 10) <= parseInt(avgUsage, 10)) &&
            (parseInt(rateClass.max_usage, 10) > parseInt(avgUsage, 10)));

        if (membershipAmount.first()) {
            if (confirm(`Based on the entered Average Monthly Usage: ${avgUsage} kWh, this member will be in ${capitalizeFirstLetter(membershipAmount.first().tier_name)}`)) {
                const meterQuery = {
                    memberID: this.props.params.memberId,
                    include_inactive: false,
                };
                doUpdateGriddyRateClass(avgUsage, meterId, meterQuery);
            }
        }
    }

    overrideMembershipClicked(membershipOverride, meterId) {
        const { doOverrideRateClass } = this.props;

        if (confirm(`Confirm override to $${membershipOverride}/month`)) {
            const meterQuery = {
                memberID: this.props.params.memberId,
                include_inactive: false,
            };
            doOverrideRateClass(membershipOverride, meterId, meterQuery);
        }
    }

    render() {
        const {
            monthlyMembership,
            rateClasses,
            meter,
            tierName,
            avgMonthlyUsage,
            doRemoveOverride,
        } = this.props;

        const { avgUsage, membershipOverride } = this.state;

        const membershipAmount = rateClasses.filter(rateClass =>
            (parseInt(rateClass.min_usage, 10) <= parseInt(avgMonthlyUsage, 10)) &&
            (parseInt(rateClass.max_usage, 10) > parseInt(avgMonthlyUsage, 10)));

        const columns = [{
            Header: "Tier Name",
            accessor: "tier_name",
            Cell: row => (
                <div>
                    {capitalizeFirstLetter(row.value)}
                </div>
            ),
        }, {
            Header: "Usage Range/kWh",
            columns: [{
                Header: "Min Usage",
                accessor: "min_usage",
                Cell: row => (
                    <div>
                        {commafy(row.value)}
                    </div>
                ),
            },
            {
                Header: "Max Usage",
                accessor: "max_usage",
                Cell: row => (
                    <div>
                        {commafy(row.value)}
                    </div>
                ),
            },
            ],
        }, {
            Header: "Membership",
            accessor: "membership_rate",
            Cell: row => (
                <div>
                    ${commafy(row.value)}
                </div>
            ),
        }];

        return (
            <div className={ detailStyles.detailsWrapper }>
                <h4>Business</h4>
                <br />
                <div>
                    <b>Business Tier: </b>
                    {tierName ? capitalizeFirstLetter(tierName) : "No Tier Set"}
                </div>
                <br />
                <div>
                    <b>Average Monthly Usage: </b>
                    {avgMonthlyUsage ? `${commafy(avgMonthlyUsage)} kWh` : "No Average Monthly Usage Set"}
                </div>
                <div>
                    <b>Update Avg Usage: </b>
                    <input placeholder="Avg. Monthly Usage" type="text" onChange={ evt => this.updateAvgUsageValue(evt) } />
                    {"kWh"}
                </div>
                <button
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                    onClick={ () => this.updateAvgUsageClicked(avgUsage.replace(/,/g, ""), meter.first().ID) }
                >
                    {"Update Avg Usage"}
                </button>
                <br />
                <div>
                    <b>Monthly Membership: </b> {monthlyMembership ? `$${commafy(monthlyMembership)}/month` : "No Monthly Member Set"}
                </div>
                {membershipAmount.first() &&
                    meter.first() &&
                    (parseInt(membershipAmount.first().membership_rate, 10) !== parseInt(meter.first().membership_rate, 10)) &&
                    <div>
                        {"This monthly membership has been overridden."}
                        <button
                            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                            onClick={ () => { doRemoveOverride(meter.first().ID); } }
                        >
                            {"Remove override"}
                        </button>
                    </div>
                }
                <div>
                    <b>Override Monthly Membership: </b>
                    <input placeholder="Monthly Membership" type="text" onChange={ evt => this.updateMembershipOverrideValue(evt) } />
                </div>
                <button
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                    onClick={ () => this.overrideMembershipClicked(membershipOverride.replace(/,/g, ""), meter.first().ID) }
                >
                    {"Override Membership"}
                </button>
                <br />
                <div>
                    <b>Business Tiers</b>
                </div>
                <div>Tiers are based on member{"'"}s average monthly usage</div>
                <ReactTable
                    data={ rateClasses }
                    columns={ columns }
                    style={ { height: "1600px", width: "600px" } }
                    className="-striped -highlight"
                    filterable
                    noDataText=""
                    defaultPageSize={ 40 }
                />
            </div>
        );
    }
}

MemberBusiness.propTypes = {
    avgMonthlyUsage: PropTypes.string,
    meter: PropTypes.object,
    monthlyMembership: PropTypes.string,
    rateClasses: PropTypes.object,
    tierName: PropTypes.string,
    params: PropTypes.object.isRequired,
    doFetchMeter: PropTypes.func.isRequired,
    doFetchRateClasses: PropTypes.func.isRequired,
    doOverrideRateClass: PropTypes.func.isRequired,
    doRemoveOverride: PropTypes.func.isRequired,
    doUpdateGriddyRateClass: PropTypes.func.isRequired,
};

MemberBusiness.defaultProps = {
    avgMonthlyUsage: "",
    meter: [],
    monthlyMembership: "",
    rateClasses: [],
    tierName: "",
};

const mapStateToProps = state => ({
    avgMonthlyUsage: state.getMeterAvgMonthlyUsage,
    meter: state.getMeterResult,
    monthlyMembership: state.getMeterResult.first() ? state.getMeterResult.first().membership_rate : "",
    overrideRemove: state.overrideRateClassResult,
    overrideSuccess: state.removeOverrideResult,
    rateClasses: state.rateClassesResult,
    tierName: state.getMeterResult.first() ? state.getMeterResult.first().tier_name : "",
});

const mapDispatchToProps = dispatch => ({
    doFetchMeter: (meterQuery) => { dispatch(fetchMeter(meterQuery)); },
    doFetchRateClasses: () => { dispatch(fetchRateClasses()); },
    doOverrideRateClass: (overrideMembership, meterId, meterQuery) => { dispatch(overrideRateClass(overrideMembership, meterId, meterQuery)); },
    doRemoveOverride: (meterId) => { dispatch(removeOverride(meterId)); },
    doUpdateGriddyRateClass: (avgUsage, meterId, meterQuery) => { dispatch(updateGriddyRateClass(avgUsage, meterId, meterQuery)); },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MemberBusiness);
