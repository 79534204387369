import { takeEvery, all, put, call } from "redux-saga/effects";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import { recieveEDFTrades, setSnackbar } from "../actions/edf";
import "babel-polyfill";

export function* edfSaga(action) {
    const { payload: fields } = action;

    try {
        // fetch meter records
        const resp = yield fetch(constants.EDF_TRADES_CREATE_URL, {
            amount_dollars: fields.amount,
            operating_date: fields.operating_date.format("YYYY-MM-DD")
        });

        yield put(recieveEDFTrades(resp.trades));

        yield put(
            setSnackbar({
                show: true,
                success: true,
                msg: "EDF Trade Added"
            })
        );
    } catch (err) {
        yield put(
            setSnackbar({
                show: true,
                success: false,
                msg: "Error: " + err.error
            })
        );
    }
}

export function* fetchEdfSaga(action) {
    const { startDate, endDate } = action;
    const resp = yield fetch(constants.EDF_TRADES_URL, {
        start_date: startDate,
        end_date: endDate
    });
    yield put(recieveEDFTrades(resp.trades));
}

export default [
    takeEvery(constants.CREATE_EDF_TRADE, edfSaga),
    takeEvery(constants.REQUEST_EDF_REPORT, fetchEdfSaga)
];
