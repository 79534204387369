import Immutable from "immutable";
import * as constants from "../../constants";
import moment from "moment";

const defaultEDFState = {
    trades: Immutable.List(),
    operatingDate: moment(),
    resetForm: true,
    snackbar: {
        show: false,
        success: false,
        msg: "snack snack"
    }
};

export default function edfResult(state = defaultEDFState, action) {
    switch (action.type) {
        case constants.RECEIVE_EDF_REPORT:
            return {
                trades: Immutable.List(action.rows),
                operatingDate: findMissingDate(action.rows),
                amount: "",
                snackbar: state.snackbar
            };
        case constants.SET_SNACKBAR:
            return {
                snackbar: action.snackbar,
                trades: state.trades,
                operatingDate: state.operatingDate,
                amount: state.amount
            };
        case constants.SET_TRADE_AMOUNT:
            return {
                snackbar: state.snackbar,
                trades: state.trades,
                operatingDate: state.operatingDate,
                amount: action.amount
            };
        case constants.SET_TRADE_DATE:
            return {
                snackbar: state.snackbar,
                trades: state.trades,
                operatingDate: action.date,
                amount: state.amount,
            };
        default:
            return state;
    }
}

function findMissingDate(rows) {
    if (rows.length < 1) {
        return moment();
    }
    rows = rows.reverse();
    let nextDate = moment(rows[0].operating_date).add(1, "days");
    for (let i = 1; i < rows.length; i++) {
        if (nextDate.format("YYYY-MM-DD") !== rows[i].operating_date) {
            return nextDate;
        }
        nextDate = nextDate.add(1, "days");
    }
    return nextDate;
}
