import moment from "moment";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import selectMeterTransactionsContainer from "../selectors/meterTransactions";

const createErrorMessage = (errorMessage) => ({
    type: constants.METER_TRANSACTION_OPEN_SNACKBAR,
    errorMessage,
});

const createSuccessMessage = (successMessage) => ({
    type: constants.METER_TRANSACTION_OPEN_SNACKBAR,
    successMessage,
});

const setIsProcessing = (isProcessing) => {
    return {
        type: constants.METER_TRANSACTIONS_UPDATE_STATE,
        updatedInfo: {
            isProcessing,
        },
    };
};

export const setErrorMessage = (message) => (dispatch) => {
    dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
    dispatch(createSuccessMessage(message));
};

const getMeterTransactions = (
    dispatch,
    page,
    limit,
    meterID,
    statusesToExclude,
    direction
) => {
    const payload = {
        meterID,
        page,
        limit,
        statusesToExclude,
        direction,
    };

    return fetch(constants.METER_TRANSACTIONS, payload)
        .then((res) => {
            const data = {
                ...res,
                transactions: res.transactions.sort((a, b) => a.ID > b.ID),
            };

            dispatch({
                type: constants.METER_TRANSACTION_LOAD_TRANSACTIONS,
                ...data,
                meterID,
            });
            dispatch(setIsProcessing(false));
        })
        .catch((err) => {
            dispatch(setIsProcessing(false));
            dispatch(setErrorMessage(err.message));
        });
};

export const setSelectedTransaction = (transactionID) => (
    dispatch,
    getState
) => {
    const { transactions } = selectMeterTransactionsContainer()(getState());
    const selectedTransaction = transactions.find(
        (t) => t.id === transactionID
    );
    if (selectedTransaction && !selectedTransaction.transactionData) {
        const payload = {
            id: transactionID,
            includeTransactionData: true,
        };
        dispatch(setIsProcessing(true));
        return fetch(constants.METER_TRANSACTIONS, payload)
            .then((res) => {
                if (res.transactions.length === 0) {
                    return dispatch(
                        setErrorMessage({
                            error: "Failed to load transaction data",
                        })
                    );
                }

                dispatch({
                    type: constants.METER_TRANSACTION_LOAD_DATA,
                    transaction: res.transactions[0],
                });
                dispatch({
                    type: constants.METER_TRANSACTION_SELECT_TRANSACTION,
                    transactionID,
                });
                dispatch(setIsProcessing(false));
            })
            .catch((err) => {
                dispatch(setErrorMessage(err.message));
                dispatch(setIsProcessing(false));
            });
    }
    dispatch({
        type: constants.METER_TRANSACTION_SELECT_TRANSACTION,
        transactionID,
    });
};

export const fetchMeterTransactions = (memberID, startPage) => (
    dispatch,
    getState
) => {
    const { page, limit, next } = selectMeterTransactionsContainer()(
        getState()
    );
    dispatch(setIsProcessing(true));
    return fetch(constants.METER_SEARCH_URL, {
        memberID,
    }).then((data) => {
        //we only want to process the first meter (which is returned by the server sorted by status)
        const firstMeter = data.meters.find((m) => m);
        if (!firstMeter) {
            // if there are no meters just bailout
            dispatch(setIsProcessing(false));
            dispatch(
                setErrorMessage(
                    "No active meter associated with this member found"
                )
            );
            return;
        }
        return getMeterTransactions(
            dispatch,
            startPage || next || page,
            limit,
            firstMeter.ID
        );
    });
};

export const fetchAllMeterTransactions = (startPage) => (
    dispatch,
    getState
) => {
    const { page, limit, next } = selectMeterTransactionsContainer()(
        getState()
    );
    dispatch(setIsProcessing(true));
    return getMeterTransactions(
        dispatch,
        startPage || next || page,
        limit,
        undefined,
        ["Yes", "Ignore"],
        "I"
    );
};

export const closeSnackbar = () => {
    return (dispatch) =>
        dispatch({ type: constants.METER_TRANSACTION_CLOSE_SNACKBAR });
};

export const updateTransaction = (updatedInfo) => (dispatch) => {
    return dispatch({ type: constants.METER_TRANSACTIONS_UPDATE, updatedInfo });
};

export const updateNewTransaction = (updatedInfo) => (dispatch) => {
    return dispatch({
        type: constants.METER_TRANSACTIONS_NEW_UPDATE,
        updatedInfo,
    });
};

export const updateNewTransactionDetail = (updatedRecord) => (dispatch) => {
    return dispatch({
        type: constants.METER_TRANSACTIONS_NEW_UPDATE_DETAIL,
        ...updatedRecord,
    });
};

export const processTransaction = (transactionID) => (dispatch) => {
    dispatch(setIsProcessing(true));
    return fetch(constants.METER_TRANSACTIONS_PROCESS_URL, {
        transactionID,
    })
        .then((data) => {
            dispatch(setIsProcessing(false));
            dispatch(
                setSuccessMessage(
                    `Transaction processing result: ${data.transaction.processingStatus}`
                )
            );
            return dispatch({
                type: constants.METER_TRANSACTIONS_UPDATE,
                updatedInfo: {
                    ...data.transaction,
                    transactionIsModified: false,
                },
            });
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
            dispatch(setIsProcessing(false));
        });
};

export const searchMeters = (meterID) => (dispatch) => {
    dispatch(setIsProcessing(true));
    return fetch(constants.METER_SEARCH_URL, {
        meterID,
    })
        .then((data) => {
            dispatch(setIsProcessing(false));
            return dispatch({
                type: constants.METER_TRANSACTIONS_UPDATE_STATE,
                updatedInfo: {
                    searchMeterResult: data.meters,
                },
            });
        })
        .catch(() => dispatch(setIsProcessing(false)));
};

export const updateState = (updatedInfo) => (dispatch) => {
    return dispatch({
        type: constants.METER_TRANSACTIONS_UPDATE_STATE,
        updatedInfo,
    });
};

export const postUpdateTransaction = () => (dispatch, getState) => {
    const { selectedTransaction } = selectMeterTransactionsContainer()(
        getState()
    );
    // store all keys
    const keys = Object.keys(selectedTransaction);
    // get the keys that are changed
    const updatedKeys = keys.filter((k) => selectedTransaction[`${k}IsSet`]);
    const payload = {
        id: selectedTransaction.id,
    };
    // assign the changed keys to the payload
    updatedKeys.forEach(
        (k) => (
            (payload[`${k}`] = selectedTransaction[`${k}`]),
            (payload[`${k}IsSet`] = true)
        )
    );
    dispatch(setIsProcessing(true));
    return fetch(constants.METER_TRANSACTIONS_UPDATE_URL, payload)
        .then(() => {
            updatedKeys.forEach((k) => (payload[`${k}IsSet`] = false));
            dispatch({
                type: constants.METER_TRANSACTIONS_UPDATE,
                updatedInfo: { ...payload, transactionIsModified: false },
            });
            dispatch(setIsProcessing(false));
            dispatch({ type: constants.METER_TRANSACTION_MERGE_SELECTED });
            dispatch(setSuccessMessage("Transaction updated"));
        })
        .catch((err) => {
            dispatch(setIsProcessing(false));
            dispatch(setErrorMessage(err.message));
        });
};

export const createNewTransaction = () => (dispatch, getState) => {
    const { meterID, newTransactionData } = selectMeterTransactionsContainer()(
        getState()
    );
    dispatch(setIsProcessing(true));
    const { transactionTypeName, transactionSubType } = newTransactionData;
    return fetch(constants.METERE_TRANSACTION_CREATE_URL, {
        meterID,
        transactionTypeName,
        transactionSubType,
    })
        .then((data) => {
            dispatch(setIsProcessing(false));
            return dispatch({
                type: constants.METER_TRANSACTIONS_NEW_UPDATE,
                updatedInfo: {
                    ...data,
                    schema: JSON.parse(data.schema),
                    transaction: {
                        ...data.transaction,
                        transactionData: JSON.parse(
                            data.transaction.transactionData
                        ),
                    },
                },
            });
        })
        .catch((err) => {
            dispatch(setIsProcessing(false));
            dispatch(setErrorMessage(err.message));
        });
};

// validate transactionData against the schema
export const validateTransactionField = (
    recordKey,
    record,
    index,
    fieldName,
    fieldValue
) => (dispatch, getState) => {
    const { newTransactionSchema } = selectMeterTransactionsContainer()(
        getState()
    );

    // assign the record level schema
    const recordSchema = newTransactionSchema.records.find(
        (r) => r[Number(record)]
    );

    // assign the field level schema
    const fieldSchema =
        recordSchema[Number(record)] &&
        recordSchema[Number(record)][index].find(
            (fs) => fs.label === fieldName
        );

    if (fieldSchema) {
        if (
            fieldSchema.is_required &&
            (!fieldValue || fieldValue === "Invalid date")
        ) {
            const updatedInfo = {
                [`${fieldName}Error`]: `${fieldName} is required`,
            };
            updateNewTransactionDetail({
                record: recordKey,
                index,
                updatedInfo,
            })(dispatch);
        } else {
            const updatedInfo = {
                [`${fieldName}Error`]: "",
            };
            updateNewTransactionDetail({
                record: recordKey,
                index,
                updatedInfo,
            })(dispatch);
        }
    }
};

// validate transactionData against the schema
export const validateTransaction = (
    newTransactionData,
    newTransactionSchema,
    dispatch
) => {
    let hadErrors = false;
    // loop all the transaction records
    Object.keys(newTransactionData.transaction.transactionData).map(
        (recordKey) => {
            // record will contain record field which is an array e.g. {3000:[]}
            const record =
                newTransactionData.transaction.transactionData[recordKey];

            // loop each record which contains array of fields e.g. [{'Record Indicator':2000,'Action ID':'GA01'}]
            record
                .map((lineRecord, lineIndex) => {
                    const recordIndicator = Number(
                        record[lineIndex]["Record Indicator"]
                    );
                    const recordSchema = newTransactionSchema.records.find(
                        (r) => r[recordIndicator]
                    );

                    if (
                        !validateTransactionRecord(
                            lineRecord,
                            newTransactionSchema,
                            lineIndex,
                            dispatch
                        )
                    ) {
                        hadErrors = true;
                        return;
                    }
                    return Object.keys(lineRecord).map((key) => {
                        const fieldSchema = recordSchema[recordIndicator][
                            lineIndex
                        ]?.find((f) => f.label === key);
                        if (fieldSchema) {
                            // check required field
                            if (fieldSchema.is_required && !lineRecord[key]) {
                                hadErrors = true;
                                //
                                const updatedInfo = {
                                    [`${fieldSchema.label}Error`]: `${fieldSchema.label} is required`,
                                };
                                // dispatch error message update
                                updateNewTransactionDetail({
                                    record: recordKey,
                                    index: lineIndex,
                                    updatedInfo,
                                })(dispatch);
                                return true;
                            }
                        }
                    });
                })
                .map((r) => r); // filter out any undefined
        }
    );
    return hadErrors;
};
const recordKeyMapping = {
    2000: "transaction_info",
    3000: "organization",
    4000: "customer",
    5000: "account",
    5500: "account_exception",
    6000: "meter",
    6500: "meter_exception",
};
const validateRule = (record, ruleName, columns, lineIndex, dispatch) => {
    if (ruleName === "OnlyOneOf") {
        // OnlyOneOf rule: Must have a value in one of the columns but only one column should have a value
        const validated = columns.reduce((hasValue, column) => {
            // check if we already invalided
            if (hasValue === null) return null;
            // invalid when non-null value is already found and we have a non-null value
            if (
                hasValue &&
                record[column] &&
                record[column] !== "Invalid date"
            ) {
                return null;
            }
            // check if we already has a value and the next value is null
            if (
                hasValue &&
                !(record[column] && record[column] !== "Invalid date")
            ) {
                return true;
            }

            return Boolean(record[column] && record[column] !== "Invalid date");
        }, false);

        let errorMessage = "";
        if (validated === null) {
            errorMessage = `Only one of ${columns} should be entered`;
        } else if (!validated) {
            errorMessage = `One of ${columns} is required`;
        }
        columns.forEach((columnName) => {
            const updatedInfo = {
                [`${columnName}Error`]: errorMessage,
            };
            // dispatch error message update
            updateNewTransactionDetail({
                record: recordKeyMapping[record["Record Indicator"]],
                index: lineIndex,
                updatedInfo,
            })(dispatch);
        });

        return validated;
    } else if (ruleName === "AnyOf") {
        // AnyOf rule: Must have a value in at least one of the columns
        const validated = columns.find((column) =>
            Boolean(record[column] && record[column] !== "Invalid date")
        );

        let errorMessage = "";
        if (!validated) {
            errorMessage = `One of ${columns} is required`;
        }
        columns.forEach((columnName) => {
            const updatedInfo = {
                [`${columnName}Error`]: errorMessage,
            };
            // dispatch error message update
            updateNewTransactionDetail({
                record: recordKeyMapping[record["Record Indicator"]],
                index: lineIndex,
                updatedInfo,
            })(dispatch);
        });

        return validated;
    }
};

// record leve validations via schema.validations
const validateTransactionRecord = (record, schema, index, dispatch) => {
    // get record level validations
    const recordValidations =
        schema.validations &&
        schema.validations.find((v) => v[record["Record Indicator"]]);
    let isValid = true;
    if (recordValidations) {
        // rules are applied via index position in the schema. Meaning the line record index corresponds to the rule index position
        const rules =
            recordValidations && recordValidations[record["Record Indicator"]];

        // chech if we have rule for record type with the given index
        if (rules && rules.length > index && rules[index]) {
            const recordRule = rules[index];
            recordRule.forEach((r) => {
                if (!validateRule(record, r.rule, r.columns, index, dispatch)) {
                    isValid = false;
                }
            });
        }
    }
    return isValid;
};

export const sendTransaction = () => (dispatch, getState) => {
    const {
        meterID,
        newTransactionData,
        newTransactionSchema,
    } = selectMeterTransactionsContainer()(getState());
    const hasError = validateTransaction(
        newTransactionData,
        newTransactionSchema,
        dispatch
    );

    if (hasError) {
        return;
    }
    dispatch(setIsProcessing(true));
    const transactionData = JSON.stringify(
        newTransactionData.transaction.transactionData
    );
    const {
        account,
        service_order,
    } = newTransactionData.transaction.transactionData;
    const utilityAccountNumber =
        (account && account.length > 0 && account[0]["ESI ID"]) ||
        (service_order && service_order.length && service_order[0]["ESI ID"]);
    const transaction = {
        ...newTransactionData.transaction,
        transactionDate: moment(transactionData.transactionDate).format(
            "YYYY-MM-DD"
        ),
        utilityAccountNumber,
        transactionData,
    };

    return fetch(constants.METER_TRANSACTION_REGISTER_URL, {
        meterID,
        transaction,
    })
        .then((data) => {
            dispatch({
                type: constants.METER_TRANSACTION_ADD_TRANSACTION,
                transaction: data.transaction,
            });
            dispatch({
                type: constants.METER_TRANSACTIONS_UPDATE_STATE,
                updatedInfo: {
                    openCreateTransaction: false,
                    newTransactionData: {
                        fileType: "gaa",
                        transactionTypeName: "",
                        transactionSubType: "Request",
                    },
                },
            });
            dispatch(setIsProcessing(false));
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
            dispatch(setIsProcessing(false));
        });
};
