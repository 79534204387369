import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Workbook from "react-excel-workbook";
import fetchTdspInvoice from "../actions/tdsp";
import { withStyles } from "@material-ui/core/styles";

const styles = (style) => ({
    row: {
        padding: "5px 0",
        display: "flex",
    },
    column: {
        width: "50%",
    },
    exportContainer: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
});

class Tdsp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberID: "",
        };
    }

    componentDidMount() {
        const { memberId, doFetchTdspInvoice } = this.props;
        if (memberId) {
            doFetchTdspInvoice(memberId);
        }
    }

    getDate = (value) => {
        const d = value.split("T")[0];
        return moment(d).format("MMM DD, YYYY");
    };

    render() {
        const { rows, doFetchTdspInvoice, classes, memberId } = this.props;
        const data = Array.from(rows);
        const columns = [
            {
                Header: "Member",
                id: "memberID",
                accessor: (d) => d.memberID,
            },
            {
                Header: "ESI",
                accessor: "ESID",
            },
            {
                Header: "TDSP",
                accessor: "TDSP",
            },
            {
                Header: "Invoice ID",
                accessor: "invoiceID",
            },
            {
                Header: "Invoice Date",
                accessor: "invoice_date",
                Cell: (row) => this.getDate(row.value),
            },
            {
                Header: "Start Date",
                accessor: "start_date",
                Cell: (row) => this.getDate(row.value),
            },
            {
                Header: "End Date",
                accessor: "end_date",
                Cell: (row) => this.getDate(row.value),
            },
            {
                Header: "Processed",
                accessor: "created",
                Cell: (row) => this.getDate(row.value),
            },
            {
                Header: "Invoice Amount",
                accessor: "invoice_amount_dollars",
                Cell: (row) => `$${row.value}`,
            },
            {
                Header: "TDSP Shadow",
                accessor: "tdsp_shadow_amount_dollars",
                Cell: (row) => `$${row.value}`,
            },
            {
                Header: "TDSP Credit",
                accessor: "tdsp_credit",
                Cell: (row) => `$${row.value}`,
            },
            {
                Header: "Reconciliation",
                accessor: "reconciliation",
            },
        ];

        return (
            <div>
                {!this.props.memberId && (
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <TextField
                                id="memberID"
                                label="MemberID"
                                name="memberID"
                                style={{ width: "300px", marginRight: "5px" }}
                                value={this.state.memberID}
                                onChange={(e) =>
                                    this.setState({ memberID: e.target.value })
                                }
                            />
                            <Button
                                color="secondary"
                                onClick={() =>
                                    doFetchTdspInvoice(this.state.memberID)
                                }
                            >
                                Search
                            </Button>
                        </div>
                        <div className={classes.exportContainer}>
                            <Workbook
                                filename="tdsp_charges.xlsx"
                                element={
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                    >
                                        Export as xlsx
                                    </Button>
                                }
                            >
                                <Workbook.Sheet data={rows} name="TDSP">
                                    <Workbook.Column
                                        label="Member"
                                        value="memberID"
                                    />
                                    <Workbook.Column label="ESI" value="ESID" />
                                    <Workbook.Column
                                        label="TDSP"
                                        value="TDSP"
                                    />
                                    <Workbook.Column
                                        label="Invoice ID"
                                        value="invoiceID"
                                    />
                                    <Workbook.Column
                                        label="Invoice Date"
                                        value={(row) =>
                                            row.invoice_date.replace("T", " ")
                                        }
                                    />
                                    <Workbook.Column
                                        label="Start Date"
                                        value={(row) =>
                                            row.start_date.replace("T", " ")
                                        }
                                    />
                                    <Workbook.Column
                                        label="End Date"
                                        value={(row) =>
                                            row.end_date.replace("T", " ")
                                        }
                                    />
                                    <Workbook.Column
                                        label="Processed"
                                        value={(row) =>
                                            row.created.replace("T", " ")
                                        }
                                    />
                                    <Workbook.Column
                                        label="Invoice Amount"
                                        value="invoice_amount_dollars"
                                    />
                                    <Workbook.Column
                                        label="TDSP Shadow"
                                        value="tdsp_shadow_amount_dollars"
                                    />
                                    <Workbook.Column
                                        label="TSDP Credit"
                                        value="tdsp_credit"
                                    />
                                    <Workbook.Column
                                        label="Reconciliation"
                                        value="reconciliation"
                                    />
                                </Workbook.Sheet>
                            </Workbook>
                        </div>
                    </div>
                )}
                <ReactTable
                    data={data}
                    columns={columns}
                    className="-striped -highlight"
                    filterable
                    defaultPageSize={10}
                />
            </div>
        );
    }
}

Tdsp.propTypes = {
    doFetchTdspInvoice: PropTypes.func.isRequired,
    rows: PropTypes.object,
    memberId: PropTypes.string,
};

Tdsp.defaultProps = {
    rows: [],
};

const mapStateToProps = (state) => ({
    rows: state.tdspResult,
});

const mapDispatchToProps = (dispatch) => ({
    doFetchTdspInvoice: (memberID) => {
        dispatch(fetchTdspInvoice(memberID));
    },
});

const ConnectedTDSP = connect(mapStateToProps, mapDispatchToProps)(Tdsp);

export default withStyles(styles)(ConnectedTDSP);
