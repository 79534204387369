import React, { useState, useEffect } from "react";
import moment from "moment";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import UsageDetailRows from "./UsageDetailRows";

const BillingUsageRows = (props) => {
  const {
    money,
    usageSummaries,
    transaction,
    index,
    doRequestUsageSummaryByDate,
    memberID,
  } = props;
  const [toggle, setToggle] = useState(false);
  const {
    transaction_date,
    electricity_usage,
    ancillary_services,
    tdu_delivery_charges,
    griddy_membership,
    taxes_and_fees,
    other,
    deposit,
    end_of_day_balance,
  } = transaction;

  const date = moment(transaction_date.substring(0, 10)).format("YYYY-MM-DD");

  const handleClick = () => {
    setToggle(!toggle);
    const [year, month, day] = date.split("-");
    if (!usageSummaries[date]) {
      doRequestUsageSummaryByDate(day, month, year, memberID);
    }
  };
  useEffect(() => {
    if (usageSummaries?.date) {
      setToggle(true);
    }
  }, [usageSummaries]);

  return [
    <TableRow
      style={{ cursor: "pointer" }}
      onClick={() => handleClick(transaction_date)}
      key={`${transaction?.transaction_date}-${index}`}
    >
      <TableCell key={`${date}-${index}`}>
        {!toggle ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
      </TableCell>
      <TableCell style={{ width: 65 }}>
        {moment(transaction_date.substring(0, 10)).format("YYYY-MM-DD")}
      </TableCell>
      <TableCell align="center">{money(electricity_usage)}</TableCell>
      <TableCell align="center">{money(ancillary_services)}</TableCell>
      <TableCell align="center">{money(tdu_delivery_charges)}</TableCell>
      <TableCell align="center">{money(griddy_membership)}</TableCell>
      <TableCell align="center">{money(taxes_and_fees)}</TableCell>
      <TableCell align="center">{money(other)}</TableCell>
      <TableCell align="center">{money(deposit)}</TableCell>
      <TableCell align="center" style={{ fontWeight: "bold" }}>
        {money(end_of_day_balance)}
      </TableCell>
    </TableRow>,
    <UsageDetailRows
      key={`${index}-${date}`}
      date={date}
      money={money}
      usageSummaries={usageSummaries}
      index={index}
      toggle={toggle}
      transaction={transaction_date}
    />,
  ];
};

export default BillingUsageRows;
