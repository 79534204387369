import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { connect } from "react-redux";
import { subNavigation } from "../styles/navigation.css";
import { clearSelectedMember } from "../actions/members";
import { clearIterableUserData } from "../actions/iterable";
import NoteCreationPanel from "./UserNotes/NoteCreationPanel.js";

import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import Snackbar from "@material-ui/core/Snackbar";

import { hideSnackBar } from "../actions/members";
import {
  fetchUserIDWithFullMemberInfoNeeded,
  fetchFullMemberInfoNeeded,
} from "../actions/search";
import {
  requestIterableChannels,
  requestIterableCampaigns,
} from "../actions/iterable";

import { confirmationError, confirmationSuccess } from "../styles/common.css";

import { CLEAR_DISPUTE_INFO, ITERABLE_CLEAR_USER_DATA } from "../../constants";

const clearDisputeInfo = () => ({
  type: CLEAR_DISPUTE_INFO,
});

const styles = (theme) => ({
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  header: {
    "& a:hover": {
      transition: "all 0.3s linear",
      backgroundColor: "#eee",
      borderRadius: "10px",
    },
    "& button:hover": {
      transition: "all 0.3s linear",
      backgroundColor: "#aaa",
      borderRadius: "10px",
      color: "white",
    },
  },
});

class Member extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const {
      member,
      onFetchUserIDWithFullMemberInfoNeeded,
      onfetchFullMemberInfoNeeded,
      onRequestIterableChannels,
      onRequestIterableCampaigns,
    } = this.props;
    if (member.get("userID") === undefined) {
      onFetchUserIDWithFullMemberInfoNeeded(this.props.params.memberId);
      onRequestIterableChannels();
      onRequestIterableCampaigns();
    } else {
      onfetchFullMemberInfoNeeded(this.props.member);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isCommercial !== nextProps.isCommercial) {
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    this.props.onClearSelectedMember();
    this.props.onClearDisputeInfo();
    this.props.onClearIterableUserData();
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.onHideSnackBar();
  };

  render() {
    const { children, params, isCommercial, classes, member } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={member.get("showSnackBar")}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          {member.getIn(["response", "success"]) ? (
            <span id="message-id" className={confirmationSuccess}>
              {member.getIn(["response", "msg"])}
            </span>
          ) : (
            <span id="message-id" className={confirmationError}>
              {member.getIn(["response", "msg"])}
            </span>
          )}
        </Snackbar>
        <header className={`${classes.header} ${subNavigation}`}>
          <Link
            to={`/members/${params.memberId}/usage`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Usage
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/details`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Details
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/billing`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Billing
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/payment`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Payment
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/sup`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Sup
          </Link>{" "}
          {isCommercial && (
            <Link
              to={`/members/${params.memberId}/business`}
              activeStyle={{
                textDecoration: "none",
                color: "black",
              }}
            >
              Business
            </Link>
          )}{" "}
          <Link
            to={`/members/${params.memberId}/savings`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Savings
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/disputes`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Disputes Info
          </Link>
          <Link
            to={`/members/${params.memberId}/dnprnp`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            DNP/RNP
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/metertransactions`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Meter Transactions
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/iterable`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Iterable
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/notes`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Notes
          </Link>{" "}
        </header>
        <NoteCreationPanel />
        {children}
        <Backdrop
          className={classes.backdropStyle}
          open={member.get("isFetching")}
        >
          <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
      </div>
    );
  }
}

Member.propTypes = {
  children: PropTypes.object,
  params: PropTypes.object,
  isCommercial: PropTypes.bool,
};

Member.defaultProps = {
  children: {},
  params: {},
  isCommercial: false,
};

const mapStateToProps = (state) => ({
  isCommercial: state.selectedMember.get("meters").first()
    ? state.selectedMember.get("meters").first().get("polr_customer_class") !==
      "residential"
    : false,
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onClearSelectedMember: () => {
    dispatch(clearSelectedMember());
  },
  onClearIterableUserData: () => {
    dispatch(clearIterableUserData());
  },
  onfetchFullMemberInfoNeeded: (member) =>
    dispatch(fetchFullMemberInfoNeeded(member)),
  onFetchUserIDWithFullMemberInfoNeeded: (member) =>
    dispatch(fetchUserIDWithFullMemberInfoNeeded(member)),
  onHideSnackBar: () => dispatch(hideSnackBar()),
  onClearDisputeInfo: () => {
    dispatch(clearDisputeInfo());
  },
  onRequestIterableChannels: () => dispatch(requestIterableChannels()),
  onRequestIterableCampaigns: () => dispatch(requestIterableCampaigns()),
});

const ConnectedMember = connect(mapStateToProps, mapDispatchToProps)(Member);

export default withStyles(styles)(ConnectedMember);
