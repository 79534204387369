import * as constants from "../../constants";
import fetch from "../util/api-ajax";

export function requestEDFTrades(startDate, endDate) {
    return {
        type: constants.REQUEST_EDF_REPORT,
        startDate: startDate,
        endDate: endDate
    };
}

export function recieveEDFTrades(data) {
    return {
        type: constants.RECEIVE_EDF_REPORT,
        rows: data
    };
}

export function setSnackbar(payload) {
    return {
        type: constants.SET_SNACKBAR,
        snackbar: payload
    };
}
export function setTradeAmount(amount) {
    return {
        type: constants.SET_TRADE_AMOUNT,
        amount: amount
    };
}

export function setOperatingDate(date) {
    return {
        type: constants.SET_TRADE_DATE,
        date: date
    };
}

function createEDF(payload) {
    return {
        type: constants.CREATE_EDF_TRADE,
        payload
    };
}

export function edfTrade(payload) {
    return dispatch => {
        dispatch(createEDF(payload));
    };
}
