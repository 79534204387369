import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MeterTransactions from "../components/MeterTransactions";
import selectMeterTransactionsContainer from "../selectors/meterTransactions";
import * as actions from "../actions/meterTransactions";

const MeterTransactionContainer = (props) => <MeterTransactions {...props} />;

const mapStateToProps = selectMeterTransactionsContainer();

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actions, dispatch),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MeterTransactionContainer);
