import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";

class MemberSup extends Component {
    constructor(props) {
        super(props);

        this.date = moment();
        this.domain =
            window.location.href.indexOf("development") !== -1
                ? "https://development.gogriddy.com"
                : "https://app.gogriddy.com";
    }

    handleChangeForDate = (date) => {
        this.date = moment(date);
        this.forceUpdate();
    };

    render() {
        return (
            <div style={{ marginLeft: "10px" }}>
                <h4>Sup</h4>
                <div>
                    <KeyboardDatePicker
                        dateformat="YYYY/MM"
                        value={this.date}
                        onChange={this.handleChangeForDate}
                        views={["year", "month"]}
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                    />
                </div>
                <br />
                <button
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                    onClick={() => {
                        window.open(
                            `${this.domain}/sup/${
                                this.props.params.memberId
                            }/${this.date.format(
                                "YYYY-MM"
                            )}?token=${localStorage.getItem("access_token")}`,
                            "_blank"
                        );
                    }}
                >
                    Select Sup
                </button>
            </div>
        );
    }
}

MemberSup.propTypes = {
    params: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MemberSup);
