import PropTypes from "prop-types";
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import Tab from "@material-ui/core/Tab";

import ViewSelector from "../common/ViewSelector";
import MonthlyChargesTable from "./MonthlyChargesTable";
import ChargesByTransactionTable from "./ChargesByTransactionTable";
import ChargesByUsageTable from "./ChargesByUsageTable";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    fontSize: "18px",
    border: "1px solid #ddd",
    borderTop: "none",
    borderTopLeftRadius: "10px",
    height: "600px",
    "& h1": {
      fontSize: "19px",
      textDecoration: "underline",
      paddingBottom: "15px",
      margin: 0,
    },
  },
});

const ChargesPanel = (props) => {
  const classes = useStyles();
  const {
    memberID,
    groupItems,
    totalAmount,
    membership,
    chargeDetails,
    usageDetails,
    usageSummaries,
    money,
    doRequestUsageSummaryByDate,
  } = props;
  const [activeView, setActiveView] = useState(0);

  return (
    <Card className={classes.root}>
      <ViewSelector activeView={activeView} setActiveView={setActiveView}>
        {[
          "Monthly Charges",
          "Daily Balance Summary (Beta)",
          "Daily Charge Summary (Beta)",
        ].map((option, index) => {
          return (
            <Tab
              key={index}
              className={activeView === index ? `view activeView` : `view`}
              label={option}
            ></Tab>
          );
        })}
      </ViewSelector>
      {activeView === 0 && (
        <MonthlyChargesTable
          groupItems={groupItems}
          totalAmount={totalAmount}
          membership={membership}
          money={money}
        />
      )}
      {activeView === 1 && (
        <ChargesByTransactionTable memberID={memberID} doRequestUsageSummaryByDate={doRequestUsageSummaryByDate} usageSummaries={usageSummaries} details={chargeDetails} money={money} />
      )}
      {activeView === 2 && (
        <ChargesByUsageTable details={usageDetails} money={money} />
      )}
    </Card>
  );
};

export default ChargesPanel;
