import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestMeters() {
    return {
        type: constants.REQUEST_METERS,
        isFetching: true,
    };
}

function recieveMeters(data) {
    return {
        type: constants.RECEIVE_METERS,
        isFetching: false,
        data,
    };
}

const dunsTable = {
    "007924772": "AEP Central",
    "007923311": "AEP North",
    957877905: "Centerpoint",
    1039940674000: "Oncor",
    1052623364500: "Sharyland",
    "007929441": "TNMP",
};

export function fetchMeters(searchObj) {
    return (dispatch) => {
        dispatch(requestMeters());
        let path = constants.METER_SEARCH;
        if (searchObj.esiID) {
            path = constants.METER_LOOKUP;
        }
        let metersResult = [];
        return fetch(path, searchObj)
            .then((data) => {
                if (data && data.esi) {
                    metersResult.push(data);
                } else if (data && data.meters) {
                    const { meters } = data;
                    metersResult = meters;
                } else {
                    metersResult.push({ no_results: true });
                }

                const newMeters = metersResult.map((m) => {
                    const newMeter = m;
                    newMeter.duns = dunsTable[m.duns];
                    return newMeter;
                });
                dispatch(recieveMeters(newMeters));
            })
            .catch((error) => {
                alert(error)
            });
    };
}

function requestMeter() {
    return {
        type: constants.REQUEST_METER,
        isFetching: true,
    };
}

function receiveMeter(data) {
    return {
        type: constants.RECEIVE_METER,
        isFetching: false,
        data,
    };
}

export function fetchMeter(query) {
    return (dispatch) => {
        dispatch(requestMeter());
        let meterId = "";
        return fetch(constants.METER_SEARCH_URL, query)
            .then((data) => {
                dispatch(receiveMeter(data.meters));

                meterId = data.meters.length > 0 ? data.meters[0].ID : "";

                dispatch(requestAvgMonthlyUsage());
                return fetch(constants.METER_MONTHLY_AVG_USAGE, { ID: meterId });
            }).then((data) => {
                dispatch(recieveAvgMonthlyUsage(data.amu));
            }).catch(() => {});
    };
}

function requestRateClasses() {
    return {
        type: constants.REQUEST_RATE_CLASSES,
        isFetching: true,
    };
}

function recieveRateClasses(data) {
    return {
        type: constants.RECEIVE_RATE_CLASSES,
        isFetching: false,
        data,
    };
}

export function fetchRateClasses() {
    return (dispatch) => {
        dispatch(requestRateClasses());
        return fetch(constants.METER_RATE_CLASSES, {})
            .then((data) => {
                dispatch(recieveRateClasses(data.griddy_rate_class));
            })
            .catch(() => {
                alert("Sorry, fetching rate classes failed.");
            });
    };
}

function requestUpdateGriddyRateClass() {
    return {
        type: constants.REQUEST_UPDATE_GRIDDY_RATE_CLASS,
        isFetching: true,
    };
}

function receiveUpdateGriddyRateClass(data) {
    return {
        type: constants.RECEIVE_UPDATE_GRIDDY_RATE_CLASS,
        isFetching: false,
        data,
    };
}

export function updateGriddyRateClass(avgUsage, meterId, meterQuery) {
    return (dispatch) => {
        dispatch(requestUpdateGriddyRateClass());
        return fetch(constants.METER_UPDATE_RATE_CLASS, { ID: meterId, amu: avgUsage })
            .then(() => {
                dispatch(receiveUpdateGriddyRateClass());
                dispatch(requestMeter());
                return fetch(constants.METER_SEARCH_URL, meterQuery);
            }).then((data) => {
                dispatch(receiveMeter(data.meters));
                dispatch(requestAvgMonthlyUsage());
                return fetch(constants.METER_MONTHLY_AVG_USAGE, { ID: meterId });
            })
            .then((data) => {
                dispatch(recieveAvgMonthlyUsage(data.amu));
            })
            .catch(() => {});
    };
}

function requestOverrideRateClass() {
    return {
        type: constants.REQUEST_OVERRIDE_RATE_CLASS,
        isFetching: true,
    };
}

function recieveOverrideRateClass(data) {
    return {
        type: constants.RECEIVE_OVERRIDE_RATE_CLASS,
        isFetching: false,
        data,
    };
}

export function overrideRateClass(membershipOverride, meterId, meterQuery) {
    return (dispatch) => {
        dispatch(requestOverrideRateClass());
        return fetch(constants.METER_OVERRIDE_RATE_CLASS, { ID: meterId, grc_override: membershipOverride })
            .then(() => {
                dispatch(recieveOverrideRateClass());
            }).then(() => {
                dispatch(requestMeter());
                return fetch(constants.METER_SEARCH_URL, meterQuery);
            }).then((data) => {
                dispatch(receiveMeter(data.meters));
            })
            .then(() => {
                dispatch(requestAvgMonthlyUsage());
                return fetch(constants.METER_MONTHLY_AVG_USAGE, { ID: meterId });
            })
            .then((data) => {
                dispatch(recieveAvgMonthlyUsage(data.amu));
            })
            .catch(() => {});
    };
}

function requestAvgMonthlyUsage() {
    return {
        type: constants.REQUEST_AVG_MONTHLY_USAGE,
        isFetching: true,
    };
}

function recieveAvgMonthlyUsage(data) {
    return {
        type: constants.RECEIVE_AVG_MONTHLY_USAGE,
        isFetching: false,
        data,
    };
}

export function fetchAvgMonthlyUsage(meterID) {
    return (dispatch) => {
        dispatch(requestAvgMonthlyUsage());
        return fetch(constants.METER_MONTHLY_AVG_USAGE, { ID: meterID })
            .then((data) => {
                dispatch(recieveAvgMonthlyUsage(data.amu));
            })
            .catch(() => {
                alert("Sorry, fetching average monthly usage failed.");
            });
    };
}

function requestRemoveOverride() {
    return {
        type: constants.REQUEST_REMOVE_OVERRIDE,
        isFetching: true,
    };
}

function recieveRemoveOverride(data) {
    return {
        type: constants.RECEIVE_REMOVE_OVERRIDE,
        isFetching: false,
        data,
    };
}

export function removeOverride(meterId) {
    return (dispatch) => {
        dispatch(requestRemoveOverride());
        return fetch(constants.METER_REMOVE_OVERRIDE, { ID: meterId })
            .then((data) => {
                dispatch(recieveRemoveOverride(data.success));
            })
            .catch(() => {
                alert("Sorry, removing rate class override failed.");
            });
    };
}
