import Immutable from "immutable";
import * as C from "../../constants";

const initialState = Immutable.fromJS({
    pairs: [],
});

export function acceptedPairs(state = initialState, action) {
    switch (action.type) {
    case C.RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS:
        return Immutable.fromJS(action.payload);
    default:
        return state;
    }
}
