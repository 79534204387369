import React, { Component } from "react";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";
import modalStyle from "../styles/modal";
import Snackbar from "@material-ui/core/Snackbar";
import { confirmationError, confirmationSuccess } from "../styles/common.css";
import {
    fetchCardList,
    fetchChargeList,
    toggleCharge,
    removePayment,
    toggleConfirmCardDelete,
    confirmPaymentDelete,
    clearErrorMessage,
} from "../actions/payment";

const columnStyle = {
    textAlign: "center",
};
const headerStyle = {
    fontWeight: "700",
};

const classes = {
    formActions: {
        marginTop: "15px",
    },
    actionButtons: {
        marginRight: "5px",
    },
};

class MemberPayment extends Component {
    componentDidMount() {
        const { onFetchCardList, onFetchChargeList } = this.props;
        onFetchChargeList(this.props.params.memberId);
        onFetchCardList(this.props.params.memberId);
    }
    getChargeTableData() {
        // constructing array of charges from chargeList which can be used in ReactTable component
        const chargeArray = [];
        this.props.chargeList.forEach((charge) => {
            let status = charge.get("captured") ? "Captured" : "";
            status = charge.get("authorized") ? `Authorized ${status}` : status;
            status = charge.get("refunded") ? `Refunded ${status}` : status;
            status = charge.get("faild_code") ? `Failed ${status}` : status;

            chargeArray.push({
                created: charge.get("created"),
                Amount: charge.get("Amount"),
                amount_refunded: charge.get("amount_refunded"),
                payment_method: charge.get("payment_method"),
                status,
                fee: charge.get("fee"),
                cardType: charge.get("card").get("cardType"),
                cardLastFour: charge.get("card").get("lastFour"),
                failureReason: charge.get("faild_code"),
                stripeId: charge.get("chargeID"),
            });
        });
        return chargeArray;
    }
    getCardTableData() {
        const cardArray = [];
        this.props.cardList.forEach((card) => {
            cardArray.push({
                default: card.get("default") ? "Yes" : "No",
                cardType: card.get("cardType"),
                lastFour: card.get("lastFour"),
                billingZip: card.get("address").get("postalCode"),
                expiry: `${card.get("expirationMonth")} / ${card.get(
                    "expirationYear"
                )}`,
                cardID: card.get("CardID"),
            });
        });
        return cardArray;
    }
    render() {
        // defining columns for charge ReactTable component
        const chargeColumns = [
            {
                id: "date",
                Header: "Date",
                accessor: (charge) => dateFormat(charge.created, "mmm d, yyyy"),
                style: columnStyle,
                headerStyle,
                sortMethod: (a, b) => {
                    if (new Date(a).getTime() > new Date(b).getTime()) {
                        return 1;
                    }
                    return -1;
                },
            },
            {
                Header: "Amount",
                accessor: "Amount",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Amount Refunded",
                accessor: "amount_refunded",
                style: columnStyle,
                headerStyle,
            },
            {
                id: "paymentMethod",
                Header: "Payment Method",
                accessor: (charge) =>
                    `${charge.cardType} ending in ${charge.cardLastFour}`,
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Status",
                accessor: "status",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Fee",
                accessor: "fee",
                style: columnStyle,
                headerStyle,
            },
            {
                id: "stripeId",
                Header: "Stripe ID",
                accessor: (charge) => (
                    <a
                        target="_blank"
                        href={`https://dashboard.stripe.com/payments/${charge.stripeId}`}
                    >
                        {charge.stripeId}
                    </a>
                ),
                style: columnStyle,
                headerStyle,
            },
        ];
        const {
            isLoading,
            showSnackBar,
            confirmDeleteModalOpen,
            onToggleConfirmCardDelete,
            onConfirmPaymentDelete,
            onRemovePayment,
            onClearErrorMessage,
            successMessage,
            errorMessage,
            cardLastFour,
        } = this.props;
        // defining columns for card ReactTable component
        const cardColumns = [
            {
                Header: "Default",
                accessor: "default",
                style: columnStyle,
                headerStyle,
                Cell: (row) => {
                    return (
                        <div>
                            {row.original.default}
                            <IconButton
                                aria-label="Edit"
                                onClick={() =>
                                    onConfirmPaymentDelete(
                                        row.original.cardID,
                                        row.original.lastFour
                                    )
                                }
                                style={{
                                    marginLeft: 10,
                                    height: 16,
                                    width: 16,
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    );
                },
            },
            {
                Header: "Type",
                accessor: "cardType",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Last Four",
                accessor: "lastFour",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Expiry (Month / Year)",
                accessor: "expiry",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Billing Zipcode",
                accessor: "billingZip",
                style: columnStyle,
                headerStyle,
            },
        ];
        const chargeDataArray = this.getChargeTableData();
        const cardDataArray = this.getCardTableData();
        return (
            <div>
                <h4>Payment History</h4>
                <ReactTable
                    data={chargeDataArray}
                    columns={chargeColumns}
                    loading={chargeDataArray.length === 0}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
                <h4>Payment Methods</h4>
                <ReactTable
                    data={cardDataArray}
                    columns={cardColumns}
                    loading={isLoading}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
                <Modal isOpen={confirmDeleteModalOpen} style={modalStyle.modal}>
                    <div>
                        Are you sure you want to delete this card ending in{" "}
                        {cardLastFour}?
                    </div>
                    <div style={classes.formActions}>
                        <Button
                            style={classes.actionButtons}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                onRemovePayment(this.props.params.memberId)
                            }
                        >
                            Yes
                        </Button>
                        <Button
                            style={classes.actionButtons}
                            variant="contained"
                            color="secondary"
                            onClick={onToggleConfirmCardDelete}
                        >
                            No
                        </Button>
                    </div>
                </Modal>
                <Snackbar
                    id="message-id"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    open={showSnackBar}
                    autoHideDuration={3000}
                    onClose={onClearErrorMessage}
                    className={
                        successMessage ? confirmationSuccess : confirmationError
                    }
                    message={successMessage || errorMessage}
                />
            </div>
        );
    }
}

MemberPayment.propTypes = {
    cardList: PropTypes.object,
    chargeList: PropTypes.object,
    params: PropTypes.object.isRequired,
    onFetchCardList: PropTypes.func.isRequired,
    onFetchChargeList: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
};

MemberPayment.defaultProps = {
    cardList: [],
    chargeList: [],
};

const mapStateToProps = (state) => ({
    cardList: state.paymentCardList,
    chargeList: state.paymentChargeList,
    confirmDeleteModalOpen: state.payments.confirmDeleteModalOpen,
    cardLastFour: state.payments.cardLastFour,
    errorMessage: state.payments.errorMessage,
    successMessage: state.payments.successMessage,
    showSnackBar: state.payments.showSnackBar,
    isLoading: state.payments.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    onFetchCardList: (member) => dispatch(fetchCardList(member)),
    onFetchChargeList: (member) => dispatch(fetchChargeList(member)),
    onToggle: (idx) => dispatch(toggleCharge(idx)),
    onRemovePayment: (member) => dispatch(removePayment(member)),
    onToggleConfirmCardDelete: () => dispatch(toggleConfirmCardDelete()),
    onConfirmPaymentDelete: (cardID, cardLastFour) =>
        dispatch(confirmPaymentDelete(cardID, cardLastFour)),
    onClearErrorMessage: () => dispatch(clearErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberPayment);
