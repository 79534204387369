import PropTypes from "prop-types";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    padding: "15px",
    border: "1px solid #ddd",
    maxHeight: "600px",
    "& h1#panel-title": {
      fontSize: "19px",
      textDecoration: "underline",
      paddingBottom: "15px",
      margin: 0,
    },
    "& .summaryInfoContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: "100%",
      "& #usage-info, #compare-price-info, #all-in-rate-info": {
        "&:hover": {
          "& p": {
            transform: "scale(1.3)",
          },
          "& h1": {
            transform: "scale(0.7)",
          },
        },
      },
      "& div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        width: "100px",
        height: "100px",
        float: "left",
        background: "#ff4081",
        margin: "10px",
        padding: "10px",
        color: "white",
        fontWeight: "bold",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        WebkitBorderRadius: "99em",
        MozBorderRadius: "99em",
        borderRadius: "99em",
        border: "12px solid #efefef",
        "& h1": {
          fontSize: "16px",
          padding: "0 10px",
          margin: "20px 0px !important",
          textAlign: "center",
          textTransform: "uppercase",
          marginBottom: "0px",
          fontWeight: "bold",
          letterSpacing: "1px",
          transition: "all 0.2s ease",
        },
        "& p": {
          textAlign: "center",
          fontSize: "15px",
          position: "relative",
          bottom: "15px",
          transition: "all 0.2s ease",
        },
      },
      "& div:nth-child(1)": {
        backgroundColor: "#ffa600",
      },
      "& div:nth-child(2)": {
        backgroundColor: "#4bad65",
      },
      "& div:nth-child(3)": {
        backgroundColor: "#bc5090",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
}));

const SummaryPanel = (props) => {
  const classes = useStyles();
  const { averageRates } = props;
  return (
    <Card className={classes.root}>
      <h1 id="panel-title">Average Rates</h1>
      <div className="summaryInfoContainer">
        <div id="usage-info">
          <h1>{`Usage:`}</h1>
          <p>{`${averageRates.kwh_used} kWh`}</p>
        </div>
        <div id="all-in-rate-info">
          <h1>{`All in rate:`}</h1>
          <p>{`${averageRates.allin_rate_ckwh} ¢/kWh`}</p>
        </div>
        <div id="compare-price-info">
          <h1>{`Price to Compare:`}</h1>
          <p>{`${averageRates.rate_to_compare_ckwh} ¢/kWh`}</p>
        </div>
      </div>
    </Card>
  );
};

export default SummaryPanel;
