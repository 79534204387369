import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import selectDnpRnpHistoryContainer from "../selectors/dnpRnpHistory";
import * as actions from "../actions/dnpRnpHistory";
import Button from "@material-ui/core/Button";
import ReactTable from "react-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import * as C from "../../constants"; 
import { confirmationError, confirmationSuccess } from "../styles/common.css";

const styles = () => ({
    list: {
        listStyle: "none",
        paddingLeft: "0",
    },
    column: {
        width: "50%",
    },
});

class DnpRnpHistory extends Component {
    componentWillMount() {
        if (this.props.params.memberId) {
            this.props.doFetchMemberDnpTransactions(this.props.params.memberId);
        } else {
            this.props.doFetchAllDnpTransactions();
        }
    }

    componentWillUnmount() {}

    getDate = (value) => {
        if (!value) {
            return "";
        }
        const d = value.split("T")[0];
        return moment(d).format("MMM DD, YYYY");
    };

    handleChange = (event) => {
        const { target } = event;
        const { name } = target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        this.props.updateFilters({ [name]: value });
        if (this.props.params.memberId) {
            this.props.doFetchMemberDnpTransactions(this.props.params.memberId);
        } else {
            this.props.doFetchAllDnpTransactions();
        }
    };

    render() {
        const {
            status,
            transactions,
            showSnackBar,
            doSendDnpRequest,
            successMessage,
            errorMessage,
            closeSnackBar,
            doSendRnpRequest,
            updateFilters,
        } = this.props;
        const columns = [
            {
                Header: "DNP Request Date",
                accessor: "created",
                Cell: (row) => this.getDate(row.value),
            },
            {
                Header: "ESI ID",
                accessor: "esiID",
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "DNP Trans ID",
                accessor: "dnp_request_trans_id",
            },
            {
                Header: "Disconnect Registered",
                accessor: "disconnect_registered",
                Cell: (row) => this.getDate(row.value),
            },
            {
                Header: "RNP Trans ID",
                accessor: "rnp_request_trans_id",
            },
            {
                Header: "Reconnect Registered",
                accessor: "reconnect_registered",
                Cell: (row) => this.getDate(row.value),
            },
        ];

        return (
            <div>
                <h4>DNP/RNP Transactions</h4>
                Status Filter :{" "}
                <Select
                    value={status}
                    onChange={this.handleChange}
                    inputProps={{
                        name: "status",
                    }}
                    style={{ marginBottom: 20, marginLeft: 20, minWidth: 150 }}
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="pending_disconnect_nonpay">
                        Pending Disconnect
                    </MenuItem>
                    <MenuItem value="disconnected_nonpay">
                        Disconnected
                    </MenuItem>
                    <MenuItem value="rejected_disconnect">
                        Rejected Disconnect
                    </MenuItem>
                    <MenuItem value="pending_reconnect_nonpay">
                        Pending Reconnect
                    </MenuItem>
                    <MenuItem value="rejected_reconnect">
                        Rejected Reconnect
                    </MenuItem>
                    <MenuItem value="reconnected_nonpay">Reconnected</MenuItem>
                </Select>
                <ReactTable
                    data={transactions}
                    columns={columns}
                    className="-striped -highlight"
                    defaultPageSize={10}
                    defaultSortOrder="Request Date"
                />
                {this.props.params.memberId && (
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                            doSendDnpRequest(this.props.params.memberId)
                        }
                        style={{ marginTop: 20, marginRight: 20 }}
                    >
                        Submit DNP
                    </Button>
                )}
                {this.props.params.memberId && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                            doSendRnpRequest(this.props.params.memberId)
                        }
                        style={{ marginTop: 20 }}
                    >
                        Submit RNP
                    </Button>
                )}
                <Snackbar
                    id="message-id"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    open={showSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackbar}
                    className={
                        successMessage ? confirmationSuccess : confirmationError
                    }
                    message={successMessage || errorMessage}
                />
            </div>
        );
    }
}

DnpRnpHistory.propTypes = {
    transactions: PropTypes.array.isRequired,
};

const mapStateToProps = selectDnpRnpHistoryContainer();

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actions, dispatch),
    dispatch,
});

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(DnpRnpHistory)
);
