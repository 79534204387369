import React, { Component } from "react";
import { bindActionCreators } from "redux";
import moment from "moment";
import selectSignupContainer from "../selectors/signup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router";
import * as actions from "../actions/signup";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { confirmationError, confirmationSuccess } from "../styles/common.css";
import {
    UserLoginInfo,
    MemberInfo,
    ServiceAddressInfo,
} from "../components/signup";

const styles = (theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
});

class Signup extends Component {
    componentWillMount() {}
    componentWillUnmount() {}

    handleChange = (event) => {
        const { target } = event;
        const { name } = target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        return this.props.updateSignUpForm({ [name]: value });
    };

    handleDateChange = (name) => (date) => {
        const dateValue = `${moment(date).format("yyyy-MM-DD")}`;
        return this.props.updateSignUpForm({ [name]: dateValue });
    };

    render() {
        const {
            userID,
            memberID,
            meter_id,
            email,
            isNewUser,
            phone,
            first_name,
            last_name,
            birthday,
            address,
            streetAddress,
            streetAddress2,
            postalCode,
            serviceAddresses,
            memberAlreadyExists,
            canCreateUser,
            canCreateMember,
            getUserByEmail,
            createUser,
            createMember,
            esi,
            errorMessage,
            successMessage,
            showSnackBar,
            lookupServiceAddressMeter,
            setServiceAddress,
            resetPassword,
            clearForm,
            closeSnackbar,
        } = this.props;
        return (
            <form noValidate autoComplete="off">
                <Grid alignItems="flex-start" container>
                    {!memberAlreadyExists && (
                        <UserLoginInfo
                            userID={userID}
                            email={email}
                            getUserByEmail={getUserByEmail}
                            handleChange={this.handleChange}
                            resetPassword={resetPassword}
                            canCreateUser={canCreateUser}
                            createUser={createUser}
                            memberAlreadyExists={memberAlreadyExists}
                            isNewUser={isNewUser}
                        />
                    )}
                    {memberAlreadyExists && (
                        <span style={{ margin: 20 }}>
                            Member with {email} email address already exists!
                            Click below to view member information :
                            <div>
                                <Link
                                    to={`/members/${memberID}/details`}
                                    onClick={() => clearForm()}
                                >
                                    {first_name} {last_name} - {email}
                                </Link>
                            </div>
                            <div>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        clearForm();
                                    }}
                                    style={{ marginTop: 20 }}
                                >
                                    GO BACK
                                </Button>{" "}
                            </div>
                        </span>
                    )}
                    <Snackbar
                        id="message-id"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        open={showSnackBar}
                        autoHideDuration={3000}
                        onClose={closeSnackbar}
                        className={
                            successMessage
                                ? confirmationSuccess
                                : confirmationError
                        }
                        message={successMessage || errorMessage}
                    />
                </Grid>

                {!memberAlreadyExists && (
                    <div>
                        <Grid alignItems="flex-start" container>
                            <ServiceAddressInfo
                                userID={userID}
                                memberID={memberID}
                                address={address}
                                streetAddress={streetAddress}
                                streetAddress2={streetAddress2}
                                postalCode={postalCode}
                                meter_id={meter_id}
                                serviceAddresses={serviceAddresses}
                                lookupServiceAddressMeter={
                                    lookupServiceAddressMeter
                                }
                                esi={esi}
                                handleChange={this.handleChange}
                                setServiceAddress={setServiceAddress}
                            />
                        </Grid>
                        <Grid alignItems="flex-start" container>
                            <MemberInfo
                                userID={userID}
                                memberID={memberID}
                                first_name={first_name}
                                last_name={last_name}
                                birthday={birthday}
                                phone={phone}
                                handleChange={this.handleChange}
                                handleDateChange={this.handleDateChange}
                                createMember={createMember}
                                canCreateMember={canCreateMember}
                            />
                        </Grid>
                    </div>
                )}

                {userID && memberID && !memberAlreadyExists && (
                    <div>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                resetPassword();
                            }}
                            style={{ margin: 20 }}
                        >
                            Send Reset Password
                        </Button>
                        <Link to={`/members/${memberID}/details`}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    clearForm();
                                }}
                            >
                                VIEW MEMBER DETAILS
                            </Button>
                        </Link>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                clearForm();
                            }}
                            style={{ margin: 20 }}
                        >
                            CLEAR FORM
                        </Button>
                    </div>
                )}
            </form>
        );
    }
}

Signup.propTypes = {
    address: PropTypes.any,
    birthday: PropTypes.any,
    canCreateMember: PropTypes.any,
    canCreateUser: PropTypes.any,
    clearForm: PropTypes.func,
    closeSnackbar: PropTypes.any,
    createMember: PropTypes.any,
    createUser: PropTypes.any,
    email: PropTypes.any,
    errorMessage: PropTypes.any,
    esi: PropTypes.any,
    first_name: PropTypes.any,
    getUserByEmail: PropTypes.any,
    isNewUser: PropTypes.any,
    last_name: PropTypes.any,
    lookupServiceAddressMeter: PropTypes.any,
    memberAlreadyExists: PropTypes.any,
    memberID: PropTypes.any,
    meter_id: PropTypes.any,
    phone: PropTypes.any,
    postalCode: PropTypes.any,
    resetPassword: PropTypes.func,
    serviceAddresses: PropTypes.any,
    setServiceAddress: PropTypes.any,
    showSnackBar: PropTypes.any,
    streetAddress: PropTypes.any,
    streetAddress2: PropTypes.any,
    successMessage: PropTypes.any,
    updateSignUpForm: PropTypes.func,
    userID: PropTypes.any,
};

Signup.defaultProps = {};

const mapStateToProps = selectSignupContainer();

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actions, dispatch),
    dispatch,
});

const ConnectedSignup = connect(mapStateToProps, mapDispatchToProps)(Signup);

export default withStyles(styles)(ConnectedSignup);
