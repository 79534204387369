import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function receiveGuestUserInfo(json) {
	return {
		type: constants.RECEIVE_GUEST_USER_INFO,
		isFetching: false,
		payload: json,
	}
}

function requestGuestEmail(userID) {
  return (dispatch) => (
  	fetch(constants.GET_USER_URL, { userID: userID})
    	.then( user => (
				dispatch(receiveGuestUserInfo({email: user.email}))
    	))
  )
}

function requestGuestMemberInfo(userID) {
	return (dispatch) => (
		fetch(constants.GUEST_GET_ALL, { userID: userID })
			.then((guest) => (
				dispatch(receiveGuestUserInfo(guest.Members[0]))
			))
	)
}

export function fetchGuestInfo(userID) {
	return (dispatch) => {
			dispatch(requestGuestEmail(userID));
			dispatch(requestGuestMemberInfo(userID));
	}
}
