import React from "react";
import PropTypes from "prop-types";
import ReactInterval from "react-interval";
import priceStyles from "../styles/prices.css";

const PriceBubbles = ({ prices, timeout, cbAction }) => {
    const pricesByZones = prices.map((zonePrice) => {
        let priceValueStyle = priceStyles.low;
        if (zonePrice.get("value_score") > 20) { priceValueStyle = priceStyles.med; }
        if (zonePrice.get("value_score") > 50) { priceValueStyle = priceStyles.high; }
        if (zonePrice.get("value_score") > 80) { priceValueStyle = priceStyles.very_high; }

        const priceSign = zonePrice.get("price_sign");
        let price = zonePrice.get("price");

        if (price === "-0") {
            price = 0;
        }

        return (
            <div key={ zonePrice.get("zone") } className={ priceValueStyle }>
                <h4>{zonePrice.get("zone")}</h4>
                <p>{priceSign === "¢" ? price + priceSign : priceSign + price}</p>
            </div>
        );
    });

    return (
        <div className={ priceStyles.triColumn }>
            <ReactInterval
                timeout={ timeout }
                enabled
                callback={ () => cbAction() }
            />
            { pricesByZones }
        </div>
    );
};

PriceBubbles.propTypes = {
    prices: PropTypes.object.isRequired,
    cbAction: PropTypes.func.isRequired,
    timeout: PropTypes.number.isRequired,
};

export default PriceBubbles;
