import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import LaunchIcon from "@material-ui/icons/Launch";
import Collapse from "@material-ui/core/Collapse";

import { Link } from "react-router";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles({
  memberTable: {
    maxWidth: 900,
  },
  guestTable: {
    maxWidth: 300,
  },
  tableRow: {
    transition: "all 0.05s linear",
    "&:nth-child(odd)": {
      backgroundColor: "rgba(0,0,0,0.03)",
    },
    "&:hover": {
      backgroundColor: "lavender",
    },
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "17px",
      color: "white",
    },
  },
});

const statusStyle = (status) => {
  const style = {
    color: "black",
    fontWeight: "bold",
    opacity: "0.7",
  };
  switch (status) {
    case "connected":
      style.color = "green";
      break;
    case "disconnected":
      style.color = "red";
      break;
    case "pending_connection":
    case "pending_disconnection":
      style.color = "#f5c842";
      style.opacity = "0.8";
      break;
    default:
      return;
  }
  return style;
};

function createGuestData(guest, member) {
  const memberRoute = `/guest/${guest.get("userID")}/details`;

  const searchResult = {
    id: member.get("memberID"),
    zip_code: member.get("zip_code"),
    member: member,
    memberRoute: memberRoute,
  };

  return searchResult;
}

function createData(member) {
  const memberRoute = `/members/${member.get("memberID")}/details`;

  const meterDetailDefault = {
    start_date: "N/A",
    end_date: "N/A",
    meterID: "N/A",
    status: "N/A",
    account_balance: "N/A",
    last_successful_payment: "N/A",
  };

  if (member.has("meterDetail")) {
    const meterDetail = member.get("meterDetail");
    // Breakdown meter detail
    Object.keys(meterDetailDefault).map((key) => {
      if (meterDetail.has(key)) {
        if (key === "status") {
          const status = meterDetail.get("status").get("status");
          meterDetailDefault[key] = parseEmptyStrings(status);
        } else {
          meterDetailDefault[key] = parseEmptyStrings(meterDetail.get(key));
        }
      }
    });
  }
  // Return values that are empty strings as N/A
  function parseEmptyStrings(str) {
    if (!str || 0 === str.length) {
      return "N/A";
    }
    return str;
  }
  // Create a formated price string
  function money(string) {
    if (typeof(string) !== 'string' || 0 === string.length) {
      return "N/A";
    } else {
      if (string.charAt(0) === "-") {
        return `${string.slice(0, 1)}$${parseFloat(string.slice(1)).toFixed(
          2
        )}`;
      }
      return `$${parseFloat(string).toFixed(2)}`;
    }
  }

  // Merge Meter detail with member details for table
  const searchResult = Object.assign({}, meterDetailDefault, {
    firstName: parseEmptyStrings(member.get("first_name")),
    lastName: parseEmptyStrings(member.get("last_name")),
    meterAddress: parseEmptyStrings(member.get("meterAddress")),
    account_balance: money(member.get("account_balance")),
    last_successful_payment: money(member.get("last_successful_payment")),
    member: member,
    memberRoute: memberRoute,
  });
  return searchResult;
}

const ResultTable = (props) => {
  const classes = useStyles();
  const { guest, members, selectCurrentMember } = props;

  let header;
  let rows = [];

  if (members.first() && members.first().get("no_results") === true) {
    header = "No Results";
  } else {
    header = "Search Results";
    rows = members.map((member) => {
      return guest.get("guestSearch")
        ? createData(member)
        : createGuestData(guest, member);
    });
  }

  const memberColumns = [
    {
      id: "",
    },
    { id: "Name", label: "Name", minWidth: 140 },
    {
      id: "Meter Address",
      label: "Meter Address",
      minWidth: 140,
    },
    {
      id: "ESI ID",
      label: "ESI ID",
      minWidth: 140,
    },
    {
      id: "Start Date",
      label: "Start Date",
      minWidth: 65,
    },
    {
      id: "End Date",
      label: "End Date",
      minWidth: 65,
    },
    {
      id: "Status",
      label: "Status",
      minWidth: 100,
    },
    {
      id: "Balance",
      label: "Balance",
      minWidth: 100,
    },
    {
      id: "Last Sucecssful Payment",
      label: "Last Payment",
      minWidth: 100,
    },
  ];

  const guestColumns = [
    {
      id: "",
    },
    { id: "ID", label: "ID", minWidth: 90 },
    {
      id: "ZIP Code",
      label: "ZIP Code",
      minWidth: 90,
    },
  ];

  return (
    <div>
      <h2>{header}</h2>
      {guest.has("guestSearch") && guest.get("guestSearch") && (
        <Table
          className={
            guest.get("guestSearch") ? classes.memberTable : classes.guestTable
          }
        >
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              {((searchType) => {
                // Map the correct column table based on guestSearch value
                return searchType ? memberColumns : guestColumns;
              })(guest.get("guestSearch")).map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow className={classes.tableRow} key={index}>
                <TableCell component="th" scope="row">
                  <LaunchIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      selectCurrentMember(row.member);
                      window.open(row.memberRoute, "_self");
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.firstName} {row.lastName}
                </TableCell>
                <TableCell align="left">{row.meterAddress}</TableCell>
                <TableCell align="left">{row.meterID}</TableCell>
                <TableCell align="left">
                  {row.start_date.replace(/T.*Z/, "")}
                </TableCell>
                <TableCell align="left">
                  {row.end_date.replace(/T.*Z/, "")}
                </TableCell>
                <TableCell style={{ ...statusStyle(row.status) }} align="left">
                  {row.status}
                </TableCell>
                <TableCell align="left">{row.account_balance}</TableCell>
                <TableCell align="left">
                  {row.last_successful_payment}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ResultTable;
