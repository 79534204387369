import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import {
    requestEDFTrades,
    edfTrade,
    setSnackbar,
    setTradeAmount,
    setOperatingDate,
} from "../actions/edf";
import TextField from "@material-ui/core/TextField";
import { hrText } from "../styles/common.css";
import Modal from "react-modal";
import modalStyle from "../styles/modal";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { confirmationError, confirmationSuccess } from "../styles/common.css";

class EDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            operating_date: moment(),
            amount: "",
            message: {},
        };
        //this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleModalConfirm = this.handleModalConfirm.bind(this);
        this.handleClickEdf = this.handleClickEdf.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    }

    componentDidMount() {
        this.props.dofetchEDFTrades();
    }

    render() {
        const { edfTrades } = this.props;
        const rows = edfTrades.trades;
        const snackbar = edfTrades.snackbar;
        const operatingDate = edfTrades.operatingDate;
        const amount = edfTrades.amount;

        const columns = [
            {
                Header: "Operating Day",
                accessor: "operating_date",
                Cell: (row) => (
                    <div>
                        {moment.tz(row.value, "UTC").format("MMM DD, YYYY")}
                    </div>
                ),
            },
            {
                Header: "Trade Ammount",
                accessor: "amount_dollars",
                Cell: (row) => <div>${row.value ? row.value : 0}</div>,
            },
        ];

        return (
            <div>
                <KeyboardDatePicker
                    margin="normal"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={
                        (operatingDate &&
                            moment.utc(operatingDate).format("l LT")) ||
                        null
                    }
                    onChange={this.handleDateChange(`operating_date`)}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    style={{ marginRight: 10 }}
                />
                <TextField
                    label="Amount"
                    id="amount"
                    type="text"
                    onChange={this.handleChange("amount")}
                    onKeyPress={this.handleKeyPress}
                    value={amount}
                />
                <button
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                    onClick={this.handleClickEdf}
                    style={{ margin: 10 }}
                >
                    {"Enter Trade"}
                </button>

                <hr className={hrText} data-content="Saved EDF Trades" />
                <ReactTable
                    data={rows}
                    columns={columns}
                    style={{ height: "600px", width: "600px" }}
                    className="-striped -highlight"
                    filterable
                    noDataText=""
                    defaultPageSize={100}
                    showPagination={false}
                />
                <Modal isOpen={this.state.showModal} style={modalStyle.modal}>
                    Operating Date: {operatingDate.format("YYYY-MM-DD")} <br />
                    Trade Amount: {this.state.amount}
                    <br />
                    <Button
                        style={modalStyle.negativeButton}
                        onClick={this.handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={modalStyle.positiveButton}
                        onClick={this.handleModalConfirm}
                    >
                        Confirm
                    </Button>
                </Modal>
                <Snackbar
                    id="message-id"
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={snackbar.show}
                    autoHideDuration={5000}
                    onClose={this.handleCloseSnackbar}
                    className={
                        snackbar.success
                            ? confirmationSuccess
                            : confirmationError
                    }
                    message={snackbar.msg}
                />
            </div>
        );
    }
    handleCloseModal() {
        this.setState({ showModal: false });
    }
    handleModalConfirm() {
        this.setState({ showModal: false });
        const { createTrade } = this.props;
        createTrade({
            amount: this.state.amount,
            operating_date: this.props.edfTrades.operatingDate,
        });
    }
    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.handleClickEdf();
        }
    }
    handleClickEdf() {
        this.setState({ showModal: true });
    }
    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
        if (name === "amount") {
            this.props.setTradeAmount(event.target.value);
        }
    };

    handleCloseSnackbar() {
        const { setSnackbar } = this.props;
        setSnackbar({ show: false });
    }

    handleDateChange = (name) => (event) => {
        this.setState({
            [name]: moment(event),
        });
        this.props.setOperatingDate(moment(event));
    };
}

EDF.propTypes = {
    edfTrades: PropTypes.object,
    dofetchEDFTrades: PropTypes.func.isRequired,
};

EDF.defaultProps = {
    edfTrades: {
        rows: [],
        operatingDate: moment(),
        snackbar: { show: false },
    },
};

const mapStateToProps = (state) => ({
    edfTrades: state.edfResult,
});

const mapDispatchToProps = (dispatch) => ({
    dofetchEDFTrades: (s, e) => {
        dispatch(requestEDFTrades(s, e));
    },
    createTrade: (fields) => dispatch(edfTrade(fields)),
    setSnackbar: (snack) => dispatch(setSnackbar(snack)),
    setTradeAmount: (amount) => dispatch(setTradeAmount(amount)),
    setOperatingDate: (amount) => dispatch(setOperatingDate(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EDF);
