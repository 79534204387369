import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function receiveAuthHeaders(headers) {
    return {
        type: constants.RECEIVE_AUTH_HEADERS,
        isFetching: false,
        headers,
    };
}

function requestAuthHeaders() {
    return {
        type: constants.REQUEST_AUTH_HEADERS,
        isFetching: true,
    };
}

function receiveUserLogInError(payload) {
    return {
        type: constants.RECEIVE_USER_LOG_IN_ERROR,
        payload,
    };
}

export function hideSnackBar() {
    return {
        type: constants.HIDE_SNACKBAR,
    };
}

export function updateUserData({ userID }) {
    return {
        type: constants.USER_LOGGED_IN,
        userID,
    };
}

export function login(data) {
    return (dispatch) => {
        dispatch(requestAuthHeaders());
        return fetch(constants.LOGIN, data)
            .then((json) => {
                localStorage.setItem("access_token", json.access_token);
                localStorage.setItem("refresh_token", json.refresh_token);
                localStorage.setItem("userID", json.userID);
                dispatch(receiveAuthHeaders(json));
                dispatch(updateUserData({ userID: json.userID }));
                dispatch(getEmailAddress(json.userID));
            })
            .catch((error) => {
                dispatch(receiveUserLogInError(error));
            });
    };
}

export function updateUserEmail(email) {
    return {
        type: constants.USER_EMAIL_UPDATED,
        email,
    };
}

function getEmailAddress(userID) {
    return (dispatch) =>
        fetch(constants.GET_USER_URL, { userID })
            .then((res) => {
                const email = res.email;
                localStorage.setItem("email", email);
                dispatch(updateUserEmail(email));
            })
            .catch((error) => {
                dispatch(receiveUserLogInError(error));
            });
}

function hydrateUser(payload) {
    return {
        type: constants.HYDRATE_USER,
        payload,
    };
}

export function hydrate() {
    const email = localStorage.getItem("email");
    const userID = localStorage.getItem("userID");
    const payload = { userID, email };
    return (dispatch) => dispatch(hydrateUser(payload));
}

export function logout() {
    localStorage.clear();
    window.location.href = "/login";
    return {
        type: constants.USER_LOGGED_OUT,
    };
}
