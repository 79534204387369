import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dateFormat from "dateformat";
import fetchSavingsData from "../actions/savings";

const column1Style = {
    textAlign: "center",
    // background: "#f1bb72ad",
};
const column2Style = {
    textAlign: "center",
    // background: "#ff408157",
};

const headerStyle = {
    fontWeight: "700",
};
const labelStyle = {
    fontWeight: "500",
    fontSize: "18px",
    fontColor: "gray",
    margin: "5px",
};
const valueStyle = {
    fontWeight: "900",
    fontSize: "18px",
};

class MemberSavings extends Component {
    componentDidMount() {
        const { onFetchSavingsData, guest, params } = this.props;
        const guestId = params.guestUserId;
        const memberId = guestId ? guest.get("memberID") : params.memberId;

        if (guestId && guest.get("memberID")) {
            onFetchSavingsData(memberId, guestId);
        } else if (!guestId) {
            onFetchSavingsData(memberId);
        }
    }

    componentDidUpdate() {
        const {
            onFetchSavingsData,
            guest,
            savingsData,
            params,
        } = this.props;
        const guestId = params.guestUserId;
        const memberId = guestId ? guest.get("memberID") : params.memberId;

        if (Object.keys(savingsData).length === 0) {
            onFetchSavingsData(memberId, guestId);
        }
    }

    getAndSortSavingsData() {
        if (this.props.savingsData.data && this.props.savingsData.data.monthly) {
            return this.props.savingsData.data.monthly.sort((a, b) => {
                if (new Date(a.period).getTime() > new Date(b.period).getTime()) {
                    return -1;
                }
                return 1;
            });
        }
        return [];
    }
    render() {
        // defining columns for ReactTable
        const cardColumns = [{
            id: "period",
            Header: "Month",
            accessor: savings => dateFormat(savings.period, "mmm dd, yyyy", true),
            style: column1Style,
            headerStyle,
            sortMethod: (a, b) => {
                if (new Date(a).getTime() < new Date(b).getTime()) {
                    return -1;
                }
                return 1;
            },
        }, {
            id: "usage",
            Header: "Usage ( KW/h )",
            accessor: saving => parseFloat(saving.usage),
            style: column1Style,
            headerStyle,
        }, {
            id: "savings",
            Header: "Savings ( $ )",
            accessor: saving => parseFloat(saving.savings),
            style: column1Style,
            headerStyle,
        }, {
            id: "griddy_all_in_costs",
            Header: "Total Griddy Costs ( $ )",
            accessor: saving => parseFloat(saving.griddy_all_in_costs),
            style: column2Style,
            headerStyle,
        }, {
            id: "eia_costs",
            Header: "TX Avg Costs ( $ )",
            accessor: saving => parseFloat(saving.eia_costs),
            style: column2Style,
            headerStyle,
        }, {
            id: "griddy_allin_rate",
            Header: "Griddy all-in Rate ( ¢ )",
            accessor: saving => parseFloat(saving.griddy_allin_rate),
            style: column1Style,
            headerStyle,
        }, {
            id: "eia_allin_rate",
            Header: "TX Avg all-in Rate ( ¢ )",
            accessor: saving => parseFloat(saving.eia_allin_rate),
            style: column1Style,
            headerStyle,
        }, {
            id: "eia_rate",
            Header: "EIA Rate ( ¢ )",
            accessor: saving => parseFloat(saving.eia_rate),
            style: column2Style,
            headerStyle,
        }, {
            id: "eia_tax_cpkwh",
            Header: "EIA Tax Add-On ( ¢ )",
            accessor: saving => parseFloat(saving.eia_tax_cpkwh),
            style: column2Style,
            headerStyle,
        }, {
            id: "is_rolling_avg",
            Header: "EIA Rate (Published officially)",
            accessor: saving => { const rollingAvg = saving.is_rolling_avg ? "Estimate" : "Actualized"; return rollingAvg; },
            style: column2Style,
            headerStyle,
        }, {
            id: "griddy_taxes",
            Header: "Griddy Taxes ( $ )",
            accessor: saving => parseFloat(saving.griddy_taxes),
            style: column1Style,
            headerStyle,
        }, {
            id: "eia_taxes",
            Header: "EIA Taxes ( $ )",
            accessor: saving => parseFloat(saving.eia_taxes),
            style: column1Style,
            headerStyle,
        }];
        const savingsMonthlyArray = this.getAndSortSavingsData();
        const savingsTotal = (this.props.savingsData.data && this.props.savingsData.data.totals) ? this.props.savingsData.data.totals : [];
        return (
            <div>
                <h4>Savings Data</h4>
                <div style={ labelStyle }>
                   Total lifetime savings with Griddy:
                    <span style={ valueStyle }> {savingsTotal.savings < 0 ? `-$${savingsTotal.savings * -1}` : `$${savingsTotal.savings}`} </span>
                </div>
                <div style={ labelStyle }>
                   Lifetime rate with Griddy:
                    <span style={ valueStyle }>  {savingsTotal.griddy_allin_rate} ¢ </span>
                </div>
                <h4>Monthly Savings</h4>
                <ReactTable
                    data={ savingsMonthlyArray }
                    columns={ cardColumns }
                    loading={ this.props.savingsData.isFetching }
                    defaultPageSize={ 10 }
                    className="-striped -highlight"
                />
            </div>
        );
    }
}

MemberSavings.propTypes = {
    savingsData: PropTypes.object,
    params: PropTypes.object.isRequired,
    onFetchSavingsData: PropTypes.func.isRequired,
    guest: PropTypes.object,
};

MemberSavings.defaultProps = {
    savingsData: {},
    guest: {},
};

const mapStateToProps = state => ({
    savingsData: JSON.parse(JSON.stringify(state.savingsData)),
    guest: state.guest,
});

const mapDispatchToProps = dispatch => ({
    onFetchSavingsData: (member, guest) => dispatch(fetchSavingsData(member, guest)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MemberSavings);
