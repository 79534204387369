import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestTdspInvoiceReport() {
    return {
        type: constants.REQUEST_TDSP_INVOICE_REPORT,
        isFetching: true,
    };
}

function recieveTdspInvoiceReport(data) {
    return {
        type: constants.RECEIVE_TDSP_INVOICE_REPORT,
        isFetching: false,
        rows: data,
    };
}

export default function fetchTdspInvoice(memberID) {
    return (dispatch) => {
        dispatch(requestTdspInvoiceReport());
        return fetch(constants.TDSP_INVOICE, { memberID })
            .then((data) => {
                data.rows.map((v) => {
                    const newTdsp = v;
                    newTdsp.tdsp_credit *= -1;
                    newTdsp.reconciliation = Number(v.invoice_amount_dollars) - (Number(v.tdsp_shadow_amount_dollars) + Number(v.tdsp_credit));
                    return newTdsp;
                });
                dispatch(recieveTdspInvoiceReport(data.rows));
            });
    };
}
