import { createSelector } from "reselect";

const taxInfo = () => (state) => state.tax;

const selectTaxInfoContainer = () =>
  createSelector(taxInfo(), (subState) =>
    Object.assign(
      {},
      {
        ...subState,
      }
    )
  );

export default selectTaxInfoContainer;
