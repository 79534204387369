import { createSelector } from "reselect";

const dnpRnpHistoryDomain = () => (state) => state.dnpRnpHistory;

const selectDnpRnpHistoryContainer = () =>
    createSelector(dnpRnpHistoryDomain(), (subState) =>
        Object.assign({}, subState)
    );

export default selectDnpRnpHistoryContainer;
