import React from "react";
import { Route, Redirect } from "react-router";
import { routerActions } from "react-router-redux";
import { UserAuthWrapper } from "redux-auth-wrapper";
import { App, Member, Guest } from "./components";

import MemberDetails from "./containers/MemberDetails";
import MemberPayment from "./containers/MemberPayment";
import MemberSup from "./containers/MemberSup";
import MemberBusiness from "./containers/MemberBusiness";
import MemberSavings from "./containers/MemberSavings";
import MemberDisputes from "./containers/MemberDisputes";
import DnpRnpHistory from "./containers/DnpRnpHistory";
import GuestDetails from "./containers/GuestDetails";
import MeterTransactions from "./containers/MeterTransactions";
import PromoDetails from "./containers/PromoDetails";
import PromoSearch from "./containers/PromoSearch";

import Ercot from "./containers/Ercot";
import EDF from "./containers/EDF";
import Tdsp from "./containers/Tdsp";
import Meter from "./containers/Meter";
import Dashboard from "./containers/Dashboard";
import Usage from "./containers/Usage";
import Members from "./containers/Members";
import Login from "./containers/Login";
import MeterUpdate from "./containers/MeterUpdate";
import Signup from "./containers/Signup";
import Dnp from "./containers/Dnp";
import DnpRnp from "./containers/DnpRnp";

import UserNotesContainer from "./containers/UserNotes";
import MemberBillingContainer from "./containers/Billings";
import IterableContainer from "./containers/Iterable";

const UserIsAuthenticated = UserAuthWrapper({
  authSelector: (state) => state.user, // how to get the user state
  redirectAction: routerActions.replace, // the redux action to dispatch for redirect
  wrapperDisplayName: "UserIsAuthenticated", // a nice name for this auth check
  predicate: (user) => user.userID,
});

export default (
  <div>
    <Route component={App}>
      <Route path="login" component={Login} />
      <Route path="dashboard" component={UserIsAuthenticated(Dashboard)} />
      <Route path="members" component={UserIsAuthenticated(Members)} />
      <Route
        exact
        path="members/:memberId"
        component={UserIsAuthenticated(Member)}
      >
        <Route path="usage" component={UserIsAuthenticated(Usage)} />
        <Route path="details" component={UserIsAuthenticated(MemberDetails)} />
        <Route
          path="billing"
          component={UserIsAuthenticated(MemberBillingContainer)}
        />
        <Route path="payment" component={UserIsAuthenticated(MemberPayment)} />
        <Route path="sup" component={UserIsAuthenticated(MemberSup)} />
        <Route
          path="business"
          component={UserIsAuthenticated(MemberBusiness)}
        />
        <Route path="savings" component={UserIsAuthenticated(MemberSavings)} />
        <Route
          path="disputes"
          component={UserIsAuthenticated(MemberDisputes)}
        />
        <Route path="dnprnp" component={UserIsAuthenticated(DnpRnpHistory)} />
        <Route
          path="metertransactions"
          component={UserIsAuthenticated(MeterTransactions)}
        />
        <Route
          path="notes"
          component={UserIsAuthenticated(UserNotesContainer)}
        />
        <Route
          path="iterable"
          component={UserIsAuthenticated(IterableContainer)}
        />
      </Route>
      <Route path="signup" component={UserIsAuthenticated(Signup)} />
      <Route path="dnprnp" component={DnpRnp}>
        <Route path="dnpqueue" component={UserIsAuthenticated(Dnp)} />
        <Route
          path="dnprnptransactions"
          component={UserIsAuthenticated(DnpRnpHistory)}
        />
      </Route>
      <Route
        path="metertransactions"
        component={UserIsAuthenticated(MeterTransactions)}
      />
      <Route
        exact
        path="guest/:guestUserId"
        component={UserIsAuthenticated(Guest)}
      >
        <Route path="usage" component={UserIsAuthenticated(Usage)} />
        <Route path="details" component={UserIsAuthenticated(GuestDetails)} />
        <Route path="savings" component={UserIsAuthenticated(MemberSavings)} />
      </Route>
      <Route exact path="promos" component={UserIsAuthenticated(PromoSearch)} />
      <Route
        exact
        path="promo/new"
        component={UserIsAuthenticated(PromoDetails)}
      />
      <Route
        exact
        path="promo/:promoCode"
        component={UserIsAuthenticated(PromoDetails)}
      />
      <Route path="ercot" component={UserIsAuthenticated(Ercot)} />
      <Route path="tdsp" component={UserIsAuthenticated(Tdsp)} />
      <Route path="meters" component={UserIsAuthenticated(Meter)} />
      <Route
        path="meters/:esiID"
        component={UserIsAuthenticated(MeterUpdate)}
      />
      <Route path="edf" component={UserIsAuthenticated(EDF)} />
    </Route>
    <Redirect from="/" to="/dashboard" />
  </div>
);
