import * as C from "../../constants";

export const requestMeterLookup = payload => ({
    type: C.REQUEST_METER_LOOKUP,
    payload,
});
    
export const requestMeterLookupSuccess = payload => ({
    type: C.REQUEST_METER_LOOKUP_SUCCESS,
    payload,
});

export const requestMeterLookupFailure = () => ({
    type: C.REQUEST_METER_LOOKUP_FAILURE,
});

export const meterUpdated = payload => ({
    type: C.METER_LOOKUP_UPDATED,
    payload,
});

export const meterLookupUpdateSuccess = payload => ({
    type: C.METER_LOOKUP_UPDATE_SUCCESS,
    payload,
});

export const meterLookupUpdateFailure = () => ({
    type: C.METER_LOOKUP_UPDATE_FAILURE,
});

export const hideSnackBar = () => ({
    type: C.HIDE_METER_LOOKUP_SNACKBAR,
});
