import fish from "isomorphic-fetch";

function getUrl(path) {
    const fullUrl = getBaseUrl(path); // returns url
    return fullUrl;
}
function getBaseUrl(url) {
    let endpointPrefix = "https://development.gogriddy.com/api/v1";
    if (window.location.href.includes("app.gogriddy")) {
        endpointPrefix = "https://app.gogriddy.com/api/v1";
    }
    return endpointPrefix + url;
}

function getOptions(url, payload) {
    const options = {
        method: "POST",
        body: JSON.stringify(payload),
    };
    const accessToken = localStorage.getItem("access_token");
    if (!url.includes("signin") && accessToken) {
        const bearerToken = `Bearer ${localStorage.getItem("access_token")}`;
        const customHeaders = new Headers();
        customHeaders.append("Authorization", bearerToken);
        options.headers = customHeaders;
        options.mode = "cors";
    }
    return options;
}

function refreshTokenAndRetry(url, payload) {
    const refreshToken = localStorage.getItem("refresh_token");
    localStorage.removeItem("access_token");
    if (refreshToken) {
        const tokenRefreshUrl = getUrl("/users/token/refresh");
        const refreshOptions = getOptions(tokenRefreshUrl, {
            token: refreshToken,
        });
        refreshOptions.mode = "cors";
        return fish(tokenRefreshUrl, refreshOptions)
            .then((responseOne) => responseOne.json())
            .then((responseTwo) => {
                localStorage.setItem("access_token", responseTwo.access_token);
                const options = getOptions(url, payload);
                return fish(url, options)
                    .then((responseThree) => responseThree.json())
                    .then((responseFour) => responseFour);
            });
    }
    return undefined;
}

export default function fetch(path, payload) {
    const url = getUrl(path);
    const options = getOptions(url, payload);
    return fish(url, options)
        .then((response) =>
            response.json().then((json) => {
                if (response.status === 401) {
                    // Authorization Error
                    return refreshTokenAndRetry(url, payload);
                } else if (response.status >= 200 && response.status < 300) {
                    // Any kinds of Success
                    return json;
                } else if (response.status >= 400) {
                    // Any kinds of failure other than Authorization
                    return Promise.reject(json);
                }
                return undefined;
            })
        )
        .catch((error) => {
            if (error) {
                return Promise.reject(error);
            }
            return undefined;
        });
}
