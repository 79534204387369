import timestamp from "internet-timestamp";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestChargeList() {
    return {
        type: constants.REQUEST_PAYMENT_CHARGES,
        isFetching: true,
    };
}
function receiveChargeList(data) {
    return {
        type: constants.RECEIVE_PAYMENT_CHARGES,
        isFetching: false,
        data,
    };
}
function requestCardList() {
    return {
        type: constants.REQUEST_PAYMENT_CARDS,
        isFetching: true,
    };
}
function receiveCardList(data) {
    return {
        type: constants.RECEIVE_PAYMENT_CARDS,
        isFetching: false,
        data,
    };
}

function requestApplyRefund() {
    return {
        type: constants.REQUEST_PAYMENT_REFUND,
        isFetching: true,
    };
}
function receiveApplyRefund(data) {
    return {
        type: constants.RECEIVE_PAYMENT_REFUND,
        isFetching: false,
        data,
    };
}

export function fetchChargeList(member) {
    return (dispatch) => {
        dispatch(requestChargeList);
        const endDate = timestamp(new Date());
        let startDate = new Date();
        startDate.setYear(new Date().getFullYear() - 5);
        startDate = timestamp(startDate);
        return fetch(constants.PAYMENT_LIST_CHARGES, {
            memberID: member,
            start_date: startDate,
            end_date: endDate,
        }).then((data) => {
            dispatch(receiveChargeList(data.charges));
        });
    };
}

export function fetchCardList(member) {
    return (dispatch) => {
        dispatch(requestCardList);
        dispatch({type: constants.PAYMENTS_INITIALIZED, isLoading: true })
        return fetch(constants.PAYMENT_LIST_CARDS, { memberID: member }).then(
            (data) => {
                dispatch(receiveCardList(data.card));
                dispatch({type: constants.PAYMENTS_INITIALIZED, isLoading: false })
            }
        );
    };
}

export function toggleCharge(chargeIndex) {
    return {
        type: constants.TOGGLE_CHARGE,
        index: chargeIndex,
    };
}

export function applyRefund(chargeObj, member) {
    return (dispatch) => {
        dispatch(requestApplyRefund);
        return fetch(constants.PAYMENT_REFUND, {
            memberID: member.get("memberID"),
            chargeID: chargeObj.get("chargeID"),
            amount: chargeObj.get("Amount"),
        }).then((data) => {
            dispatch(receiveApplyRefund(data));
        });
    };
}

export function clearConfMsg() {
    return {
        type: constants.CLEAR_MEMBER_CONF_MSG,
    };
}

export const removePayment = (memberID) => {
    return (dispatch, getState) => {
        const { cardID } = getState().payments;
        dispatch({ type: constants.PAYMENTS_TOGGLE_CONFIRM_DELETE });
        return fetch(constants.PAYMENT_REMOVE_CARD, {
            memberID,
            cardID,
        })
            .then(() => {
                fetchCardList(memberID)(dispatch);
            })
            .catch((err) => {
                dispatch({
                    type: constants.PAYMENTS_SET_ERROR,
                    errorMessage: err.message,
                });
            });
    };
};

export const toggleConfirmCardDelete = () => {
    return (dispatch) => {
        dispatch({ type: constants.PAYMENTS_TOGGLE_CONFIRM_DELETE });
    };
};
export const confirmPaymentDelete = (cardID, cardLastFour) => {
    return (dispatch) => {
        dispatch({
            type: constants.PAYMENTS_SET_CARD_DELETE,
            cardID,
            cardLastFour,
        });
    };
};

export const clearErrorMessage = () => {
    return (dispatch) => {
        dispatch({ type: constants.PAYMENTS_CLEAR_ERROR });
    };
};
