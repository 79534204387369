import * as constants from "../../constants";
import fetchIT from "../util/api-iterable";
import selectIterableDataContainer from "../selectors/iterable";

export const createErrorMessage = (errorMessage) => ({
  type: constants.ITERABLE_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.ITERABLE_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

const setIsProcessing = (isProcessing) => {
  return {
    type: constants.ITERABLE_UPDATE_STATE,
    updatedInfo: {
      isProcessing,
    },
  };
};

const addToTemplateObject = (payload) => {
  return {
    type: constants.ITERABLE_ADD_TO_TEMPLATES,
    payload,
  };
};

const setChannelCollection = (payload) => {
  return {
    type: constants.ITERABLE_SET_CHANNEL_COLLECTION,
    payload,
  };
};

const setCampaignCollection = (payload) => {
  return {
    type: constants.ITERABLE_SET_CAMPAIGN_COLLECTION,
    payload,
  };
};

const setIterableUserData = (payload) => {
  return {
    type: constants.ITERABLE_REQUEST_SUCCESS,
    payload,
  };
};
export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.ITERABLE_CLOSE_SNACKBAR });
};

export const requestIterableUserData = (email) => (dispatch, getState) => {
  const { templateCollection } = selectIterableDataContainer()(getState());
  
  dispatch(setIsProcessing(true));

  return fetchIT
    .get(`${constants.ITERABLE_USER_INFO}?email=${email}`)
    .then((res) => {
      dispatch({
        type: constants.ITERABLE_UPDATE_USER_INFO,
        payload: res.data,
      });

      return fetchIT.get(`${constants.ITERABLE_USER_EVENTS}?email=${email}`);
    })
    .then((eventRes) => {
      eventRes.data.events.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const promises = eventRes.data.events.map(async (event) => {
        if (event.eventType === "emailSend") {
          if (templateCollection.hasOwnProperty(event.templateId)) {
            event.emailTemplate = templateCollection[event.templateId];
          } else {
            let emailTemplate = await requestIterableEmailTemplate(
              event.templateId,
              dispatch
            );
            event.emailTemplate = emailTemplate;
          }
          return event;
        }
        return event;
      });

      return Promise.all(promises);
    })
    .then((eventsPromiseRes) => {
      dispatch(setIterableUserData({ events: eventsPromiseRes }));
      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableUserInfo = (email) => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetchIT
    .get(`${constants.ITERABLE_USER_INFO}?email=${email}`)
    .then((res) => {
      dispatch({
        type: constants.ITERABLE_UPDATE_USER_INFO,
        payload: res.data,
      });
      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableUserMessages = (email) => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetchIT
    .get(`${constants.ITERABLE_USER_MESSAGES}?email=${email}`)
    .then((res) => {
      dispatch(setIterableUserData(res.data));
      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableUserEvents = (email) => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetchIT
    .get(`${constants.ITERABLE_USER_EVENTS}?email=${email}`)
    .then((res) => {
      res.data.events.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      const promises = res.data.events.map(async (event) => {
        if (event.eventType === "emailSend") {
          let emailTemplate = await requestIterableEmailTemplate(
            event.templateId,
            dispatch
          );
          event.emailTemplate = emailTemplate;
          return event;
        }
        return event;
      });

      Promise.all(promises)
        .then((events) => {
          dispatch(setIterableUserData({ events: events }));
          dispatch(setIsProcessing(false));
        })
        .catch((err) => {
          dispatch(setErrorMessage(err));
          dispatch(setIsProcessing(false));
        });
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableEmailView = (email, messageId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setIsProcessing(true));
    return fetchIT
      .get(
        `${constants.ITERABLE_VIEW_EMAIL_IN_BROWSER}?email=${email}&messageId=${messageId}`
      )
      .then((res) => {
        dispatch(setIsProcessing(false));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(setIsProcessing(false));
        reject(err);
      });
  });
};

export const requestIterableChannels = () => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetchIT
    .get(constants.ITERABLE_CHANNELS)
    .then((res) => {
      res.data.channels.sort(function (a, b) {
        return a.id - b.id;
      });
      res.data.channels.sort(function (a, b) {
        return a.channelType < b.channelType
          ? -1
          : a.channelType > b.channelType
          ? 1
          : 0;
      });

      const collection = res.data.channels.reduce((obj, channel) => {
        return {
          ...obj,
          [channel.id]: channel,
        };
      }, {});

      dispatch(setIterableUserData(res.data));
      dispatch(setChannelCollection(collection));
      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableCampaigns = () => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetchIT
    .get(constants.ITERABLE_CAMPAIGNS)
    .then((res) => {
      const collection = res.data.campaigns.reduce((obj, campaign) => {
        return {
          ...obj,
          [campaign.id]: campaign,
        };
      }, {});

      dispatch(setCampaignCollection(collection));

      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableEmailTemplate = (templateId, dispatch) => {
  return new Promise((resolve, reject) => {
    return fetchIT
      .get(`${constants.ITERABLE_GET_EMAIL_TEMPLATE}?templateId=${templateId}`)
      .then((res) => {
        dispatch(
          addToTemplateObject({ templateId: templateId, template: res.data })
        );
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export function clearIterableUserData() {
  return (dispatch) =>
    dispatch({
      type: constants.CLEAR_ITERABLE_DATA,
    });
}