import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { noteCreate } from "../../actions/members";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import RemoveIcon from "@material-ui/icons/Remove";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import Collapse from "@material-ui/core/Collapse";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles({
  root: {
    marginBottom: "10px",
    width: "500px",
    maxHeight: "500px",
    position: "fixed",
    top: "100%",
    left: "100%",
    transform: "translate(calc(-102% - 100px), calc(-102% - 60px))",
    zIndex: "1",
    transition: "all 0.3s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "350px",
    cursor: "pointer",
  },
  textAreaContainer: {
    margin: "0px 10px 10px 10px",
    height: "220px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    "& .MuiSvgIcon-root": {
      marginLeft: "5px",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "2px solid rgba(32,33,36,0.2)",
  },
  optionContainer: {
    margin: "0px 10px",
    borderTop: "2px solid rgba(32,33,36,0.2)",
    display: "flex",
    justifyContent: "flex-end",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  badge: {
    left: "15px",
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-5px",
      transform: "scale(1.2)",
    },
  },
});

const NoteCreationPanel = (props) => {
  const classes = useStyles();
  const { adminUser } = props;
  const [userNoteBody, setUserNoteBody] = useState("");
  const [toggleAddButton, setToggleAddButton] = useState(false);
  const [toggleMinimizeButton, setToggleMinimizeButton] = useState(true);

  const handleChange = (e) => {
    if (e.target.value === "") {
      setToggleAddButton(false);
    } else if (toggleAddButton !== true) {
      setToggleAddButton(true);
    }
    setUserNoteBody(e.target.value);
  };

  const clearUserNoteBody = () => {
    setUserNoteBody("");
    setToggleAddButton(false);
  };

  const addNote = () => {
    if (userNoteBody === "") {
      return;
    }
    const { onNoteCreate, adminUser, member } = props;

    onNoteCreate(
      Number(adminUser.userID),
      adminUser.email,
      member.get("userID"),
      member.get("memberID"),
      userNoteBody
    );

    setUserNoteBody("");
    setToggleAddButton(false);
    setToggleMinimizeButton(true);
  };
  const inlineRootStyle = {
    maxHeight: !toggleMinimizeButton ? null : "35px",
    width: !toggleMinimizeButton ? null : "200px",
  };

  const inlineNoteContainerStyle = {
    backgroundColor: !toggleMinimizeButton ? null : "transparent",
    border: !toggleMinimizeButton ? null : "none",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  };

  const inlineMenuBarStyle = {
    borderBottomLeftRadius: !toggleMinimizeButton ? null : "10px",
    borderBottomRightRadius: !toggleMinimizeButton ? null : "10px",
  };

  return (
    <div className={classes.root} style={{ ...inlineRootStyle }}>
      {userNoteBody !== "" && toggleMinimizeButton && (
        <Badge
          classes={{ root: classes.badge }}
          badgeContent={"!"}
          color="error"
        ></Badge>
      )}
      <Card
        className={classes.createNoteContainer}
        style={{ ...inlineNoteContainerStyle }}
        variant="outlined"
      >
        <div
          className={classes.menuBar}
          style={{ ...inlineMenuBarStyle }}
          onClick={() => {
            setToggleMinimizeButton(!toggleMinimizeButton);
          }}
        >
          <span>Add Note</span>
          <div>
            {toggleMinimizeButton ? (
              <AspectRatioIcon onClick={() => setToggleMinimizeButton(false)} />
            ) : (
              <RemoveIcon onClick={() => setToggleMinimizeButton(true)} />
            )}
          </div>
        </div>
        <Collapse in={!toggleMinimizeButton}>
          <div className={classes.header}>
            <span>{adminUser.email}</span>
          </div>
          <div className={classes.textAreaContainer}>
            <textarea
              className={classes.textArea}
              value={userNoteBody}
              placeholder={"Describe user details ..."}
              autoFocus
              id="user-note"
              onChange={(e) => handleChange(e)}
              style={{ height: "100%", border: "none", overflowY: "auto" }}
            />
          </div>
          <div className={classes.optionContainer}>
            <Button
              color="secondary"
              varian="raised"
              onClick={() => clearUserNoteBody()}
            >
              Clear
            </Button>
            <Button
              classes={{
                root: !toggleAddButton
                  ? classes.disableButton
                  : classes.enableButton,
              }}
              color="primary"
              varian="raised"
              onClick={() => addNote()}
            >
              Add Note
            </Button>
          </div>
        </Collapse>
      </Card>
    </div>
  );
};

NoteCreationPanel.propTypes = {
  member: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
  adminUser: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  onNoteCreate: (authorID, authorEmail, userID, memberID, body) =>
    dispatch(noteCreate(authorID, authorEmail, userID, memberID, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteCreationPanel);