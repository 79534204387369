import { mergeDeepRight } from "ramda";
import * as C from "../../constants";

const initialState = {
    showSnackbar: false,
    actionResponse: "",
    actionMessage: "",
    meter: {},
};

export default function meterLookupDetails(state = initialState, action) {
    switch(action.type) {
    case C.REQUEST_METER_LOOKUP_SUCCESS: {
	const success = {
	    meter: action.payload,
	}
	return mergeDeepRight(state, success);
    }
    case C.REQUEST_METER_LOOKUP_FAILURE: {
	const failure = {
	    showSnackbar: true,
	    actionResponse: "failure",
	    actionMessage: "Meter lookup failed",
	    meter: {},
	}
	return mergeDeepRight(state, failure);
    }
    case C.METER_LOOKUP_UPDATE_SUCCESS: {
	const success = {
	    showSnackbar: true,
	    actionResponse: "success",
	    actionMessage: "Meter update successful",
	    meter: action.payload,
	}
	return mergeDeepRight(state, success);
    }
    case C.METER_LOOKUP_UPDATE_FAILURE: {
	const failure = {
	    showSnackbar: true,
	    actionResponse: "failure",
	    actionMessage: "Meter update failed",
	    meter: {},
	}
	return mergeDeepRight(state, failure);
    }
    case C.HIDE_METER_LOOKUP_SNACKBAR: {
	return mergeDeepRight(state, {
	    showSnackbar: false,
	});
    }
    default:
	return state;
    }
}
