import { takeLatest, takeEvery, call, select, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { find, propEq, set, view, lensProp } from "ramda";
import "babel-polyfill";
import * as C from "../../constants";
import fetch from "../util/api-ajax";
import {
    receivePromosSuccess,
    setCurrentPromo,
    createPromoRequest,
    promoCreateSuccess,
    promoCreateFailure,
    promoUpdatedSuccess,
    promoUpdatedFailure,
    updatePromoRequest,
} from "../actions/promos";
import { selectPromoSearchResults } from "../selectors/promos";

function* fetchAllPromos() {
    try {
        const { promos } = yield fetch(C.PROMO_GET_ALL_URL);
        yield put(receivePromosSuccess(promos));
    } catch (err) {
        console.log(err);
    }
}

function* loadPromo(action) {
    const { payload: { promoCode } } = action;
    const promos = yield select(selectPromoSearchResults);
    const selectedPromoInStore = find(propEq("code", promoCode), promos);
    let selectedPromo = {};
    if (selectedPromoInStore) {
        selectedPromo = selectedPromoInStore;
    } else {
        try {
            const { promo } = yield call(fetch, C.PROMO_GET, { code: promoCode });
            promo.is_active = promo.is_active.toString();
            selectedPromo = promo;
        } catch (err) {
            console.log(err);
            return;
        }
    }
    yield put(setCurrentPromo(selectedPromo));
}

function preparePromo(action) {
    const { payload } = action;
    const isActive = lensProp("is_active");
    return set(isActive, Boolean(view(isActive, payload)), payload);
}

function* createPromo(action) {
    const preparedPromo = preparePromo(action);
    yield put(createPromoRequest());
    try {
        const { promo, successful, error } = yield call(fetch, C.PROMO_CREATE_URL, preparedPromo);
        if (!successful) {
            throw error;
        }
        yield put(promoCreateSuccess(promo));
        yield put(push(`/promo/${promo.code}`));
    } catch (err) {
        yield put(promoCreateFailure(err));
    }
}

function* updatePromo(action) {
    yield put(updatePromoRequest());
    const preparedPromo = preparePromo(action);
    try {
        const { promo, successful, error } = yield call(fetch, C.PROMO_UPDATE_URL, preparedPromo);
        if (!successful) {
            throw error;
        }
        yield put(promoUpdatedSuccess(promo));
        yield put(push(`/promo/${promo.code}`));
    } catch (err) {
        yield put(promoUpdatedFailure(err));
    }
}

export default [
    takeLatest(C.REQUEST_PROMOS_GET_ALL, fetchAllPromos),
    takeEvery(C.CURRENT_PROMO_SELECTED, loadPromo),
    takeLatest(C.PROMO_UPDATED, updatePromo),
    takeLatest(C.PROMO_CREATED, createPromo),
];
