import { mergeRight, unionWith, eqBy, prop, toString, set, view, lensProp, map } from "ramda";
import * as C from "../../constants";

const promosDefaultState = {
    isFetching: false,
    showSnackBar: false,
    actionResponse: "",
    actionMessage: "",
    searchResults: [],
    currentPromo: {},
};

export default function promos(state = promosDefaultState, action) {
    switch (action.type) {
    case C.RECEIVE_PROMOS_SUCCESS: {
        const isActive = lensProp("is_active");
        const stringifyBoolean = promo => set(isActive, toString(view(isActive, promo)), promo);
        const modifiedPromos = map(stringifyBoolean, action.payload);

        const promoArray = { searchResults: unionWith(eqBy(prop("id")), state.searchResults, modifiedPromos) };
        return mergeRight(state, promoArray);
    }
    case C.SET_CURRENT_PROMO: {
        return mergeRight(state, { currentPromo: action.payload });
    }
    case C.CLEAR_PROMO_SEARCH_RESULTS: {
        return mergeRight(state, { searchResults: [] });
    }
    case C.PROMO_UPDATE_REQUEST:
    case C.PROMO_CREATE_REQUEST: {
        const fetching = { isFetching: true };
        return mergeRight(state, fetching);
    }
    case C.PROMO_UPDATE_SUCCESS: {
        const { promo } = action;
        const success = {
            isFetching: false,
            showSnackBar: true,
            actionResponse: "success",
            actionMessage: "Update successful",
            currentPromo: promo,
        };
        return mergeRight(state, success);
    }
    case C.PROMO_CREATE_SUCCESS: {
        const { promo } = action;
        const success = {
            isFetching: false,
            showSnackBar: true,
            actionResponse: "success",
            actionMessage: "Promo created.",
            currentPromo: promo,
        };
        return mergeRight(state, success);
    }
    case C.HIDE_PROMO_SNACKBAR: {
        return mergeRight(state, { showSnackBar: false });
    }
    case C.PROMO_CREATE_FAILURE:
    case C.PROMO_UPDATE_FAILURE: {
        const { payload: { msg } } = action;
        return mergeRight(state, {
            showSnackBar: true,
            isFetching: false,
            actionResponse: "failure",
            actionMessage: msg,
        });
    }
    default:
        return state;
    }
}
