import sha256 from "sha256";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import { fetchFullMemberInfoNeeded } from "./search";

function requestChangeToAccount() {
    return {
        type: constants.REQUEST_CHANGE_TO_ACCOUNT,
        isFetching: true,
    };
}

function requestSetTaxExemption() {
    return {
        type: constants.REQUEST_SET_TAX_EXEMPTION,
        isFetching: true,
    };
}

function requestAddFunds() {
    return {
        type: constants.REQUEST_ADD_FUNDS,
        isFetching: true,
    };
}

function receiveUserUpdate(response, property, value) {
    const newResponse = response;

    if (response.success === true) {
        newResponse.msg = `Success: ${property} updated to ${value}`;
    } else {
        newResponse.msg = response.error || `Error - Updating ${property}`;
    }
    return {
        type: constants.RECEIVE_USER_UPDATE,
        isFetching: false,
        response: newResponse,
        property,
        value,
    };
}

function noteUpdatedRequest() {
    return {
        type: constants.MEMBER_NOTE_UPDATE_REQUEST,
    };
}

function noteCreatedRequest() {
    return {
        type: constants.MEMBER_NOTE_CREATE_REQUEST,
    };
}

function noteUpdatedSuccess(payload) {
    return {
        type: constants.MEMBER_NOTE_UPDATE_SUCCESS,
        payload,
    };
}

function noteCreatedSuccess(payload) {
    return {
        type: constants.MEMBER_NOTE_CREATE_SUCCESS,
        payload,
    };
}

function noteCreatedFailure(payload) {
    return {
        type: constants.MEMBER_NOTE_CREATE_FAILURE,
        payload,
    };
}

function noteUpdatedFailure(payload) {
    return {
        type: constants.MEMBER_NOTE_UPDATE_FAILURE,
        payload,
    };
}
function passwordResetRequest() {
    return {
        type: constants.MEMBER_RESET_REQUEST,
    };
}

function passwordResetSuccess(payload) {
    return {
        type: constants.MEMBER_RESET_SUCCESS,
        payload,
    };
}

function passwordResetfailed(payload) {
    return {
        type: constants.MEMBER_RESET_FAIL,
        payload,
    };
}

export function noteUpdate(ID, authorID, authorEmail, body) {
    const opts = { ID, authorID, authorEmail, body };
    return (dispatch) => {
        dispatch(noteUpdatedRequest());
        return fetch(constants.MEMBER_NOTE_UPDATE_URL, opts)
            .then((res) => {
                dispatch(noteUpdatedSuccess(res));
            })
            .catch((error) => {
                dispatch(noteUpdatedFailure(error));
            });
    };
}

export function noteCreate(authorID, authorEmail, userID, memberID, body) {
    const opts = { authorID, authorEmail, userID, memberID, body };
    return (dispatch) => {
        dispatch(noteCreatedRequest());
        return fetch(constants.MEMBER_NOTE_CREATE_URL, opts)
            .then((res) => {
                dispatch(noteCreatedSuccess(res));
            })
            .catch((error) => {
                dispatch(noteCreatedFailure(error));
            });
    };
}

export function clearSelectedMember() {
    return (dispatch) =>
        dispatch({
            type: constants.CLEAR_SELECTED_MEMBER,
        });
}

function receiveMemberUpdate(response, property, value, secondaryObject = {}) {
    const msgValue = secondaryObject.title ? secondaryObject.title : value;
    const msgProperty = secondaryObject.property
        ? `${property} ${secondaryObject.property}`
        : property;
    if (response.success === true) {
        response.msg = `Success: ${msgProperty} updated to ${msgValue}`;
    } else {
        response.msg = response.error || `Error - Updating ${msgProperty}`;
    }
    return {
        type: constants.RECEIVE_MEMBER_UPDATE,
        isFetching: false,
        response,
        property,
        value,
    };
}

function receiveExemptionUpdate(response, property, value, exemptionCode) {
    if (response.success === true) {
        response.msg = `Success: tax exemptoin updated to ${exemptionCode}`;
    } else {
        response.msg = response.error || `Error - Updating tax exemption`;
    }
    return {
        type: constants.RECEIVE_MEMBER_UPDATE,
        isFetching: false,
        response,
        property,
        value,
    };
}

function receiveBillingAddFunds(response, property, value) {
    if (response.account_balance) {
        response.msg = `Success: ${property} updated to ${value}`;
        response.success = true;
    } else {
        response.msg = response.error || `Error - Updating ${property}`;
    }
    return {
        type: constants.RECEIVE_ADD_FUNDS_CONF,
        isFetching: false,
        response,
        property: "account_balance",
        value: response.account_balance,
    };
}

function receiveApplyRefundConf(response, property, value) {
    const newMsg = response;

    if (response.success === true) {
        response.msg = `Success: ${property} updated to ${value}`;
        response.success = true;
    } else {
        response.msg = response.error || `Error - Updating ${property}`;
    }
    return {
        type: constants.RECEIVE_APPLY_REFUND_CONF,
        isFetching: false,
        response,
        property: "account_balance",
        value: response.new_balance,
    };
}

function receiveAddMeterConf(response, esiID) {
    const newMsg = response;

    if (response.success === true) {
        response.msg = `Success: New meter ${esiID} added`;
        response.success = true;
    } else {
        response.msg =
            response.error || `Error - Updating adding meter ${esiID}`;
    }

    return {
        type: constants.RECEIVE_ADD_METER_CONF,
        isFetching: false,
        response,
    };
}

function receiveCloseAccountConf(confMsg) {
    const newMsg = confMsg;
    let property;
    let value;

    if (confMsg.success === true) {
        newMsg.msg = "Success - Deactivate Recharge";
        newMsg.success = true;
        property = "active";
        value = false;
    } else {
        newMsg.msg = confMsg.error || "Error - Deactivate Recharge";
    }
    return {
        type: constants.RECEIVE_CLOSE_ACCOUNT_CONF,
        isFetching: false,
        response: newMsg,
        property,
        value,
    };
}

function receiveActivateAccount(data) {
    const newMsg = data;
    let property;
    let value;
    if (data.success === true) {
        newMsg.msg = "Success - Activate Recharge";
        newMsg.success = true;
        property = "active";
        value = true;
    } else {
        newMsg.msg = data.error || "Error - Activate Recharge";
    }
    return {
        type: constants.RECEIVE_BILLING_ACTIVATE,
        isFetching: false,
        response: newMsg,
        property,
        value,
    };
}

export function addAdditionalMeter(
    esiID,
    requestedStartDate,
    token,
    enrollType,
    member
) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());

        return fetch(constants.METER_LOOKUP, { esiID })
            .then((meterLookupResponse) => {
                let address = {
                    street_address:
                        meterLookupResponse.address.line1 +
                        " " +
                        meterLookupResponse.address.line2,
                    city: meterLookupResponse.city,
                    state: meterLookupResponse.state,
                    country: meterLookupResponse.country,
                    postal_code: meterLookupResponse.postal_code,
                };

                let formattedBirthday = member.get("birthday").split("T")[0];

                if (formattedBirthday.includes("/")) {
                    let birthday = formattedBirthday.split("/");
                    let year = birthday[2];
                    let month =
                        parseInt(birthday[0], 10) < 10
                            ? "0" + birthday[0]
                            : birthday[0];
                    let day =
                        parseInt(birthday[1], 10) < 10
                            ? "0" + birthday[1]
                            : birthday[1];
                    formattedBirthday = year + "-" + month + "-" + day;
                }

                return fetch(constants.MEMBER_CREATE, {
                    userID: member.get("userID"),
                    member_agreement: true,
                    first_name: member.get("first_name"),
                    last_name: member.get("last_name"),
                    email: member.get("email"),
                    address,
                    recharge_dollars: "49",
                    birthday: formattedBirthday,
                });
            })
            .then((memberCreateResponse) => {
                return fetch(constants.PAYMENT_ENROLL, {
                    memberID: memberCreateResponse.memberID,
                    emailAddress: member.get("email"),
                });
            })
            .then((paymentEnrollResponse) => {
                return fetch(constants.PAYMENT_ADD_CARD, {
                    memberID: paymentEnrollResponse.result.memberID,
                    stripeToken: token.id,
                    is_default: true,
                });
            })
            .then((paymentAddCardResponse) => {
                return fetch(constants.BILLING_ADDFUNDS, {
                    hashID: sha256(JSON.stringify(paymentAddCardResponse)),
                    new_payment_amount: "49",
                    initial_payment: true,
                    memberID: paymentAddCardResponse.result.memberID,
                });
            })
            .then((billingAddFundsResponse) => {
                let params = {
                    meterID: esiID,
                    enrollMode: enrollType,
                    memberID: billingAddFundsResponse.memberID,
                };
                if (requestedStartDate !== "") {
                    params.requestedStartDate = requestedStartDate;
                }
                return fetch(constants.ADD_METER, params);
            })
            .then((addMeterResponse) => {
                dispatch(receiveAddMeterConf(addMeterResponse, esiID));
            })
            .catch((error) => {
                dispatch(receiveAddMeterConf(error));
            });
    };
}

export function userUpdate(userId, property, value) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());
        return fetch(constants.USER_UPDATE, {
            userID: userId,
            [property]: value,
        })
            .then((response) =>
                dispatch(receiveUserUpdate(response, property, value))
            )
            .catch((response) => dispatch(receiveUserUpdate(response)));
    };
}

export function memberUpdate(memberId, property, value, secondaryObject) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());
        return fetch(constants.MEMBER_UPDATE, {
            memberID: memberId,
            [property]: value,
        })
            .then((msg) =>
                dispatch(
                    receiveMemberUpdate(msg, property, value, secondaryObject)
                )
            )
            .catch((msg) => dispatch(receiveMemberUpdate(msg)));
    };
}

export function meterUpdate(meterID, property, value) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());
        return fetch(constants.METER_UPDATE, { ID: meterID, [property]: value })
            .then((msg) => dispatch(receiveMemberUpdate(msg, property, value)))
            .catch((msg) => dispatch(receiveMemberUpdate(msg)));
    };
}

export function changeExemptionCode(meters, meterID, exemptionCode) {
    // build new meter list
    for (let i = 0; i < meters.size; i++) {
        if (meters.get(i).get("ID") === meterID) {
            let metadata = meters
                .get(i)
                .get("meta_data")
                .set("tax_exemption_code", exemptionCode);
            meters = meters.set(i, meters.get(i).set("meta_data", metadata));
        }
    }
    return (dispatch) => {
        dispatch(requestSetTaxExemption());
        return fetch(constants.METER_SET_TAX_EXEMPTION, {
            meterID: meterID,
            exemption_code: exemptionCode,
        })
            .then((msg) =>
                dispatch(
                    receiveExemptionUpdate(msg, "meters", meters, exemptionCode)
                )
            )
            .catch((msg) =>
                dispatch(
                    receiveExemptionUpdate(msg, "meters", meters, exemptionCode)
                )
            );
    };
}

export function applyAddFunds(memberId, property, value) {
    return (dispatch) => {
        dispatch(requestAddFunds());
        const payload = {
            start: new Date().toISOString(),
            memberID: memberId,
            [property]: value,
            initial_payment: false,
        };
        payload.hashID = sha256(JSON.stringify(payload));

        return fetch(constants.BILLING_ADDFUNDS, payload)
            .then((response) => {
                dispatch(receiveBillingAddFunds(response, property, value));
            })
            .catch((response) => {
                dispatch(receiveBillingAddFunds(response));
            });
    };
}

export function applyRefund(memberId, property, value) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());
        return fetch(constants.BILLING_ACCOUNT, { memberID: memberId }).then(
            (account) => {
                if (Number.parseFloat(account.account_balance) >= value) {
                    return fetch(constants.BILLING_REFUND, {
                        memberID: memberId,
                        [property]: value,
                    })
                        .then((response) =>
                            dispatch(
                                receiveApplyRefundConf(
                                    response,
                                    property,
                                    value
                                )
                            )
                        )
                        .catch((response) =>
                            dispatch(receiveApplyRefundConf(response))
                        );
                }
                return dispatch(receiveApplyRefundConf(null, "refund", false));
            }
        );
    };
}

export function closeAccount(memberId) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());
        return fetch(constants.BILLING_ACCOUNT, { memberID: memberId })
            .then(() => {
                return fetch(constants.BILLING_DEACTIVATE, {
                    memberID: memberId,
                }).then((msg) => dispatch(receiveCloseAccountConf(msg)));
            })
            .catch((response) => dispatch(receiveCloseAccountConf(response)));
    };
}

export function refundAndCloseAccount(memberId) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());
        return fetch(constants.BILLING_ACCOUNT, { memberID: memberId })
            .then(() => {
                return fetch(constants.BILLING_REFUND, {
                    memberID: memberId,
                    deactivate_account: true,
                }).then((msg) => dispatch(receiveCloseAccountConf(msg)));
            })
            .catch((err) => {
                dispatch(receiveCloseAccountConf(err));
            });
    };
}

export function activateAccount(memberId) {
    return (dispatch) => {
        dispatch(requestChangeToAccount());
        return fetch(constants.BILLING_ACTIVATE, { memberID: memberId })
            .then((data) => {
                dispatch(receiveActivateAccount(data));
            })
            .catch((err) => {
                dispatch(receiveActivateAccount(err));
            });
    };
}

export function clearConfMsg() {
    return {
        type: constants.CLEAR_MEMBER_CONF_MSG,
    };
}

export function hideSnackBar() {
    return {
        type: constants.HIDE_SNACKBAR,
    };
}

export const resetPassword = (email) => (dispatch) => {
    const fields = { email };
    dispatch(passwordResetRequest());
    return fetch(constants.SIGNUP_RESET_PASSWORD_URL, fields)
        .then((res) => {
            if (res.success) {
                return dispatch(passwordResetSuccess(res));
            }
            dispatch(passwordResetfailed(res));
        })
        .catch((err) => {
            dispatch(passwordResetfailed(err));
        });
};

export const activateUser = (userID) => (dispatch) => {
    return fetch(constants.USER_ACTIVATE, { userID })
        .then((response) =>
            dispatch(receiveUserUpdate(response, "userIsActive", true))
        )
        .catch((response) => dispatch(receiveUserUpdate(response)));
};

export const deActivateUser = (userID) => (dispatch) => {
    return fetch(constants.USER_DEACTIVATE, { userID })
        .then((response) =>
            dispatch(receiveUserUpdate(response, "userIsActive", false))
        )
        .catch((response) => dispatch(receiveUserUpdate(response)));
};
