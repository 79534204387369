import * as C from "../../constants";

const initialState = {
    initialized: false,
    isLoading: false,
    accountFlagTypes: [],
    accounts: {},
    successMessage: "",
    errorMessage: "",
    showSnackBar: false,
    expandedViews: [],
};

const loadAccountFlagTypes = (state, action) => {
    return {
        ...state,
        accountFlagTypes: action.accountFlagTypes,
    };
};

const toggleIsLoading = (state) => ({
    ...state,
    isLoading: !state.isLoading,
});

const loadAccountFlags = (state, action) => {
    return {
        ...state,
        accounts: {
            ...state.accounts,
            [action.selectedAccountID]: action.accountFlags,
        },
    };
};

const addAccountFlag = (state, action) => {
    return {
        ...state,
        accounts: {
            ...state.accounts,
            [action.selectedAccountID]: [
                ...state.accounts[action.selectedAccountID],
                action.accountFlag,
            ],
        },
    };
};

const deleteAccountFlag = (state, action) => {
    const index = state.accounts[action.selectedAccountID].findIndex(
        (f) => f.account_flag_id === action.accountFlag.account_flag_id
    );

    return {
        ...state,
        accounts: {
            ...state.accounts,
            [action.selectedAccountID]: [
                ...state.accounts[action.selectedAccountID].slice(0, index),
                ...state.accounts[action.selectedAccountID].slice(index + 1),
            ],
        },
    };
};

const setSuccess = (state, action) => {
    return {
        ...state,
        showSnackBar: true,
        successMessage: action.message,
    };
};

const setError = (state, action) => {
    return {
        ...state,
        showSnackBar: true,
        successMessage: action.message,
    };
};

const dismissMessage = (state) => {
    return {
        ...state,
        showSnackBar: false,
        successMessage: "",
        errorMessage: "",
    };
};

const toggleCategoryView = (state, action) => {
    const index = state.expandedViews.indexOf(action.category);
    let expandedViews;
    if (index === -1) {
        expandedViews = [action.category];
    } else {
        expandedViews = [];
    }
    return {
        ...state,
        expandedViews,
    };
};

const toggleAccountFlag = (state, action) => {
    const accountFlags = state.accounts[action.selectedAccountID] || [];
    const accountFlag = accountFlags.find((a) => a.flag_type === action.flagId);
    if (!accountFlag) {
        accountFlags.push({ flag_type: action.flagId, action: "add" });
    } else {
        accountFlag.action = accountFlag.action !== "remove" ? "remove" : "add";
    }
    return {
        ...state,
        accounts: {
            ...state.accounts,
            [action.selectedAccountID]: accountFlags,
        },
    };
};

export const cancelChanges = (state, action) => {
    const accountFlags = (state.accounts[action.selectedAccountID] || [])
        .filter((af) => af.account_flag_id)
        .map((a) => ({ ...a, action: null }));
    return {
        ...state,
        accounts: {
            ...state.accounts,
            [action.selectedAccountID]: accountFlags,
        },
    };
};
export default function accountFlags(state = initialState, action) {
    switch (action.type) {
        case C.ACCOUNT_FLAG_TYPES_LOAD:
            return loadAccountFlagTypes(state, action);
        case C.ACCOUNT_FLAG_TOGGLE_LOADING:
            return toggleIsLoading(state);
        case C.ACCOUNT_FLAGS_LOAD:
            return loadAccountFlags(state, action);
        case C.ACCOUNT_FLAGS_ADD:
            return addAccountFlag(state, action);
        case C.ACCOUNT_FLAGS_DELETE:
            return deleteAccountFlag(state, action);
        case C.ACCOUNT_FLAG_SET_SUCCESS:
            return setSuccess(state, action);
        case C.ACCOUNT_FLAG_SET_ERROR:
            return setError(state, action);
        case C.ACCOUNT_FLAG_DISMISS_MESSAGE:
            return dismissMessage(state);
        case C.ACCOUNT_FLAG_TOGGLE_CATEGORY_VIEW:
            return toggleCategoryView(state, action);
        case C.ACCOUNT_FLAG_TOGGLE:
            return toggleAccountFlag(state, action);
        case C.ACCOUNT_FLAG_CANCEL_CHANGES:
            return cancelChanges(state, action);
        default:
            return state;
    }
}
