import React from "react";
import moment from "moment";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const UsageDetailRows = (props) => {
  const {
    date,
    money,
    usageSummaries,
    index,
    toggle,
    transaction_date,
  } = props;

  return [
    usageSummaries[date] && (
      <TableRow
        key={`${index}-${transaction_date}`}
        style={{
          display: toggle ? null : "none",
          backgroundColor: "#fafafa",
        }}
      >
        <TableCell
          style={{ fontWeight: "bold", padding: "5px 25px" }}
          align="right"
          colSpan={5}
        >
          Transaction Time
        </TableCell>
        <TableCell
          style={{ fontWeight: "bold", padding: "5px 0px" }}
          align="center"
          colSpan={2}
        >
          Charge Type
        </TableCell>
        <TableCell
          style={{ fontWeight: "bold", padding: "5px 0px" }}
          align="center"
          colSpan={1}
        >
          Operating Date
        </TableCell>
        <TableCell
          style={{ fontWeight: "bold", padding: "5px 0px" }}
          align="center"
        >
          Amount
        </TableCell>
        <TableCell
          style={{ fontWeight: "bold", padding: "5px 0px" }}
          align="center"
        >
          Balance
        </TableCell>
      </TableRow>
    ),
    usageSummaries[date]?.map((usage, i) => {
      return (
        <TableRow
          key={`${i}-${transaction_date}-${index}`}
          style={{
            display: toggle ? null : "none",
            backgroundColor: "#fafafa",
          }}
        >
          <TableCell align="right" colSpan={5}>
            {moment(usage.transaction_date).format("YYYY-MM-DD hh:mm:ss")}
          </TableCell>
          <TableCell align="center" colSpan={2}>
            {usage.charge_type}
          </TableCell>
          <TableCell align="center" colSpan={1}>
            {moment(usage.operating_date).format("YYYY-MM-DD")}
          </TableCell>
          <TableCell align="center">{money(usage.amount)}</TableCell>
          <TableCell align="center">{money(usage.ending_balance)}</TableCell>
        </TableRow>
      );
    }),
  ];
};

export default UsageDetailRows;
