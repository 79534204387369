import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Iterable from "../components/Iterable";
import selectIterableDataContainer from "../selectors/iterable";
import {selectedMemberContainer} from "../selectors/selectedMember";
import * as actions from "../actions/iterable";

const IterableContainer = (props) => <Iterable {...props} />;

const mapStateToProps = (state) => ({
  member: selectedMemberContainer(state)(state),
  ...selectIterableDataContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(IterableContainer);
