import * as C from "../../constants";

const initialState = {
    userID: "",
    memberID: "",
    email: "",
    esi: "",
    firstName: "",
    lastName: "",
    phone: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    birthday: "",
    agreed: true,
    streetAddress2: "",
    addressSearchResult: [],
    errorMessage: "",
    successMessage: "",
    showSnackBar: false,

};

const loadServiceAddresses = (state, action) => {    
    return {
        ...state,
        serviceAddresses: action.metersResult,
    };
} 

const loadMember = (state, action) => {        
    return {
        ...state,
        ...action.member,
        ...action.member.address,
    };
};

const loadUser = (state, action) => {           
    return {
        ...state,
        ...action.user,
        isNewUser: Boolean(!action.user || (action.user.isNewUser))
    };
};

const updateForm = (state, action) => {    
    return {
        ...state,
        ...action.updatedInfo,
    };
};

const setSelectedAddress = (state, action) => {    
    const selectedAddress = action.selectedIndex && state.serviceAddresses[action.selectedIndex] || {}; 
    return {
        ...state,
        ...selectedAddress.address,
        esi: selectedAddress.esi,
        
        serviceAddresses:[]
    }
}

const closeSnackbar = (state) =>{
    return {
        ...state,
        errorMessage: "",
        successMessage: "",
        showSnackBar: false,
    }
}

const setErrorMessage = (state, action) =>{
    return {
        ...state,
        errorMessage: action.message,
        showSnackBar: true,
    }
}

const setSuccessMessage = (state, action) =>{
    return {
        ...state,
        successMessage: action.message,
        showSnackBar: true,
    }
}

const clearForm = () => ({ ...initialState })

export default function signup(state = initialState, action) {
    switch (action.type) {
        case C.SIGNUP_UPDATE_FORM:
            return updateForm(state, action);
        case C.SIGNUP_LOAD_USER:
            return loadUser(state, action);
        case C.SIGNUP_LOAD_MEMBER:
            return loadMember(state, action);
        case C.SIGNUP_METER_ADDRESS_SEACH_SUCCESS:
            return loadServiceAddresses(state, action);
        case C.SIGNUP_SET_SELECTED_ADDRESS:
            return setSelectedAddress(state, action);
        case C.SIGNUP_CREATE_MEMBER_SUCCESS:
            return loadMember(state, action)
        case C.SIGNUP_CLEAR_FORM:
            return clearForm();
        case C.SIGNUP_CLOSE_SNACKBAR:
            return closeSnackbar(state);
        case C.SIGNUP_SET_ERROR_MESSAGE:
            return setErrorMessage(state, action);
        case C.SIGNUP_SET_SUCCESS_MESSAGE:
            return setSuccessMessage(state, action);
        default:
            return state;
    }
}