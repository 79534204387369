import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { subNavigation } from "../styles/navigation.css";

class DnpRnp extends Component {
    render() {
        const { children } = this.props;
        return (
            <div>
                <header className={subNavigation}>
                    <Link
                        to={`/dnprnp/dnpqueue`}
                        activeStyle={{ textDecoration: "none", color: "black" }}
                    >
                        DNP Queue
                    </Link>{" "}
                    <Link
                        to={`/dnprnp/dnprnptransactions`}
                        activeStyle={{ textDecoration: "none", color: "black" }}
                    >
                        DNP/RNP Records
                    </Link>{" "}
                </header>
                {children}
            </div>
        );
    }
}

DnpRnp.propTypes = {
    children: PropTypes.object,
};

DnpRnp.defaultProps = {
    children: {},
};

export default DnpRnp;
