import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import ReactTable from "react-table";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MeterTransactionDetail from "./MeterTransactionDetail";
import MeterTransactionCreate from "./MeterTransactionCreate";
import {
    confirmationError,
    confirmationSuccess,
} from "../../styles/common.css";

const useStyles = makeStyles((theme) => ({
    icons: {
        float: "right",
        "& > *": {
            margin: theme.spacing(1),
        },
        height: 25,
    },
    iconButtonLabel: {
        display: "flex",
        flexDirection: "column",
    },
    assignMeterDialog: {
        minHeight: "400px",
    },
    searchMeterResult: {
        marginLeft: 15,
    },
    createTransaction: {
        "&  *": {
            marginRight: "20px",
            width: "200px",
        },
    },
    input175: {
        marginRight: "20px",
        width: "100%",
    },
    input200: {
        marginRight: "20px",
        width: "175px",
    },
    transactionDetailRoot: {
        width: "100%",
        height: 420,

        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            width: "100%",
            margin: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },
    transactionDetailHeader: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(4),
        display: "inline-block",
        fontWeight: theme.typography.fontWeightRegular,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        display: "inline-block",
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginLeft: 10,
    },
    divider: {
        margin: "20px 0",
    },
    editTransactionView: {
        height: 550,
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "100%",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    label: {
        fontSize: ".75rem",
        fontWeight: 400,
        lineHeight: 1,
    },
    formControl: {
        width: "100%",
        paddingLeft: 10,
    },
    dialog: {
        position: "absolute",

        top: 0,
    },
}));

const MeterTransactions = ({
    transactions,
    showSnackBar,
    successMessage,
    errorMessage,
    selectedIndex,
    selectedTransaction,
    transactionIsModified,
    params,
    total,
    has_next,
    allTransactionTypes,
    fetchMeterTransactions,
    fetchAllMeterTransactions,
    updateTransaction,
    closeSnackbar,
    setSelectedTransaction,
    processTransaction,
    postUpdateTransaction,
    updateState,
    openAssignMeter,
    openConfirmTransactionProcessing,
    searchMeters,
    searchESI,
    searchMeterResult,
    isProcessing,
    openCreateTransaction,
    newTransactionData,
    updateNewTransaction,
    updateNewTransactionDetail,
    createNewTransaction,
    sendTransaction,
    validateTransactionField,
}) => {
    const [memberId] = useState(params.memberId);

    const fetchData = (memberId, page) => {
        if (memberId) {
            fetchMeterTransactions(memberId, page);
        } else {
            fetchAllMeterTransactions(page);
        }
    };

    const getDate = (value) => {
        if (!value) {
            return "";
        }
        const d = value.split("T")[0];
        return moment(d).format("YYYY-MM-DD");
    };

    const handleChange = (event) => {
        const { target } = event;
        const { name, value } = target;

        updateTransaction({
            [`${name}`]: value,
            [`${name}IsSet`]: true,
        });
    };

    const handleNewTransactionChange = (event) => {
        const { target } = event;
        const { name, value } = target;

        updateNewTransaction({
            [`${name}`]: value,
            [`${name}IsSet`]: true,
        });
    };

    const handleNewTransactionDetailChange = (record, index, updatedInfo) => {
        updateNewTransactionDetail({
            record,
            index,
            updatedInfo,
        });
    };

    const handleValidateTransactionField = (
        recordKey,
        record,
        index,
        fieldName,
        fieldValue
    ) => {
        validateTransactionField(
            recordKey,
            record,
            index,
            fieldName,
            fieldValue
        );
    };

    const handleEditorChange = (event) => {
        updateTransaction({
            transactionDataEdited: event.plainText,
            transactionDataIsSet: true,
        });
    };

    const handelConfirmProcessTransaction = () => {
        updateState({
            openConfirmTransactionProcessing: false,
        });
        processTransaction(selectedTransaction.id);
    };

    const assignMeterToTransaction = async (meterID, memberID) => {
        updateTransaction({
            meterID,
            memberID,
            meterIDIsSet: true,
            memberIDIsSet: true,
        });
        await postUpdateTransaction();
        updateState({
            searchESI: "",
            searchMeterResult: [],
            openAssignMeter: false,
            openConfirmTransactionProcessing: true,
        });
    };

    useEffect(() => {
        const START_PAGE = 1;
        fetchData(memberId, START_PAGE);
    }, [memberId]);

    const wildcardFilter = (filter, row) =>
        row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;

    const columns = [
        {
            Header: "Date",
            accessor: "transactionDate",
            Cell: (row) => getDate(row.value),
        },
        {
            Header: "Member ID",
            accessor: "memberID",
            show: !Boolean(memberId),
            Cell: (row) =>
                row?.value === "" ? (
                    "Unassigned"
                ) : (
                    <Link to={`/members/${row.value}/details`}>
                        {row.value}
                    </Link>
                ),
        },
        {
            Header: "Utility No",
            accessor: "utilityAccountNumber",
        },
        {
            Header: "Direction",
            accessor: "direction",
            show: Boolean(memberId),
            Cell: (row) => (row.value === "I" ? "Inbound" : "Outbound"),
        },
        {
            Header: "Type",
            accessor: "transactionTypeName",
            filterMethod: wildcardFilter,
        },
        {
            Header: "Status",
            accessor: "transactionSubType",
            filterMethod: wildcardFilter,
        },
        {
            Header: "Reason",
            accessor: "reason",
            filterMethod: wildcardFilter,
        },
        {
            Header: "Sender Transaction ID",
            accessor: "senderTransactionID",
        },
        {
            Header: "Originating Transaction ID",
            accessor: "originatingTransactionID",
        },
        {
            Header: "Processed",
            accessor: "processingStatus",
            filterMethod: wildcardFilter,
        },
        {
            Header: "",
            accessor: "id",
            Cell: (row) => (
                <Button
                    onClick={() => setSelectedTransaction(row.value)}
                    variant="contained"
                    color="secondary"
                >
                    View Data
                </Button>
            ),
        },
    ];
    const transactionDataJson = JSON.parse(
        (selectedTransaction && selectedTransaction.transactionData) || "{}"
    );

    const classes = useStyles();
    return (
        <div>
            <Grid container>
                <Grid item md={6}>
                    <h4>
                        {!!memberId
                            ? "Meter Transactions"
                            : "Unprocessed Transactions"}{" "}
                    </h4>
                </Grid>
                {false && !!memberId && (
                    <Grid item md={6} style={{ alignSelf: "flex-end" }}>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <IconButton
                                aria-label="Create Transaction"
                                size="medium"
                                title="Create Transaction"
                                classes={{ label: classes.iconButtonLabel }}
                                color="primary"
                                onClick={() =>
                                    updateState({
                                        openCreateTransaction: true,
                                    })
                                }
                            >
                                <AddCircleOutlineIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </Grid>
                )}
            </Grid>

            <ReactTable
                data={transactions}
                columns={columns}
                className="-striped -highlight"
                defaultPageSize={10}
                defaultSortOrder="Request Date"
                filterable
            />
            {selectedTransaction && selectedTransaction.id > 0 && (
                <MeterTransactionDetail
                    classes={classes}
                    selectedTran={selectedTransaction}
                    selectedIndex={selectedIndex}
                    transactionData={transactionDataJson}
                    handleChange={handleChange}
                    handleEditorChange={handleEditorChange}
                    setSelectedTransaction={setSelectedTransaction}
                    transactionIsModified={transactionIsModified}
                    processTransaction={processTransaction}
                    searchMeters={searchMeters}
                    searchESI={searchESI}
                    searchMeterResult={searchMeterResult}
                    openAssignMeter={openAssignMeter}
                    updateState={updateState}
                    postUpdateTransaction={postUpdateTransaction}
                    isProcessing={isProcessing}
                    assignMeterToTransaction={assignMeterToTransaction}
                    openConfirmTransactionProcessing={
                        openConfirmTransactionProcessing
                    }
                    handelConfirmProcessTransaction={
                        handelConfirmProcessTransaction
                    }
                />
            )}

            {openCreateTransaction && (
                <MeterTransactionCreate
                    classes={classes}
                    openCreateTransaction={openCreateTransaction}
                    updateState={updateState}
                    updateNewTransaction={updateNewTransaction}
                    updateNewTransactionDetail={
                        handleNewTransactionDetailChange
                    }
                    isProcessing={isProcessing}
                    newTransactionData={newTransactionData}
                    handleChange={handleNewTransactionChange}
                    createNewTransaction={createNewTransaction}
                    sendTransaction={sendTransaction}
                    validateTransactionField={handleValidateTransactionField}
                    allTransactionTypes={allTransactionTypes}
                />
            )}

            <Snackbar
                id="message-id"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={showSnackBar}
                autoHideDuration={3000}
                onClose={closeSnackbar}
                className={
                    successMessage ? confirmationSuccess : confirmationError
                }
                message={successMessage || errorMessage}
            />
            {has_next && (
                <Button
                    onClick={() => {
                        fetchData(memberId);
                    }}
                    color="primary"
                    variant="contained"
                    style={{
                        marginRight: 20,
                        marginTop: 10,
                    }}
                >
                    Load More
                </Button>
            )}

            <div style={{ float: "right", marginTop: "10px" }}>
                Downloaded {transactions.length} of {total} transactions
            </div>
            <Backdrop className={classes.backdrop} open={isProcessing}>
                <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
            </Backdrop>
        </div>
    );
};

MeterTransactions.propTypes = {
    closeSnackbar: PropTypes.any,
    errorMessage: PropTypes.any,
    fetchAllMeterTransactions: PropTypes.func,
    fetchMeterTransactions: PropTypes.func,
    params: PropTypes.shape({
        memberId: PropTypes.any,
    }),
    processTransaction: PropTypes.func,
    selectedIndex: PropTypes.any,
    setSelectedTransaction: PropTypes.func,
    showSnackBar: PropTypes.any,
    successMessage: PropTypes.any,
    transactionIsModified: PropTypes.any,
    transactions: PropTypes.array,
    updateTransaction: PropTypes.func,
};

export default MeterTransactions;
