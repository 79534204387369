import { createSelector } from "reselect";

const promos = state => state.promos;

export const selectCurrentPromo = createSelector(
    promos,
    promos => promos.currentPromo
);

export const selectPromoSearchResults = createSelector(
    promos,
    promos => promos.searchResults
);

export const selectPromoFetchingStatus = createSelector(
    promos,
    promo => promo.isFetching
);

export const selectPromoActionResponse = createSelector(
    promos,
    promo => promo.actionResponse
);

export const selectPromoActionMessage = createSelector(
    promos,
    promo => promo.actionMessage
);

export const selectPromoSnackbarStatus = createSelector(
    promos,
    promo => promo.showSnackBar
);
