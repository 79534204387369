import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  noteContainer: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  overflowFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: "10px",
    position: "relative",
    top: "-100px",
    marginBottom: "-100px",
    "& #overflowObject": {
      height: "80px",
      width: "100%",
      backgroundImage: "linear-gradient(180deg, transparent, white)",
    },
    "& #overflowOptions": {
      background: "white",
      display: "flex",
      justifyContent: "flex-end",
      padding: "5px 0px",
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        color: "black",
        transition: "all 0.3s ease",
        "&:hover": {
          color: "grey",
        },
      },
    },
  },
  content: {
    fontSize: "15px",
    // overflowY: "auto",
    maxHeight: "250px",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
});

const NoteCard = (props) => {
  const { noteInfo } = props;
  const createdDate = noteInfo.createdDate;
  const author = noteInfo.author;
  const content = noteInfo.content;

  const textInput = useRef();

  const [toggleFooter, setToggleFooter] = useState(false);
  const [toggleExpand, setToggleExpand] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const noteHeight = 270;
    const scrollHeight = textInput.current.scrollHeight;
    if (scrollHeight > noteHeight) {
      setToggleFooter(true);
    } else {
      setToggleFooter(false);
    }
  }, [textInput]);

  const handleOpen = () => {
    setToggleExpand(true);
  };

  const handleClose = () => {
    setToggleExpand(false);
  };
  return (
    <Card className={classes.root} variant="outlined">
      <div className={classes.noteContainer}>
        <div ref={textInput} className={classes.content}>
          {content}
        </div>
        {toggleFooter && (
          <div className={classes.overflowFooter}>
            <div id="overflowObject"></div>
            <div id="overflowOptions">
              <AspectRatioIcon onClick={() => handleOpen()} />
            </div>
          </div>
        )}
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={toggleExpand}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.header}>
            <span>{createdDate}</span>
            <span>{author}</span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.noteContainer}>
            <div className={classes.contentFullView}>{content}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default NoteCard;
