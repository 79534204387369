const modalStyle = {
    modal: {
        content: {
            marginLeft: "25%",
            marginRight: "25%",
            marginTop: "10%",
            marginBottom: "10%",
            padding: "5%",
            textAlign: "center",
            height: "10%",
            width: "30%",
            fontSize: "18px",
        },
    },
    positiveButton: {
        margin: "10px", fontSize: "18px", color: "white", background: "rgb(255,64,129)",
    },
    negativeButton: {
        margin: "10px", fontSize: "18px", color: "white", background: "grey",
    },
    loadingModal: {
        content: {
            marginLeft: "25%",
            marginRight: "25%",
            marginTop: "10%",
            marginBottom: "10%",
            padding: "5%",
            textAlign: "center",
            height: "20%",
            width: "30%",
            fontSize: "18px",
            background: "rgb(255,255,255,0.10)",
            border: "0px solid white",
        },
    },
    serviceAddressListModal: {
        content: {
            marginLeft: "25%",
            marginRight: "25%",
            marginTop: "5%",            
            padding: "5%",
            textAlign: "center",
            height: "60%",
            width: "30%",
            fontSize: "18px",
            overflow: "hidden",
        },
    },
};

export default modalStyle;
