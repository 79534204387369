import * as constants from "../../constants";
import selectDnpContainer from "../selectors/dnp";
import fetch from "../util/api-ajax";
import moment from "moment";

const createErrorMessage = (message) => ({
    type: constants.DNP_SET_ERROR_MESSAGE,
    message,
});

const createSuccessMessage = (message) => ({
    type: constants.DNP_SET_SUCCESS_MESSAGE,
    message,
});

const getSelectedMeters = (acc, cur) => {
    if (cur.selected) {
        acc.push(cur.ID);
    }
    return acc;
};

const toggleIsLoading = () => ({
    type: constants.DNP_TOGGLE_LOADING,
});

export const setErrorMessage = (message) => (dispatch) => {
    dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
    dispatch(createSuccessMessage(message));
};

const sendDnpRequests = (dispatch, meter_recordID) => {
    dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
    return fetch(constants.DNP_DISCONNECT_URL, { meter_recordID })
        .then(() => {
            dispatch({ type: constants.DNP_TOGGLE_SUCCESS, meter_recordID });
        })
        .catch((err) => {
            dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
            dispatch({
                type: constants.DNP_SET_METER_ERROR,
                meter_recordID,
                message: err.message,
            });
        });
};

export const clearAllErrors = () => (dispatch) => {
    dispatch({
        type: constants.DNP_CLEAR_ALL_ERRORS,
    });
};

export const doSendDnpRequests = () => (dispatch, getState) => {
    const { accountsByMeters } = selectDnpContainer()(getState());
    const selectedMeters = accountsByMeters.reduce(getSelectedMeters, []);

    clearAllErrors()(dispatch);
    // want to limit request to max of 10
    selectedMeters.slice(0, 10).map((m) => sendDnpRequests(dispatch, m));
};

const sendSnoozeRequests = (dispatch, meter_recordID, accountID) => {
    dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
    const snoozeFlagId = "SNOOZE";
    return fetch(
        `${constants.ACCOUNT_FLAGS_MAIN_URL}/${accountID}/accountflags/create`,
        { account_flag_type_id: snoozeFlagId }
    )
        .then(() => {
            dispatch({ type: constants.DNP_TOGGLE_SUCCESS, meter_recordID });
        })
        .catch((err) => {
            dispatch({ type: constants.DNP_TOGGLE_INPROGRESS, meter_recordID });
            dispatch({
                type: constants.DNP_SET_METER_ERROR,
                meter_recordID,
                message: err.message,
            });
        });
};

export const doSendSnoozeRequests = () => (dispatch, getState) => {
    const { accountsByMeters } = selectDnpContainer()(getState());
    const selectedMeters = accountsByMeters.reduce(getSelectedMeters, []);

    clearAllErrors()(dispatch);
    // want to limit request to max of 10
    selectedMeters.slice(0, 10).map((m) => {
        // get the account associated wit the meter
        const account = accountsByMeters.find(
            (a) => a.meters.findIndex((am) => am.ID === m) !== -1
        );

        if (account) {
            sendSnoozeRequests(dispatch, m, account.accountID);
        }
    });
};

export const doFetchDnpCandidates = () => (dispatch) => {
    const fields = {
        limit: 3000,
        is_active: "true",
        balance_lt: 10,
        return_last_successful_payment: "true",
        has_meter_status: ["connected"],
        return_filtered_meters: "true",
        exclude_if_pending_dnp: "true",
        exclude_account_category_flag: "DNP_FLAGS",
    };
    dispatch(toggleIsLoading());
    dispatch({
        type: constants.DNP_LOAD_CANDIDATES,
        accounts: [],
    });
    return fetch(constants.DNP_FETCH_CANDIDATES_URL, fields)
        .then((candidates) => {
            dispatch({
                type: constants.DNP_LOAD_CANDIDATES,
                ...candidates,
            });
            dispatch(toggleIsLoading());
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
            dispatch(toggleIsLoading());
        });
};

export const updateCandidateState = (index, updatedInfo) => (dispatch) => {
    dispatch({
        type: constants.DNP_UPDATE_CANDIDATE,
        updatedInfo,
        index,
    });
};

export const toggleCandidateSelect = (accountID, meterId) => (dispatch) => {
    dispatch({
        type: constants.DNP_TOGGLE_CANDIDATE_SELECT,
        accountID,
        meterId,
    });
};

export const toggleCandidatesSelectAll = () => (dispatch) => {
    dispatch({
        type: constants.DNP_TOGGLE_CANDIDATES_SELECT_ALL,
    });
};
