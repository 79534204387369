import React from "react";
import ReactTable from "react-table";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";

const AssignMeterTransaction = ({
    open,
    searchESI,
    handleChange,
    handleAssignMeter,
    searchMeters,
    searchMeterResult,
    classes,
    isProcessing,
}) => {
    const columns = [
        {
            Header: "ESI ID",
            accessor: "meterID",
        },
        {
            Header: "Member ID",
            accessor: "memberID",
            width: 300,
        },
        {
            Header: "Start Date",
            accessor: "requested_start_date",
            width: 100,
        },
        {
            Header: "Status",
            accessor: "status.status",
        },
        {
            Header: "",
            accessor: "ID",
            width: 100,
            Cell: (row) => (
                <Button
                    onClick={() =>
                        handleAssignMeter(row.value, row.original.memberID)
                    }
                    variant="contained"
                    color="secondary"
                >
                    Assign
                </Button>
            ),
        },
    ];
    return (
        <Dialog
            open={open}
            contentlabel="Transaction Raw Data"
            ariaHideApp={false}
            fullWidth={true}
            maxWidth={"md"}
            classes={{ paperFullWidth: classes.assignMeterDialog }}
        >
            <DialogTitle>Meter Assignment</DialogTitle>
            <DialogContent>
                <Backdrop className={classes.backdrop} open={isProcessing}>
                    <ClipLoader
                        sizeUnit="px"
                        size={100}
                        color="#ED0874"
                        loading
                    />
                </Backdrop>
                <DialogContentText id="alert-dialog-description">
                    <Grid container>
                        <ListItem>
                            <TextField
                                name="searchESI"
                                style={{
                                    marginRight: "20px",
                                    width: "200px",
                                }}
                                label="ESI"
                                value={searchESI}
                                type="string"
                                onChange={(e) =>
                                    handleChange({ searchESI: e.target.value })
                                }
                            />
                            <IconButton
                                aria-label="Link Meter"
                                size="large"
                                title="Link Meter"
                                classes={{ label: classes.iconButtonLabel }}
                                color="primary"
                                onClick={() => searchMeters(searchESI)}
                            >
                                <SearchIcon size="large" />
                            </IconButton>
                        </ListItem>
                    </Grid>
                    <div className={classes.searchMeterResult}>
                        <h5>Select Meter</h5>

                        <ReactTable
                            data={searchMeterResult}
                            columns={columns}
                            className="-striped -highlight"
                            defaultPageSize={5}
                            showPagination={false}
                        />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleChange({
                            openAssignMeter: false,
                            searchMeterResult: [],
                            searchESI: "",
                        });
                    }}
                    color="primary"
                    variant="contained"
                    Close
                    style={{
                        float: "right",
                        marginRight: 10,
                        marginBottom: 10,
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AssignMeterTransaction.propTypes = {};

export default AssignMeterTransaction;
