import { createSelector } from "reselect";

const selectingMember = () => (state) => state.selectedMember;

const selectMembersContainer = () =>
  createSelector(selectingMember(), (subState) =>
    Object.assign(
      {},
      {
        ...subState,
        member: subState,
        notes: subState.get("notes"),
      }
    )
  );

export const selectedMemberContainer = () =>
  createSelector(selectingMember(), (subState) =>
    Object.assign(
      {},
      {
        email: subState.get("email")
      }
    )
  );

export default selectMembersContainer;
