import { takeEvery, all, put, call } from "redux-saga/effects";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import { receiveMembers, setIsLoading } from "../actions/search";
import "babel-polyfill";

export function* searchSaga(action) {
  const { payload: fields } = action;

  try {
    // fetch meter records
    const resp = yield fetch(constants.METER_SEARCH_URL, {
      meterID: fields.esiID,
    });

    // fetch members for each meter
    let membersResp = yield all(
      resp.meters.map((meter, i) => {
        return call(fetch, constants.MEMBER_GET_URL, {
          memberID: meter.memberID,
        });
      })
    );
    
    // Get list of member ids
    let memberIDs = membersResp.map((member) => member.memberID);

    // Fetch billing info for each member in member id list
    let billingResp = yield call(fetch, constants.DNP_FETCH_CANDIDATES_URL, {
      memberIDs: memberIDs,
      return_last_successful_payment: "true",
    });

    // Construct search result
    membersResp.map((member) => {
      const meter = resp.meters.find(
        (meter) => meter.memberID === member.memberID
      );
      const billingInfo = billingResp.accounts.find(
        (billing) => billing.memberID === member.memberID
      );
      member.meterAddress = meter?.service_address.lineOne;
      member.meterDetail = meter;
      member.account_balance = billingInfo?.account_balance;
      member.last_successful_payment =
        billingInfo?.last_successful_payment?.amount;
      member;
    });

    if (membersResp.length !== 0) {
      // display member
      yield put(receiveMembers({ Members: membersResp }));
    } else {
      // empty members
      yield put(receiveMembers({ Members: [{ no_results: true }] }));
    }
    yield put(setIsLoading(false));
  } catch (err) {
    console.log("error fetching members:", err);
    yield put(setIsLoading(false));
    return;
  }
}

export default [takeEvery(constants.ESI_SEARCH, searchSaga)];
