import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";

import { KeyboardDatePicker } from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import SummaryPanel from "./SummaryPanel";
import ChargesPanel from "./ChargesPanel";
import TransactionPanel from "./TransactionPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  topPanel: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  dateSelector: {
    display: "flex",
    alignItems: "center",
    margin: "0px 15px",
    "& #date-selector-label": {
      fontSize: "15px",
      marginRight: "15px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const MemberBilling = (props) => {
  const classes = useStyles();
  const {
    billingSummaryData,
    confMsg,
    acceptedPairs,
    billingInfo: {
      groupItems,
      membership,
      totalAmount,
      averageRates,
      chargeDetails,
      usageDetails,
      usageSummaries,
    },
    transactions: { transactions, hasNext, page, isFetching },
    doRequestAllBillingTransactions,
    authData,
    params,
    doApplyAdjustment,
    onNoteCreate,
    onHideSnackBar,
    doRequestUsageSummaryByDate,
  } = props;

  const [currentDate, setCurrentDate] = useState(moment());

  useEffect(() => {
    const { doFetchBillingInfo, params } = props;
    const date = moment();

    doFetchBillingInfo(date.month() + 1, date.year(), params.memberId);
  }, []);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onHideSnackBar();
  };
  const handleChangeForDate = (date) => {
    const selectedDate = moment(date);
    const { doFetchBillingInfo, params } = props;
    doFetchBillingInfo(
      selectedDate.month() + 1,
      selectedDate.year(),
      params.memberId
    );
    setCurrentDate(selectedDate);
  };

  // Create a formated price string
  function money(string) {
    if (typeof string === "number") {
      const value = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(string);
      return value;
    } else if (typeof string !== "string" || 0 === string.length) {
      return "N/A";
    } else {
      if (string.charAt(0) === "-") {
        return `${string.slice(0, 1)}$${parseFloat(string.slice(1)).toFixed(
          2
        )}`;
      }
      return `$${parseFloat(string).toFixed(2)}`;
    }
  }

  return (
    <div className={classes.root}>
      <h1 id="page-title">Billing</h1>
      <div className={classes.dateSelector}>
        <span id="date-selector-label">Select Month</span>
        <KeyboardDatePicker
          dateformat="YYYY/MM"
          value={currentDate}
          onChange={handleChangeForDate}
          views={["year", "month"]}
          style={{ margin: "15px 0px", width: "200px" }}
        />
      </div>
      <div className={classes.topPanel}>
        <ChargesPanel
          memberID={params.memberId}
          groupItems={groupItems}
          membership={membership}
          totalAmount={totalAmount}
          chargeDetails={chargeDetails}
          usageDetails={usageDetails}
          usageSummaries={usageSummaries}
          money={money}
          doRequestUsageSummaryByDate={doRequestUsageSummaryByDate}
        />
        <SummaryPanel averageRates={averageRates} />
      </div>
      <TransactionPanel
        authData={authData}
        transactions={transactions}
        acceptedPairs={acceptedPairs}
        hasNext={hasNext}
        page={page}
        money={money}
        params={params}
        doApplyAdjustment={doApplyAdjustment}
        doRequestAllBillingTransactions={doRequestAllBillingTransactions}
        onNoteCreate={onNoteCreate}
      />
      {confMsg && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={confMsg.get("showSnackBar")}
          autoHideDuration={3000}
          onClose={handleCloseSnackBar}
          message={
            confMsg.getIn(["response", "success"]) ? (
              <span id="message-id" className={confirmationSuccess}>
                {confMsg.getIn(["response", "msg"])}
              </span>
            ) : (
              <span id="message-id" className={confirmationError}>
                {confMsg.getIn(["response", "msg"])}
              </span>
            )
          }
        />
      )}
      <Backdrop
        className={classes.backdrop}
        open={billingSummaryData.get("isFetching") || isFetching}
      >
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

MemberBilling.propTypes = {
  member: PropTypes.object.isRequired,
};

export default MemberBilling;
