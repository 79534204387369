import Immutable from "immutable";
import * as constants from "../../constants";

const guestDefaultState = Immutable.fromJS({});

export function guest(state =  guestDefaultState, action) {
	switch (action.type) {
		case constants.RECEIVE_GUEST_MEMBERS:
			return state.merge(Immutable.fromJS({
				guestSearch: false,
			}));
		case constants.RECEIVE_MEMBERS:
			return state.merge(Immutable.fromJS({
				guestSearch: true,
			}));
    case constants.RECEIVE_SELECTED_USER_ID:
      return state.merge(Immutable.fromJS({
      	userID: action.userID
      }));
    case constants.RECEIVE_GUEST_USER_INFO:
    	return state.merge(Immutable.fromJS({
				...action.payload
    	}));
		default:
			return state;
	}
}

