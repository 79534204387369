import { all } from "redux-saga/effects";
import searchSaga from "./searchSaga";
import promoSaga from "./promoSaga";
import edfSaga from "./edfSaga";
import meterLookup from "./meterLookupSaga";

export default function* rootSaga() {
    yield all([
        ...searchSaga,
        ...promoSaga,
        ...edfSaga,
        ...meterLookup,
    ]);
}
