import * as constants from "../../constants";
import selectSignupContainer from "../selectors/signup";
import fetch from "../util/api-ajax";
import moment from "moment";

const createErrorMessage = (message) => ({
    type: constants.SIGNUP_SET_ERROR_MESSAGE,
    message,
});

const createSuccessMessage = (message) => ({
    type: constants.SIGNUP_SET_SUCCESS_MESSAGE,
    message,
});

export const setErrorMessage = (message) => (dispatch) => {
    dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
    dispatch(createSuccessMessage(message));
};

const getMember = (dispatch, user) => {
    const { userID } = user;
    const fields = { userID };

    return fetch(constants.MEMBER_GET_URL, fields)
        .then((member) => {
            if (!member) return;
            const formattedBirthday = moment(
                member.birthday.split("T")[0]
            ).format("YYYY-MM-DD");

            const dbMember = {
                ...member,
                birthday: formattedBirthday,
                memberAlreadyExists: true,
            };
            return dispatch({
                type: constants.SIGNUP_LOAD_MEMBER,
                member: dbMember,
            });
        })
        .catch((err) => {
            if (err.message !== "no record found for given id") {
                dispatch(setErrorMessage(err.message));
            }
        });
};

export const closeSnackbar = () => (dispatch) => {
    dispatch({ type: constants.SIGNUP_CLOSE_SNACKBAR });
};

export function updateSignUpForm(updatedInfo) {
    return (dispatch) =>
        dispatch({ type: constants.SIGNUP_UPDATE_FORM, updatedInfo });
}

export const getUserByEmail = () => (dispatch, getState) => {
    const { email } = selectSignupContainer()(getState());
    const fields = { email };
    return fetch(constants.USER_SEARCH_URL, fields)
        .then((userJson) => {
            const user = userJson.users && userJson.users[0];
            dispatch({ type: constants.SIGNUP_LOAD_USER, user });
            return user;
        })
        .then((user) => user && getMember(dispatch, user));
};

export const clearForm = () => (dispatch) => {
    return dispatch({ type: constants.SIGNUP_CLEAR_FORM });
};

export const resetPassword = () => (dispatch, getState) => {
    const { email } = selectSignupContainer()(getState());
    const fields = { email };

    return fetch(constants.SIGNUP_RESET_PASSWORD_URL, fields)
        .then((res) => {
            if (res.success) {
                dispatch(
                    setSuccessMessage(
                        "Password reset instructions has been sent to the user!"
                    )
                );
                return dispatch({ type: constants.SIGNUP_RESET_SUCCESS });
            }
            dispatch(
                setErrorMessage(
                    "Errors occurred bu sending password reset. Please try again."
                )
            );
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
        });
};

export const createUser = () => (dispatch, getState) => {
    const { email, phone, agreed } = selectSignupContainer()(getState());
    const adminUser = getState().user;
    const password = "GriddySignup50";
    const fields = {
        email,
        phone,
        password,
        agreed,
        created_by: adminUser.userID,
    };
    return fetch(constants.SIGNUP_USER_URL, fields)
        .then((user) => {
            dispatch({ type: constants.SIGNUP_LOAD_USER, user });
            dispatch(setSuccessMessage(`User ${email} successfully created!`));
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
        });
};

export const createMember = () => (dispatch, getState) => {
    const store = getState();
    const {
        userID,
        first_name,
        last_name,
        email,
        line1,
        line2,
        postal_code,
        postal_code_four,
        city,
        state,
        country,
        birthday,
    } = selectSignupContainer()(store);
    const adminUser = store.user;
    return fetch(constants.MEMBER_CREATE, {
        address: {
            city,
            country,
            line2,
            postal_code,
            postal_code_four,
            state,
            street_address: line1,
        },
        birthday,
        first_name,
        last_name,
        member_agreement: true,
        userID,
        created_by: adminUser.userID,
    })
        .then((member) => {
            dispatch({ type: constants.SIGNUP_CREATE_MEMBER_SUCCESS, member });
            dispatch(
                setSuccessMessage(
                    `Member ${first_name} ${last_name} successfully created!`
                )
            );
            resetPassword()(dispatch, getState);
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
        });
};

export const lookupServiceAddressMeter = () => (dispatch, getState) => {
    let metersResult = [];
    const {
        streetAddress,
        streetAddress2,
        postalCode,
    } = selectSignupContainer()(getState());
    const searchObj = {
        line1: streetAddress,
        line2: streetAddress2,
        postal_code: postalCode,
    };
    return fetch(constants.METER_SEARCH, searchObj)
        .then((data) => {
            if (data && data.esi) {
                metersResult.push(data);
            } else if (data && data.meters) {
                const { meters } = data;
                metersResult = meters;
            } else {
                metersResult.push({ no_results: true });
            }
            dispatch({
                type: constants.SIGNUP_METER_ADDRESS_SEACH_SUCCESS,
                metersResult,
            });
        })
        .catch((error) => {
            dispatch(setErrorMessage(error.message));
        });
};

export const setServiceAddress = (selectedIndex) => (dispatch) => {
    dispatch({ type: constants.SIGNUP_SET_SELECTED_ADDRESS, selectedIndex });
};
