import Immutable from "immutable";
import * as constants from "../../constants";

const defaultErcotResultState = Immutable.List();

export default function ercotResult(state = defaultErcotResultState, action) {
    switch (action.type) {
    case constants.RECEIVE_ERCOT_INVOICE_REPORT:
        return Immutable.List(action.rows);
    default:
        return state;
    }
}
