import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { all, omit, mergeDeepRight } from "ramda";
import DatePicker from "react-datepicker";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl } from "@material-ui/core/";
import Edit from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { ClipLoader } from "react-spinners";
import Modal from "react-modal";
import modalStyle from "../styles/modal";
import {
    selectCurrentPromo,
    selectPromoSnackbarStatus,
    selectPromoFetchingStatus,
    selectPromoActionResponse,
    selectPromoActionMessage,
} from "../selectors/promos";
import {
    currentPromoSelected,
    promoUpdated,
    promoCreated,
    hideSnackBar,
} from "../actions/promos";
import { confirmationError, confirmationSuccess } from "../styles/common.css";

const styles = {
    root: {
        minHeight: "700px",
        padding: "15px",
        width: "500px",
    },
    promoTitle: {
        display: "inline-block",
    },
    editIcon: {
        display: "inline-block",
        position: "relative",
        top: "-5px",
        margin: "0 0 0 10px",
    },
    inputRow: {
        padding: "10px 0",
        width: "50%",
    },
    codeRow: {
        margin: "0 0 10px 0",
        width: "100%",
        minHeight: "20px",
    },
    selectRow: {
        padding: "10px 0",
        width: "30%",
    },
    formRow: {
        padding: "10px 0",
    },
    formActions: {
        padding: "30px 0 0 0",
    },
    textArea: {
        width: "80%",
    },
    saveButton: {
        marginRight: "15px",
    },
    labelDisabled: {
        color: "rgba(0, 0, 0, 0.38)",
    },
    successEmoji: {
        "&:after": {
            padding: "0 0 0 10px",
            position: "relative",
            top: "5px",
            fontSize: "2rem",
            content: "'😀'",
        },
    },
    errorEmoji: {
        "&:after": {
            padding: "0 0 0 10px",
            position: "relative",
            top: "5px",
            fontSize: "2rem",
            content: "'💩'",
        },
    },
};

const requiredFields = ["code", "unit", "value", "is_active"];

class PromoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNew: false,
            isEditing: false,
            prePopulate: true,
            hasSubmitted: false,
            validations: [],
            promo: {
                type: "promo",
                name: "",
                code: "",
                description: "",
                is_active: "",
                start_date: "",
                end_date: "",
                unit: "",
                value: "",
                id: "",
            },
        };
    }

    componentDidMount() {
        const {
            routeParams: { promoCode },
            route: { path },
        } = this.props;
        if (path === "promo/new") {
            this.setState({ prePopulate: false, isNew: true });
            return;
        }
        this.props.currentPromoSelected(promoCode);
    }

    componentDidUpdate() {
        if (this.state.prePopulate && this.props.promo) {
            this.setState({ prePopulate: false, promo: this.props.promo });
        }
    }

    handleChange = (name) => (event) => {
        const promo = { promo: { [name]: event.target.value } };
        const mergedState = mergeDeepRight(this.state, promo);
        this.setState(mergedState);
    };

    handleDateChange = (name) => (date) => {
        const promo = { promo: { [name]: moment(date).format("YYYY-MM-DD") } };
        const mergedState = mergeDeepRight(this.state, promo);
        this.setState(mergedState);
    };

    handleEditToggle = () => {
        const { isEditing } = this.state;
        if (isEditing) {
            this.handleReset();
        } else {
            this.setState({ isEditing: true });
        }
    };

    handleReset = () => {
        this.setState({
            isEditing: false,
            hasSubmitted: false,
            promo: this.props.promo,
        });
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.hideSnackBar();
    };

    validate = (field) => {
        const { hasSubmitted } = this.state;
        const required = requiredFields.includes(field);
        const valid = Boolean(this.state.promo[field]);
        if (!required || !hasSubmitted) {
            return true;
        }
        return Boolean(required && valid);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { isNew, promo } = this.state;
        const { promoUpdated, promoCreated } = this.props;
        this.setState({ hasSubmitted: true });
        const isFormValidated = all(
            (field) => Boolean(this.state.promo[field]),
            requiredFields
        );
        if (!isFormValidated) {
            return;
        }
        if (isNew) {
            const newPromo = omit(["id"], promo);
            promoCreated(newPromo);
            return;
        }
        const editedPromo = omit(["type", "unit", "value"], promo);
        promoUpdated(editedPromo);
        this.setState({ isEditing: false });
    };

    render() {
        const {
            classes,
            showSnackbar,
            actionResponse,
            actionMessage,
            isFetching,
        } = this.props;
        const { isEditing, isNew, promo } = this.state;
        const editable = !(isEditing || isNew);
        const nonEditable = !isNew;

        const datePicker = (promo, value) => {
            const hasValue = Boolean(promo[value]);
            return hasValue ? (
                <DatePicker
                    dateFormat="YYYY-MM-DD"
                    disabled={editable}
                    selected={moment(promo[value])}
                    onChange={this.handleDateChange(value)}
                />
            ) : (
                <DatePicker
                    dateFormat="YYYY-MM-DD"
                    disabled={editable}
                    onChange={this.handleDateChange(value)}
                />
            );
        };

        return (
            <div className={classes.root}>
                <Modal
                    isOpen={isFetching}
                    contentLabel="Minimal Modal Example"
                    style={modalStyle.loadingModal}
                >
                    <ClipLoader
                        sizeUnit="px"
                        size={150}
                        color="#ED0874"
                        loading={isFetching}
                    />
                </Modal>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showSnackbar}
                    autoHideDuration={
                        actionResponse === "failure" ? 6000 : 3000
                    }
                    onClose={() => this.handleClose()}
                >
                    <span
                        id="message-id"
                        className={
                            actionResponse === "failure"
                                ? `${confirmationError} ${classes.errorEmoji}`
                                : `${confirmationSuccess} ${classes.successEmoji}`
                        }
                    >
                        {actionMessage}
                    </span>
                </Snackbar>
                {!isNew ? (
                    <div>
                        <h4 className={classes.promoTitle}>
                            Promo: {promo.code}
                        </h4>
                        <IconButton
                            aria-label="Edit"
                            onClick={this.handleEditToggle}
                            className={classes.editIcon}
                        >
                            <Edit color={isEditing ? "primary" : "inherit"} />
                        </IconButton>
                    </div>
                ) : (
                    <h4>New Promo</h4>
                )}
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className={classes.inputRow}>
                        <TextField
                            id="name"
                            label="Name"
                            name="name"
                            error={!this.validate("name")}
                            fullWidth
                            value={promo.name}
                            disabled={editable}
                            onChange={this.handleChange("name")}
                        />
                    </div>
                    <div className={classes.inputRow}>
                        <TextField
                            id="code"
                            label="Code"
                            name="code"
                            error={!this.validate("code")}
                            fullWidth
                            value={promo.code}
                            disabled={nonEditable}
                            onChange={this.handleChange("code")}
                        />
                    </div>
                    <div className={classes.codeRow}>
                        <label
                            className={
                                (editable && classes.labelDisabled) || undefined
                            }
                        >
                            Url:{" "}
                        </label>
                        <span
                            className={
                                (editable && classes.labelDisabled) || undefined
                            }
                        >
                            https://app.gogriddy.com/sign-up?_gpc={promo.code}
                        </span>
                    </div>
                    <div className={classes.textAreaRow}>
                        <TextField
                            onChange={this.handleChange("description")}
                            label="Description"
                            fullWidth
                            multiline
                            error={!this.validate("description")}
                            value={promo.description}
                            disabled={editable}
                            rows={4}
                            rowsMax={10}
                            inputProps={{
                                classes: {
                                    root: classes.textArea,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.selectRow}>
                        <FormControl disabled={editable} fullWidth>
                            <InputLabel htmlFor="promo-active">
                                Is active
                            </InputLabel>
                            <Select
                                value={promo.is_active}
                                onChange={this.handleChange("is_active")}
                                disabled={editable}
                                error={!this.validate("is_active")}
                                inputProps={{
                                    name: "Is active",
                                    id: "promo-active",
                                }}
                            >
                                <MenuItem value="true">Active</MenuItem>
                                <MenuItem value="false">Not active</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.formRow}>
                        <label
                            className={
                                (editable && classes.labelDisabled) || undefined
                            }
                        >
                            Start date
                        </label>
                        <div>{datePicker(promo, "start_date")}</div>
                    </div>
                    <div className={classes.formRow}>
                        <label
                            className={
                                (editable && classes.labelDisabled) || undefined
                            }
                        >
                            End date
                        </label>
                        <div>{datePicker(promo, "end_date")}</div>
                    </div>
                    <div className={classes.selectRow}>
                        <FormControl disabled={nonEditable} fullWidth>
                            <InputLabel htmlFor="promo-unit">Unit</InputLabel>
                            <Select
                                value={promo.unit}
                                onChange={this.handleChange("unit")}
                                error={!this.validate("unit")}
                                inputProps={{
                                    name: "Unit",
                                    id: "promo-unit",
                                }}
                            >
                                <MenuItem value="dollar">Dollar</MenuItem>
                                <MenuItem value="month">Month</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.selectRow}>
                        <FormControl disabled={nonEditable} fullWidth>
                            <InputLabel htmlFor="promo-value">Value</InputLabel>
                            {promo.unit === "dollar" ? (
                                <Select
                                    value={promo.value}
                                    onChange={this.handleChange("value")}
                                    error={!this.validate("value")}
                                    inputProps={{
                                        name: "value",
                                        id: "promo-value",
                                    }}
                                >
                                    <MenuItem value="10.00">$10</MenuItem>
                                    <MenuItem value="20.00">$20</MenuItem>
                                    <MenuItem value="25.00">$25</MenuItem>
                                    <MenuItem value="50.00">$50</MenuItem>
                                    <MenuItem value="150.00">$150</MenuItem>
                                    <MenuItem value="250.00">$250</MenuItem>
                                </Select>
                            ) : (
                                <Select
                                    value={promo.value}
                                    onChange={this.handleChange("value")}
                                    error={!this.validate("value")}
                                    inputProps={{
                                        name: "value",
                                        id: "promo-value",
                                    }}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="6">6</MenuItem>
                                    <MenuItem value="12">12</MenuItem>
                                </Select>
                            )}
                        </FormControl>
                    </div>
                    {(isEditing || isNew) && (
                        <div className={classes.formActions}>
                            <Button
                                className={classes.saveButton}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.handleReset}
                            >
                                Cancel
                            </Button>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

PromoDetail.propTypes = {
    promo: PropTypes.object,
    classes: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    currentPromoSelected: PropTypes.func.isRequired,
    hideSnackBar: PropTypes.func.isRequired,
    showSnackbar: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    actionResponse: PropTypes.string.isRequired,
    actionMessage: PropTypes.string.isRequired,
    promoUpdated: PropTypes.func.isRequired,
    promoCreated: PropTypes.func.isRequired,
};

PromoDetail.defaultProps = {
    promo: {},
};

const mapState = createStructuredSelector({
    promo: selectCurrentPromo,
    showSnackbar: selectPromoSnackbarStatus,
    isFetching: selectPromoFetchingStatus,
    actionResponse: selectPromoActionResponse,
    actionMessage: selectPromoActionMessage,
});

const mapDispatch = {
    currentPromoSelected,
    promoUpdated,
    promoCreated,
    hideSnackBar,
};
export default withStyles(styles)(connect(mapState, mapDispatch)(PromoDetail));
