import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { navigation } from "../styles/navigation.css";
import { logout } from "../actions/user";

const App = ({ children }) => (
    <div>
        <header className={navigation}>
            {/* https://goo.gl/gtyF9S - nav component example to remove inline activestyle below */}
            <Link
                to="/dashboard"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Dashboard
            </Link>{" "}
            <Link
                to="/members"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Members
            </Link>{" "}
            <Link
                to="/signup"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Sign Up
            </Link>{" "}
            <Link
                to="/ercot"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Ercot
            </Link>{" "}
            <Link
                to="/tdsp"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                TDSP
            </Link>{" "}
            <Link
                to="/edf"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Finance
            </Link>{" "}
            <Link
                to="/promos"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Promos
            </Link>{" "}
            <Link
                to="/meters"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Meter Lookup
            </Link>{" "}
            <Link
                to="/dnprnp/dnpqueue"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                DNP/RNP
            </Link>{" "}
            <Link
                to="/metertransactions"
                activeStyle={{ textDecoration: "none", color: "black" }}
            >
                Unprocessed Transactions
            </Link>{" "}
            <Link to="/login">Login</Link>{" "}
            <button onClick={() => logout()}>Logout</button>
        </header>
        {children}
    </div>
);

App.propTypes = {
    children: PropTypes.object.isRequired,
};

export default App;