import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { connect } from "react-redux";
import { subNavigation } from "../styles/navigation.css";
import { fetchGuestInfo } from "../actions/guest";

const mapDispatchToProps = dispatch => ({
    onFetchGuestInfo: guest => dispatch(fetchGuestInfo(guest)),
});

class Member extends Component {

    componentWillMount() {
    	this.props.onFetchGuestInfo(this.props.params.guestUserId);
    }

    render() {
        const { children, params } = this.props;
        return (
            <div>
                <header className={ subNavigation }>
                    <Link to={ `/guest/${params.guestUserId}/usage` } activeStyle={ { textDecoration: "none", color: "black" } }>Usage</Link>
                    {" "}
                    <Link to={ `/guest/${params.guestUserId}/details` } activeStyle={ { textDecoration: "none", color: "black" } }>Details</Link>
                    {" "}
                    <Link to={ `/guest/${params.guestUserId}/savings` } activeStyle={ { textDecoration: "none", color: "black" } }>Savings</Link>
                    {" "}
                </header>
                { children }
            </div>
        );
    }
}

Member.propTypes = {
    children: PropTypes.object,
    params: PropTypes.object,
};

Member.defaultProps = {
    children: {},
    params: {},
};

export default connect(null,mapDispatchToProps)(Member);
