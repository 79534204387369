import PropTypes from "prop-types";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { noselect } from "../../styles/common.css";

const useStyles = makeStyles({
  tableContainer: {
    margin: "15px",
    width: "auto !important",
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "15px",
      color: "white",
    },
  },
  chargesTotalRow: {
    borderTop: "2px solid rgba(0, 0, 0, 0.35)",
    "& #total-label-cell": {
      fontWeight: "bold",
      textAlign: "end",
    },
    "& #total-label-cell, #total-cell": {
      fontSize: "15px",
      paddingLeft: "10px",
    },
  },
});

const MonthlyChargesTable = (props) => {
  const classes = useStyles();
  const { groupItems, totalAmount, membership, money } = props;

  return (
    <Table className={classes.tableContainer}>
      <TableHead className={`${classes.tableHeaderRow} ${noselect}`}>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Sub Type</TableCell>
          <TableCell>Charges</TableCell>
          <TableCell>Totals</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groupItems &&
          groupItems.map((groupItem) => {
            if (groupItem.get("title")) {
              return (
                <TableRow key={groupItem.get("title")}>
                  <TableCell>{groupItem.get("title")}</TableCell>
                  <TableCell>
                    {groupItem.get("line_items").map((lineItem, index) => (
                      <div key={index} style={{ padding: "3px 0px" }}>
                        {lineItem.get("name")}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {groupItem.get("line_items").map((lineItem, index) => (
                      <div key={index} style={{ padding: "3px 0px" }}>
                        {money(lineItem.get("amount_dollars"))}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {money(groupItem.get("amount_dollars"))}
                  </TableCell>
                </TableRow>
              );
            }
          })}
        {membership && (
          <TableRow>
            <TableCell>Griddy Membership</TableCell>
            <TableCell></TableCell>
            <TableCell>
              {money(membership.get("amount_dollars"))}
            </TableCell>{" "}
            <TableCell>{money(membership.get("amount_dollars"))}</TableCell>
          </TableRow>
        )}
        <TableRow className={classes.chargesTotalRow}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell id="total-label-cell">Total:</TableCell>
          <TableCell id="total-cell">
            {totalAmount && money(totalAmount)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default MonthlyChargesTable;
