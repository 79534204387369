import { createSelector } from "reselect";

const billingSummaryData = () => (state) => state.billingSummaryResult;

const selectBillingInfoContainer = () =>
  createSelector(billingSummaryData(), (billingSummaryData) => {
    return Object.assign(
      {},
      {
        groupItems: billingSummaryData.has("group_items")
          ? billingSummaryData.get("group_items")
          : null,
        membership: billingSummaryData.has("membership")
          ? billingSummaryData.get("membership")
          : null,
        totalAmount: billingSummaryData.has("total_amount")
          ? billingSummaryData.get("total_amount")
          : null,
        averageRates: {
          kwh_used: billingSummaryData.get("kwh_used"),
          allin_rate_ckwh: billingSummaryData.get("allin_rate_ckwh"),
          rate_to_compare_ckwh: billingSummaryData.get("rate_to_compare_ckwh"),
        },
        chargeDetails: billingSummaryData.has("chargeDetails")
          ? billingSummaryData.get("chargeDetails")?.toJS()
          : null,
        usageDetails: billingSummaryData.has("usageDetails")
          ? billingSummaryData.get("usageDetails")?.toJS()
          : null,
        usageSummaries: billingSummaryData.has("usageSummaries")
          ? billingSummaryData.get("usageSummaries")?.toJS()
          : null,
      }
    );
  });

export default selectBillingInfoContainer;