import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchBillingUsageReport, fetchMeterReport } from "../actions/billing";
import { triColumn } from "../styles/dashboard.css";
import fetchPrices from "../actions/prices";
import PriceBubbles from "../components/PriceBubbles";

class Dashboard extends Component {
    componentDidMount() {
        const {
            doFetchBillingUsageReport, doFetchMeterReport, doFetchPrices,
        } = this.props;

        doFetchBillingUsageReport();
        doFetchMeterReport();
        doFetchPrices(this.props.params.memberId);
    }

    fetchPriceWithMember = () => {
        const { doFetchPrices } = this.props;

        doFetchPrices(this.props.params.memberId);
    }

    render() {
        const { data, pricesData } = this.props;
        const timeout = 30000;

        return (
            <div>
                <PriceBubbles prices={ pricesData } timeout={ timeout } cbAction={ this.fetchPriceWithMember } />
                <hr />
                <div className={ triColumn }>
                    <div>
                        <h1>Number of Customers</h1>
                        <p>{data.get("number_of_meters")}</p>
                    </div>
                    <div>
                        <h1>Average Griddy rate</h1>
                        <p>{data.get("average_rate_cent")}¢/kWh</p>
                    </div>
                    <div>
                        <h1>Total Member usage (kwh)</h1>
                        <p>{data.get("total_usage")}</p>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    data: PropTypes.object,
    pricesData: PropTypes.object,
    params: PropTypes.object.isRequired,
    doFetchBillingUsageReport: PropTypes.func.isRequired,
    doFetchMeterReport: PropTypes.func.isRequired,
    doFetchPrices: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
    data: {},
    pricesData: [],
};

const mapStateToProps = state => ({
    data: state.billingUsageReport,
    pricesData: state.prices,
});

const mapDispatchToProps = dispatch => ({
    doFetchBillingUsageReport: () => { dispatch(fetchBillingUsageReport()); },
    doFetchMeterReport: () => { dispatch(fetchMeterReport()); },
    doFetchPrices: () => { dispatch(fetchPrices()); },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
