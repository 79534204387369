import Immutable from "immutable";
import * as constants from "../../constants";

const defaultCardList = Immutable.fromJS([]);
const defaultChargeList = Immutable.fromJS([]);
const defaultPaymentRefundMsg = Immutable.fromJS({});
const paymentInitState = {
    isLoading: false,
    confirmDeleteModalOpen: false,
    errorMessage: "",
    successMessage: "",
    cardID: "",
    cardLastFour: "",
    showSnackBar: false,    
};

const toggleConfirmDelete = (state) => {
    return {
        ...state,
        confirmDeleteModalOpen: !state.confirmDeleteModalOpen,
    };
};

const setCardToDelete = (state, action) => {
    return {
        ...state,
        ...action,
        confirmDeleteModalOpen: true,
    };
};

const setErrorMessage = (state, action) => {
    return {
        ...state,
        errorMessage: action.errorMessage,
        showSnackBar: true,
    };
};

const clearErrorMessage = (state) => {
    return {
        ...state,
        errorMessage: "",
        showSnackBar: false,
    };
};

const setIsLoading = (state, isLoading) =>{
    return {
        ...state,
        isLoading,
    }
}

export function payments(state = paymentInitState, action) {
    switch (action.type) {
        case constants.PAYMENTS_TOGGLE_CONFIRM_DELETE:
            return toggleConfirmDelete(state);
        case constants.PAYMENTS_SET_CARD_DELETE:
            return setCardToDelete(state, action);
        case constants.PAYMENTS_SET_ERROR:
            return setErrorMessage(state, action);
        case constants.PAYMENTS_CLEAR_ERROR:
            return clearErrorMessage(state);
        case constants.PAYMENTS_INITIALIZED:
            return setIsLoading(state, action.isLoading);
        default:
            return state;
    }
}

export function paymentCardList(state = defaultCardList, action) {
    switch (action.type) {
        case constants.RECEIVE_PAYMENT_CARDS:
            return Immutable.fromJS(action.data);
        default:
            return state;
    }
}

export function paymentChargeList(state = defaultChargeList, action) {
    switch (action.type) {
        case constants.RECEIVE_PAYMENT_CHARGES:
            return Immutable.fromJS(action.data);
        case constants.TOGGLE_CHARGE: {
            const bool = !state.get(action.index).get("show");
            const newState = state.setIn([action.index, "show"], bool);
            return newState;
        }
        default:
            return state;
    }
}

export function paymentRefundMsg(state = defaultPaymentRefundMsg, action) {
    switch (action.type) {
        case constants.RECEIVE_PAYMENT_REFUND:
            return Immutable.fromJS(action.data);
        default:
            return state;
    }
}
