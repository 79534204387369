import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestSavingsData() {
    return {
        type: constants.REQUEST_SAVINGS,
        isFetching: true,
    };
}
function receiveSavingsData(data) {
    return {
        type: constants.RECEIVE_SAVINGS,
        isFetching: false,
        data,
    };
}
export default function fetchSavingsData(member, guest) {
    return (dispatch) => {
        dispatch(requestSavingsData);

		  	const endpoint = guest ? constants.GUEST_ANALYTICS_SAVINGS : constants.ANALYTICS_SAVINGS;

        return fetch(endpoint, { memberID: member })
            .then((data) => {
                dispatch(receiveSavingsData(data));
            });
    };
}
