import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import { fetchUserIDWithFullMemberInfoNeeded } from "../actions/search";
import { fetchBillingSummary } from "../actions/billing";
import TdspTable from "./Tdsp";

const styles = () => ({
    list: {
        listStyle: "none",
        paddingLeft: "0",
    },
    column: {
        width: "50%",
    },
});

class Disputes extends Component {
    componentWillMount() {
        const {
            onFetchUserIDWithFullMemberInfoNeeded,
            doFetchBillingSummary,
            params: { memberId },
        } = this.props;
        onFetchUserIDWithFullMemberInfoNeeded(memberId);
        doFetchBillingSummary(8, 2019, memberId);
    }

    render() {
        const { member, billing, classes, transactions } = this.props;

        const lt = transactions.deposits[0];
        const isRefund =
            lt &&
            lt.type_name === "deposit" &&
            lt.subtype_name === "balance_refund";

        return (
            <div className={classes.root}>
                <div>
                    <h4>Basic info</h4>
                    <ul className={classes.list}>
                        <li id="name" key="name">
                            <b>Name:</b> {member.get("first_name")}{" "}
                            {member.get("last_name")}
                        </li>
                        <li id="email" key="email">
                            <b>Email:</b> {member.get("email")}
                        </li>
                        <li id="recharge_amount" key="recharge_amount">
                            <b>Recharge amount:</b> $
                            {member.get("recharge_dollars")}
                        </li>
                        {member.get("meters").map((meter) => (
                            <div key="div">
                                <li id="enrollement_date" key="enrollment_date">
                                    <b>Enrollment completed:</b>{" "}
                                    {meter.get("enrollment_date")
                                        ? meter
                                              .get("enrollment_date")
                                              .split("T")[0]
                                        : ""}
                                </li>
                                <li id="member_id" key="member_id">
                                    <b>Member Id:</b> {meter.get("memberID")}
                                </li>
                                <li id="esi" key="esi">
                                    <b>ESI:</b> {meter.get("esi")}
                                </li>
                                <li id="start_date" key="start_date">
                                    <b>Start date:</b>{" "}
                                    {meter.get("start_date")
                                        ? meter.get("start_date").split("T")[0]
                                        : ""}
                                </li>
                                <li id="end_date" key="end_date">
                                    <b>End date:</b>{" "}
                                    {meter.get("end_date")
                                        ? meter.get("end_date").split("T")[0]
                                        : ""}
                                </li>
                            </div>
                        ))}
                    </ul>
                    <ul id="puc_complaints" className={classes.list}>
                        {member.get("meters").map((meter) => (
                            <div key="div">
                                <li id="load_zone" key="load_zone">
                                    <b>Load zone:</b> {meter.get("load_zone")}
                                </li>
                                <li id="active_status" key="active_status">
                                    <b>Active status:</b>{" "}
                                    {member.get("active")
                                        ? "Enabled"
                                        : "Disabled"}
                                </li>
                            </div>
                        ))}
                        <li id="all_in_rate" key="all_in_rate">
                            <b>All in rate August, 2019:</b>{" "}
                            {billing.get("allin_rate_ckwh")}¢/kWh
                        </li>
                        <li id="last_refund" key="last_refund">
                            <b>Last refund:</b>{" "}
                            {isRefund ? (
                                <span>
                                    {lt.created.split("T")[0]} {lt.amount}{" "}
                                    {lt.type_name} {lt.subtype_description}
                                </span>
                            ) : (
                                "N/A"
                            )}
                        </li>
                    </ul>
                </div>
                <hr />
                <div>
                    <h4>MarkeTrak info</h4>
                    <ul id="marketrak_complaints" className={classes.list}>
                        <li id="gg_status" key="gg_status">
                            <b>GG Status:</b> {member.get("gg_status")}
                        </li>
                        <li id="language" key="language">
                            <b>Prefered language:</b> {member.get("language")}
                        </li>
                        <li id="notify_price_alert" key="notify_price_alert">
                            <b>Price alerts:</b>{" "}
                            {member.get("notify_price_alert")
                                ? "true"
                                : "false"}
                        </li>

                        {member.get("meters").map((meter) => (
                            <div key="div">
                                <li
                                    id="polr_customer_class"
                                    key="polr_customer_class"
                                >
                                    <b>Polr class:</b>{" "}
                                    {meter.get("polr_customer_class")}
                                </li>
                                <li
                                    id="open_service_orders"
                                    key="open_service_orders"
                                >
                                    <b>Open service orders:</b>{" "}
                                    {meter.get("open_service_orders")
                                        ? meter.get("open_service_orders")
                                        : "none"}
                                </li>
                                <li
                                    id="meter_read_cycle"
                                    key="meter_read_cycle"
                                >
                                    <b>Meter read cycle:</b>{" "}
                                    {meter.get("meter_read_cycle")}
                                </li>
                                <li id="rate_class" key="rate_class">
                                    <b>Rate class:</b> {meter.get("rate_class")}
                                </li>
                                <li id="tier_name" key="tier_name">
                                    <b>Tier name:</b> {meter.get("tier_name")}
                                </li>
                            </div>
                        ))}
                        {member.has("devices") &&
                            member.get("devices").map((device) => (
                                <li key={device.hwID}>
                                    <b>Platform:</b> {device.get("platform")}
                                </li>
                            ))}
                    </ul>
                    {member.get("memberID") && (
                        <TdspTable memberId={member.get("memberID")} />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    member: state.selectedMember,
    billing: state.billingSummaryResult,
    transactions: state.billingTransactions,
});

const mapDispatchToProps = (dispatch) => ({
    onFetchUserIDWithFullMemberInfoNeeded: (member) =>
        dispatch(fetchUserIDWithFullMemberInfoNeeded(member)),
    doFetchBillingSummary: (month, year, memberID) => {
        dispatch(fetchBillingSummary({ month, year, memberID }));
    },
});

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(Disputes)
);
