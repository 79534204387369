import * as C from "../../constants";

const initialState = {
    initialized: false,
    page: 1,
    limit: 100,
    status: "all",
    transactions: [],
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
};

const loadTransactions = (state, action) => {
    return {
        ...state,
        ...action,
    };
};

const updateFilter = (state, action) => {
    return {
        ...state,
        ...action.updatedInfo,
    };
};

const closeSnackBar = (state) => {
    return {
        ...state,
        showSnackBar: false,
        successMessage: "",
        errorMessage: "",
    };
};

const openSnackBar = (state, action) => {
    return {
        ...state,
        showSnackBar: true,
        ...action,
    };
};

export default function dnp(state = initialState, action) {
    switch (action.type) {
        case C.DNP_RNP_UPDATE_FILTERS:
            return updateFilter(state, action);
        case C.DNP_RNP_LOAD_TRANSACTIONS:
            return loadTransactions(state, action);
        case C.DNP_RNP_CLOSE_SNACKBAR:
            return closeSnackBar(state);
        case C.DNP_RNP_OPEN_SNACKBAR:
            return openSnackBar(state, action);
        default:
            return state;
    }
}
