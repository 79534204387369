import Immutable from "immutable";
import * as constants from "../../constants";

const defaultMeterResultState = Immutable.List();
const defaultRateClassesState = Immutable.List();
const defaultUpdateGriddyRateClassState = false;
const defaultGetMeterState = Immutable.List();
const defaultOverrideRateClassState = false;
const defaultRemoveOverrideResult = false;
const defaultMeterAvgMonthlyUsage = "";

export function meterResult(state = defaultMeterResultState, action) {
    switch (action.type) {
        case constants.RECEIVE_METERS:
            return Immutable.List(action.data);
        default:
            return state;
    }
}

export function getMeterResult(state = defaultGetMeterState, action) {
    switch (action.type) {
        case constants.RECEIVE_METER:
            return Immutable.List(action.data);
        default:
            return state;
    }
}

export function rateClassesResult(state = defaultRateClassesState, action) {
    switch (action.type) {
        case constants.RECEIVE_RATE_CLASSES:
            return Immutable.List(action.data);
        default:
            return state;
    }
}

export function updateGriddyRateClassResult(
    state = defaultUpdateGriddyRateClassState,
    action
) {
    switch (action.type) {
        case constants.RECEIVE_UPDATE_GRIDDY_RATE_CLASS:
            return action.data;
        default:
            return state;
    }
}

export function overrideRateClassResult(
    state = defaultOverrideRateClassState,
    action
) {
    switch (action.type) {
        case constants.RECEIVE_OVERRIDE_RATE_CLASS:
            return state;
        default:
            return state;
    }
}

export function removeOverrideResult(
    state = defaultRemoveOverrideResult,
    action
) {
    switch (action.type) {
        case constants.RECEIVE_REMOVE_OVERRIDE:
            return action.data;
        default:
            return state;
    }
}

export function getMeterAvgMonthlyUsage(
    state = defaultMeterAvgMonthlyUsage,
    action
) {
    switch (action.type) {
        case constants.RECEIVE_AVG_MONTHLY_USAGE:
            return action.data;
        default:
            return state;
    }
}
