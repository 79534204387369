import { createSelector } from "reselect";

const iterableData = () => (state) => state.iterable;
const iterableChannels = () => (state) => state.iterable.channels;
const iterableEvents = () => (state) => state.iterable.events;

const selectIterableData = () =>
  createSelector(iterableData(), (subState) => {
    return subState;
  });

const selectFilteredEvents = () =>
  createSelector(iterableEvents(), (events) => {
    const validEventTypes = ["emailSend", "emailUnSubscribe", "emailSubscribe"];

    const filteredEvents = events.filter((event) => {
      if (validEventTypes.includes(event.eventType)) {
        return true;
      }
      return false;
    });

    return Object.assign(
      {},
      {
        filteredEvents: filteredEvents,
      }
    );
  });

const selectIterableDataContainer = () =>
  createSelector(
    selectIterableData(),
    selectFilteredEvents(),
    (subState, filteredEvents) => {
      return Object.assign(
        {},
        {
          ...subState,
          ...filteredEvents,
        }
      );
    }
  );

export default selectIterableDataContainer;
