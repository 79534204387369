import * as C from "../../constants";

export const fetchPromos = () => ({
    type: C.REQUEST_PROMOS_GET_ALL,
});

export const receivePromosSuccess = promos => ({
    type: C.RECEIVE_PROMOS_SUCCESS,
    payload: promos,
});

export const setCurrentPromo = promo => ({
    type: C.SET_CURRENT_PROMO,
    payload: promo,
});

export const currentPromoSelected = promoCode => ({
    type: C.CURRENT_PROMO_SELECTED,
    payload: { promoCode },
});

export const promoCreated = payload => ({
    type: C.PROMO_CREATED,
    payload,
});

export const createPromoRequest = () => ({
    type: C.PROMO_CREATE_REQUEST,
});

export const promoCreateSuccess = payload => ({
    type: C.PROMO_CREATE_SUCCESS,
    payload,
});

export const promoCreateFailure = payload => ({
    type: C.PROMO_CREATE_FAILURE,
    payload,
});

export const promoUpdated = payload => ({
    type: C.PROMO_UPDATED,
    payload,
});

export const updatePromoRequest = () => ({
    type: C.PROMO_CREATE_REQUEST,
});

export const promoUpdatedSuccess = payload => ({
    type: C.PROMO_UPDATE_SUCCESS,
    payload,
});

export const promoUpdatedFailure = payload => ({
    type: C.PROMO_UPDATE_FAILURE,
    payload,
});

export const hideSnackBar = () => ({
    type: C.HIDE_PROMO_SNACKBAR,
});

export const clearPromosSearchResults = () => ({
    type: C.CLEAR_PROMO_SEARCH_RESULTS,
});
