import { mergeRight } from "ramda";
import * as C from "../../constants";

const initialState = {
    userID: "",
    email: "",
    isFetching: false,
    showSnackBar: false,
    response: "",
};

const userUpdate = (state = initialState, action) => {
    switch (action.type) {
        case C.HYDRATE_USER: {
            const { userID, email } = action.payload;
            const user = {
                userID,
                email,
            };
            return mergeRight(state, user);
        }
        case C.USER_LOGGED_IN: {
            return mergeRight(state, { userID: action.userID });
        }
        case C.USER_EMAIL_UPDATED: {
            return mergeRight(state, { email: action.email });
        }
        case C.USER_LOGGED_OUT:
            return initialState;
        case C.RECEIVE_USER_LOG_IN_ERROR: {
            const { message } = action.payload;
            const user = {
                isFectching: false,
                showSnackBar: true,
                response: message,
            };

            return mergeRight(state, user);
        }
        case C.HIDE_SNACKBAR: {
            return mergeRight(state, { showSnackBar: false });
        }
        default:
            return state;
    }
};

export default userUpdate;
