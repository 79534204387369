import { createSelector } from "reselect";

const meterLookupDetails = state => state.meterLookupDetails;

export const selectMeterLookupDetails = createSelector(
    meterLookupDetails,
    details => details.meter
);

export const selectMeterLookupSnackbarStatus = createSelector(
    meterLookupDetails,
    details => {
	const { showSnackbar, actionMessage, actionResponse } = details;
	return {
	    showSnackbar,
	    actionMessage,
	    actionResponse,
	};
    }
)

