import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import user from "./user";
import { memberSearchResult, selectedMember } from "./members";
import { guest } from "./guest";
import ercotResult from "./ercot";
import edfResult from "./edf";
import tdspResult from "./tdsp";
import {
    meterResult,
    rateClassesResult,
    updateGriddyRateClassResult,
    getMeterResult,
    overrideRateClassResult,
    getMeterAvgMonthlyUsage,
    removeOverrideResult,
} from "./meters";
import prices from "./prices";
import usage from "./usage";
import {
    billingSummaryResult,
    billingAdjustmentMsg,
    billingUsageReport,
    billingTransactions,
} from "./billing";
import {
    paymentCardList,
    paymentChargeList,
    paymentRefundMsg,
    payments,
} from "./payment";
import savingsData from "./savings";
import promos from "./promos";
import { acceptedPairs } from "./acceptedPairs";
import meterLookupDetails from "./meterLookup";
import signup from "./signup";
import dnp from "./dnp";
import dnpRnpHistory from "./dnpRnpHistory";
import accountFlags from "./accountFlags";
import meterTransactions from "./meterTransactions";
import tax from "./tax";
import iterable from "./iterable";

const rootReducer = combineReducers({
    user,
    memberSearchResult,
    selectedMember,
    guest,
    ercotResult,
    edfResult,
    tdspResult,
    meterResult,
    getMeterResult,
    rateClassesResult,
    updateGriddyRateClassResult,
    overrideRateClassResult,
    removeOverrideResult,
    getMeterAvgMonthlyUsage,
    prices,
    usage,
    billingSummaryResult,
    billingAdjustmentMsg,
    billingUsageReport,
    billingTransactions,
    paymentCardList,
    paymentChargeList,
    paymentRefundMsg,
    payments,
    routing,
    savingsData,
    promos,
    acceptedPairs,
    meterLookupDetails,
    signup,
    dnp,
    dnpRnpHistory,
    accountFlags,
    meterTransactions,
    tax,
    iterable
});

export default rootReducer;
