export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const RECEIVE_USER_LOG_IN_ERROR = "RECEIVE_USER_LOG_IN_ERROR";
export const USER_EMAIL_UPDATED = "USER_EMAIL_UPDATED";
export const HYDRATE_USER = "HYDRATE_USER";
export const REQUEST_MEMBERS = "REQUEST_MEMBERS";
export const RECEIVE_MEMBERS = "RECEIVE_MEMBERS";
export const SELECTED_MEMBER = "SELECTED_MEMBER";
export const REQUEST_AUTH_HEADERS = "REQUEST_AUTH_HEADERS";
export const RECEIVE_AUTH_HEADERS = "RECEIVE_AUTH_HEADERS";
export const CLEAR_SELECTED_MEMBER = "CLEAR_SELECTED_MEMBER";

export const MEMBERS_GET_ALL_SUCCESS = "MEMBERS_GET_ALL_SUCCESS";
export const MEMBERS_GET_ALL_FAILURE = "MEMBERS_GET_ALL_FAILURE";

export const RECEIVE_GUEST_MEMBERS = "RECEIVE_GUEST_MEMBERS";
export const RECEIVE_GUEST_USER_INFO = "RECEIVE_GUEST_USER_INFO";

export const RECEIVE_SELECTED_USER_ID = "RECEIVE_SELECTED_USER_ID";

export const ESI_SEARCH = "ESI_SEARCH";

export const CLEAR_DISPUTE_INFO = "CLEAR_DISPUTE_INFO";

export const UPDATE_LOADING_SEARCH_STATE = "UPDATE_LOADING_SEARCH_STATE";

export const SEARCH_PAGINATION_STARTED = "SEARCH_PAGINATION_STARTED";
export const SEARCH_PAGINATION_COMPLETE = "SEARCH_PAGINATION_COMPLETE";
export const INCREMENT_SEARCH_RESULTS_PAGE_COUNT =
  "INCREMENT_SEARCH_RESULTS_PAGE_COUNT";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";

export const REQUEST_MEMBER_INFO = "REQUEST_MEMBER_INFO";
export const RECEIVE_MEMBER_USER_INFO = "RECEIVE_MEMBER_USER_INFO";
export const RECEIVE_MEMBER_METER_INFO = "RECEIVE_MEMBER_METER_INFO";
export const RECEIVE_RELATED_MEMBER_METER_INFO =
  "RECEIVE_RELATED_MEMBER_METER_INFO";

export const REQUEST_CHANGE_TO_ACCOUNT = "REQUEST_CHANGE_TO_ACCOUNT";
export const REQUEST_SET_TAX_EXEMPTION = "REQUEST_SET_TAX_EXEMPTION";
export const RECEIVE_USER_UPDATE = "RECEIVE_USER_UPDATE";
export const REQUEST_ADD_FUNDS = "REQUEST_ADD_FUNDS";
export const RECEIVE_ADD_FUNDS_CONF = "RECEIVE_ADD_FUNDS_CONF";
export const RECEIVE_ADD_METER_CONF = "RECEIVE_ADD_METER_CONF";
export const RECEIVE_APPLY_REFUND_CONF = "RECEIVE_APPLY_REFUND_CONF";
export const RECEIVE_CLOSE_ACCOUNT_CONF = "RECEIVE_CLOSE_ACCOUNT_CONF";
export const RECEIVE_BILLING_ACTIVATE = "RECEIVE_BILLING_ACTIVATE";
export const CLEAR_MEMBER_CONF_MSG = "CLEAR_MEMBER_CONF_MSG";
export const RECEIVE_MEMBER_UPDATE = "RECEIVE_MEMBER_UPDATE";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export const REQUEST_ERCOT_INVOICE_REPORT = "REQUEST_ERCOT_INVOICE_REPORT";
export const RECEIVE_ERCOT_INVOICE_REPORT = "RECEIVE_ERCOT_INVOICE_REPORT";
export const REQUEST_TDSP_INVOICE_REPORT = "REQUEST_TDSP_INVOICE_REPORT";
export const RECEIVE_TDSP_INVOICE_REPORT = "RECEIVE_TDSP_INVOICE_REPORT";
export const REQUEST_EDF_REPORT = "REQUEST_EDF_REPORT";
export const RECEIVE_EDF_REPORT = "RECEIVE_EDF_REPORT";
export const CREATE_EDF_TRADE = "CREATE_EDF_TRADE";
export const SET_SNACKBAR = "SET_SNACKBAR";
export const SET_TRADE_AMOUNT = "SET_TRADE_AMOUNT";
export const SET_TRADE_DATE = "SET_TRADE_DATE";

export const REQUEST_BILLING_SUMMARY = "REQUEST_BILLING_SUMMARY";
export const RECEIVE_BILLING_SUMMARY = "RECEIVE_BILLING_SUMMARY";
export const RECEIVE_BILLING_SUMMARY_DETAILS =
  "RECEIVE_BILLING_SUMMARY_DETAILS";
export const RECEIVE_BILLING_SUMMARY_USAGE_DETAILS =
  "RECEIVE_BILLING_SUMMARY_USAGE_DETAILS";
export const REQUEST_BILLING_USAGE_REPORT = "REQUEST_BILLING_USAGE";
export const RECEIVE_BILLING_USAGE_REPORT = "RECEIVE_BILLING_USAGE";
export const REQUEST_BILLING_ADJUSTMENT = "REQUEST_BILLING_ADJUSTMENT";
export const RECEIVE_BILLING_ADJUSTMENT = "RECEIVE_BILLING_ADJUSTMENT";
export const REQUEST_BILLING_TRANSACTIONS = "REQUEST_BILLING_TRANSACTIONS";
export const RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS =
  "RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS";
export const RECEIVED_BILLING_TRANSACTIONS = "RECEIVED_BILLING_TRANSACTIONS";
export const RECEIVED_ALL_BILLING_TRANSACTIONS =
  "RECEIVED_ALL_BILLING_TRANSACTIONS";
export const OPEN_BILLING_PAGE_SNACKBAR = "OPEN_BILLING_PAGE_SNACKBAR";
export const HIDE_BILLING_PAGE_SNACKBAR = "HIDE_BILLING_PAGE_SNACKBAR";
export const SET_BILLING_FETCHING_STATE = "SET_BILLING_FETCHING_STATE";

export const REQUEST_PAYMENT_CARDS = "REQUEST_PAYMENT_CARDS";
export const RECEIVE_PAYMENT_CARDS = "RECEIVE_PAYMENT_CARDS";
export const REQUEST_PAYMENT_CHARGES = "REQUEST_PAYMENT_CHARGES";
export const RECEIVE_PAYMENT_CHARGES = "RECEIVE_PAYMENT_CHARGES";
export const REQUEST_PAYMENT_REFUND = "REQUEST_PAYMENT_REFUND";
export const RECEIVE_PAYMENT_REFUND = "RECEIVE_PAYMENT_REFUND";
export const TOGGLE_CHARGE = "TOGGLE_CHARGE";
export const PAYMENTS_TOGGLE_CONFIRM_DELETE = "PAYMENTS_TOGGLE_CONFIRM_DELETE";
export const PAYMENTS_SET_CARD_DELETE = "PAYMENTS_SET_CARD_DELETE";
export const PAYMENTS_SET_ERROR = "PAYMENTS_SET_ERROR";
export const PAYMENTS_CLEAR_ERROR = "PAYMENTS_CLEAR_ERROR";
export const PAYMENTS_INITIALIZED = "PAYMENTS_INITIALIZED";

export const RECEIVE_STRIPE_ACCOUNT_ID = "RECEIVE_STRIPE_ACCOUNT_ID";

export const REQUEST_SAVINGS = "REQUEST_SAVINGS";
export const RECEIVE_SAVINGS = "RECEIVE_SAVINGS";

export const METER_LOOKUP_UPDATED = "METER_LOOKUP_UPDATED";
export const REQUEST_METER_LOOKUP = "REQUEST_METER_LOOKUP";
export const REQUEST_METER_LOOKUP_SUCCESS = "REQUEST_METER_LOOKUP_SUCCESS";
export const REQUEST_METER_LOOKUP_FAILURE = "REQUEST_METER_LOOKUP_FAILURE";
export const METER_LOOKUP_UPDATE_SUCCESS = "METER_LOOKUP_UPDATE_SUCCESS";
export const METER_LOOKUP_UPDATE_FAILURE = "METER_LOOKUP_UPDATE_FAILURE";
export const HIDE_METER_LOOKUP_SNACKBAR = "HIDE_METER_LOOKUP_SNACKBAR";

export const REQUEST_METERS = "REQUEST_METERS";
export const RECEIVE_METERS = "RECEIVE_METERS";

export const REQUEST_METER = "REQUEST_METER";
export const RECEIVE_METER = "RECEIVE_METER";

export const REQUEST_PRICES = "REQUEST_PRICES";
export const RECEIVE_PRICES = "RECEIVE_PRICES";

export const REQUEST_USAGE = "REQUEST_USAGE";
export const RECEIVE_USAGE = "RECEIVE_USAGE";

export const REQUEST_RATE_CLASSES = "REQUEST_RATE_CLASSES";
export const RECEIVE_RATE_CLASSES = "RECEIVE_RATE_CLASSES";

export const REQUEST_UPDATE_GRIDDY_RATE_CLASS = "REQUEST_GRIDDY_RATE_CLASS";
export const RECEIVE_UPDATE_GRIDDY_RATE_CLASS = "RECEIVE_GRIDDY_RATE_CLASS";

export const REQUEST_OVERRIDE_RATE_CLASS = "REQUEST_OVERRIDE_RATE_CLASS";
export const RECEIVE_OVERRIDE_RATE_CLASS = "RECEIVE_OVERRIDE_RATE_CLASS";

export const REQUEST_REMOVE_OVERRIDE = "REQUEST_REMOVE_OVERRIDE";
export const RECEIVE_REMOVE_OVERRIDE = "RECEIVE_REMOVE_OVERRIDE";

export const REQUEST_AVG_MONTHLY_USAGE = "REQUEST_AVG_MONTHLY_USAGE";
export const RECEIVE_AVG_MONTHLY_USAGE = "RECEIVE_AVG_MONTHLY_USAGE";

export const SET_CURRENT_PROMO = "SET_CURRENT_PROMO";
export const CURRENT_PROMO_SELECTED = "CURRENT_PROMO_SELECTED";
export const CURRENT_PROMO_SELECTED_SUCCESS = "CURRENT_PROMO_SELECTED_SUCCESS";
export const REQUEST_PROMO_GET = "REQUEST_PROMO_GET";
export const REQUEST_PROMOS_GET_ALL = "REQUEST_PROMOS_GET_ALL";
export const RECEIVE_PROMOS_SUCCESS = "RECEIVE_PROMOS_SUCCESS";
export const RECEIVE_PROMOS_FAILURE = "RECEIVE_PROMOS_FAILURE";
export const PROMO_CREATE_REQUEST = "PROMO_CREATE_REQUEST";
export const PROMO_CREATE_SUCCESS = "PROMO_CREATE_SUCCESS";
export const PROMO_CREATE_FAILURE = "PROMO_CREATE_FAILURE";
export const PROMO_UPDATE_REQUEST = "PROMO_UPDATED_REQUEST";
export const PROMO_UPDATE_SUCCESS = "PROMO_UPDATED_SUCCESS";
export const PROMO_UPDATE_FAILURE = "PROMO_UPDATED_FAILURE";
export const HIDE_PROMO_SNACKBAR = "HIDE_PROMO_SNACKBAR";
export const CLEAR_PROMO_SEARCH_RESULTS = "CLEAR_PROMO_SEARCH_RESULTS";

export const PROMO_CREATED = "PROMO_CREATED";
export const PROMO_UPDATED = "PROMO_UPDATED";

export const MEMBER_NOTES_GET_SUCCESS = "MEMBER_NOTES_GET_SUCCESS";
export const MEMBER_NOTES_GET_FAILURE = "MEMBER_NOTES_GET_FAILURE";

export const MEMBER_NOTE_CREATE_REQUEST = "MEMBER_NOTE_CREATE_REQUEST";
export const MEMBER_NOTE_UPDATE_REQUEST = "MEMBER_NOTE_UPDATE_REQUEST";
export const MEMBER_NOTE_CREATE_SUCCESS = "MEMBER_NOTES_CREATE_SUCCESS";
export const MEMBER_NOTE_UPDATE_SUCCESS = "MEMBER_NOTES_UPDATE_SUCCESS";
export const MEMBER_NOTE_CREATE_FAILURE = "MEMBER_NOTES_CREATE_FAILURE";
export const MEMBER_NOTE_UPDATE_FAILURE = "MEMBER_NOTES_UPDATE_FAILURE";
export const MEMBER_NOTE_CREATED = "MEMBER_NOTES_CREATED";
export const MEMBER_RESET_REQUEST = "MEMBER_RESET_REQUEST";
export const MEMBER_RESET_SUCCESS = "MEMBER_RESET_SUCCESS";
export const MEMBER_RESET_FAIL = "MEMBER_RESET_FAIL";

// API ENDPOINTS
export const MEMBER_NOTES_GET_URL = "/members/notes";

export const MEMBER_NOTE_CREATE_URL = "/members/note/create";
export const MEMBER_NOTE_UPDATE_URL = "/members/note/update";

export const GET_USER_URL = "/users";
export const LOGIN = "/users/signin";
export const USER_SEARCH_URL = "/users/search";
export const USER_UPDATE = "/users/update";
export const USER_ACTIVATE = "/users/activate";
export const USER_DEACTIVATE = "/users/deactivate";

export const SIGNUP_UPDATE_FORM = "/signup/UPDATE_SIGNUP_FORM";
export const SIGNUP_LOAD_USER = "/signup/SIGNUP_LOAD_USER";
export const SIGNUP_RESET_SUCCESS = "/signup/SIGNUP_RESET_SUCCESS";
export const SIGNUP_LOAD_MEMBER = "/signup/SIGNUP_LOAD_MEMBER";
export const SIGNUP_CLOSE_SNACKBAR = "/signup/SIGNUP_CLOSE_SNACKBAR";
export const SIGNUP_SET_ERROR_MESSAGE = "/signup/SIGNUP_SET_ERROR_MESSAGE";
export const SIGNUP_SET_SUCCESS_MESSAGE = "/signup/SIGNUP_SET_SUCCESS_MESSAGE";

export const SIGNUP_METER_ADDRESS_SEACH_SUCCESS =
  "/signup/SIGNUP_METER_ADDRESS_SEACH_SUCCESS";
export const SIGNUP_SET_SELECTED_ADDRESS =
  "/signup/SIGNUP_SET_SELECTED_ADDRESS";
export const SIGNUP_CREATE_MEMBER_SUCCESS =
  "/signup/SIGNUP_CREATE_MEMBER_SUCCESS";
export const SIGNUP_CLEAR_FORM = "/signup/SIGNUP_CLEAR_FORM";
export const SIGNUP_USER_URL = "/users/signup";
export const SIGNUP_RESET_PASSWORD_URL = "/users/password/newcustomer";

export const MEMBER_SEARCH_URL = "/members/search";
export const MEMBER_GET_URL = "/members/get";
export const MEMBER_GET_ALL = "/members/get_all";
export const MEMBER_UPDATE = "/members/update";
export const MEMBER_CREATE = "/members/create";

export const GUEST_GET_ALL = "/griddyguest/get_all";

export const METER_SEARCH_URL = "/meter/get_meter";
export const GUEST_METER_SEARCH_URL = "/griddyguest/get_meter";
export const ADD_METER = "/meter/meters";
export const METER_REPORT = "/meter/active_meters_report";
export const METER_RATE_CLASSES = "/meter/griddy_rate_classes";
export const METER_UPDATE_RATE_CLASS = "/meter/update_grc";
export const METER_OVERRIDE_RATE_CLASS = "/meter/set_grc_override";
export const METER_REMOVE_OVERRIDE = "/meter/remove_grc_override";
export const METER_MONTHLY_AVG_USAGE = "/meter/get_amu";
export const METER_SET_TAX_EXEMPTION = "/meter/set_tax_exemption";
export const METER_UPDATE = "/meter/update";

export const METER_TRANSACTIONS = "/meter/get_transaction_list";
export const METER_TRANSACTIONS_UPDATE_URL = "/meter/update_transaction";
export const METER_TRANSACTIONS_PROCESS_URL = "/meter/process_transaction";
export const METER_TRANSACTION_REGISTER_URL = "/meter/register_transaction";
export const METERE_TRANSACTION_CREATE_URL =
  "/meter/make_new_outbound_transaction";
export const METER_TRANSACTION_LOAD_TRANSACTIONS =
  "METER_TRANSACTION_LOAD_TRANSACTIONS";
export const METER_TRANSACTION_SET_SUCCESS_MESSAGE =
  "METER_TRANSACTION_SET_SUCCESS_MESSAGE";
export const METER_TRANSACTION_SET_ERROR_MESSAGE =
  "METER_TRANSACTION_SET_ERROR_MESSAGE";
export const METER_TRANSACTION_OPEN_SNACKBAR =
  "METER_TRANSACTION_OPEN_SNACKBAR";
export const METER_TRANSACTION_CLOSE_SNACKBAR =
  "METER_TRANSACTION_CLOSE_SNACKBAR";
export const METER_TRANSACTION_SELECT_TRANSACTION =
  "METER_TRANSACTION_SELECT_TRANSACTION";
export const METER_TRANSACTION_LOAD_DATA = "METER_TRANSACTION_LOAD_DATA";
export const METER_TRANSACTIONS_UPDATE = "METER_TRANSACTIONS_UPDATE";
export const METER_TRANSACTIONS_UPDATE_STATE =
  "METER_TRANSACTIONS_UPDATE_STATE";
export const METER_TRANSACTIONS_TOGGLE_PROCESSING =
  "METER_TRANSACTIONS_TOGGLE_PROCESSING";
export const METER_TRANSACTIONS_NEW_UPDATE = "METER_TRANSACTIONS_NEW_UPDATE";
export const METER_TRANSACTIONS_NEW_UPDATE_DETAIL =
  "METER_TRANSACTIONS_NEW_UPDATE_DETAIL";
export const METER_TRANSACTION_ADD_TRANSACTION =
  "METER_TRANSACTION_ADD_TRANSACTION";
export const METER_TRANSACTION_MERGE_SELECTED =
  "METER_TRANSACTION_MERGE_SELECTED";

export const BILLING_ACCOUNT = "/billing/account";
export const BILLING_REFUND = "/billing/refund";
export const BILLING_ADDFUNDS = "/billing/addfunds";
export const BILLING_ACTIVATE = "/billing/activate";
export const BILLING_DEACTIVATE = "/billing/deactivate";
export const ERCOT_INVOICE = "/billing/ercot_invoices_report";
export const TDSP_INVOICE = "/billing/tdsp_invoices_report";
export const EDF_TRADES_URL = "/analytics/get_edf_trades";
export const EDF_TRADES_CREATE_URL = "/analytics/set_edf_trade";
export const BILLING_SUMMARY = "/billing/summary";
export const BILLING_SUMMARY_DETAILS = "/billing/summary/details";
export const BILLING_SUMMARY_USAGE_DETAILS = "/billing/summary/details/daily";
export const BILLING_ACCOUNT_DEPOSITS = "/billing/get_account_deposits";
export const BILLING_TRANSACTIONS = "/billing/get_billing_transactions";
export const BILLING_USAGE_REPORT = "/billing/usage_and_cost_report";
export const BILLING_CREDIT = "/billing/credit";
export const GET_ACCEPTED_TYPE_SUBTYPE_PAIRS =
  "/billing/get_accepted_type_subtype_pairs";

export const METER_LOOKUP = "/meter-lookup/get";
export const METER_SEARCH = "/meter-lookup/search";
export const METER_LOOKUP_UPDATE = "/meter-lookup/update";

export const GET_METER_LIST = "/meter/list";

export const PAYMENT_LIST_CHARGES = "/payment/list_charge";
export const PAYMENT_LIST_CARDS = "/payment/list_cards";
export const PAYMENT_REFUND = "/payment/refund";
export const PAYMENT_ENROLL = "/payment/enroll";
export const PAYMENT_ADD_CARD = "/payment/add_card";
export const PAYMENT_REMOVE_CARD = "/payment/remove_card";

export const GET_PRICES = "/insights/getnow";

export const GET_USAGE = "/analytics/history";
export const ANALYTICS_SAVINGS = "/analytics/savings";
export const GUEST_GET_USAGE = "/griddyguest/analytics/history";
export const GUEST_ANALYTICS_SAVINGS = "/griddyguest/analytics/savings";

export const PROMO_GET = "/promo/get";
export const PROMO_GET_ALL_URL = "/promo/get_all";
export const PROMO_CREATE_URL = "/promo/create";
export const PROMO_UPDATE_URL = "/promo/update";

export const TDSP_LOOKUP_DICT = {
  "007924772": "AEP CENTRAL",
  "007923311": "AEP NORTH",
  957877905: "CENTERPOINT ENERGY",
  "0088288574800": "NUECES",
  1039940674000: "ONCOR ELECTRIC DELIVERY",
  1052623364500: "SHARYLAND",
  105262336: "SHARYLAND MCALLEN",
  "007929441": "TNMP",
};

export const DNP_RNP_TRANSACTIONS_REQUEST_URL =
  "/meter/get_dnp_rnp_transactions";
export const DNP_FETCH_CANDIDATES_URL = "/billing/accounts";
export const DNP_DISCONNECT_URL = "/meter/disconnect_non_pay";
export const DNP_RECONNECT_URL = "/meter/reconnect_non_pay";
export const DNP_LOAD_CANDIDATES = "DNP_LOAD_CANDIDATES";
export const DNP_LOAD_PENDING_TRANSACTIONS = "DNP_LOAD_PENDING_TRANSACTIONS";
export const DNP_UPDATE_CANDIDATE = "DNP_UPDATE_CANDIDATE";
export const DNP_TOGGLE_CANDIDATE_SELECT = "DNP_TOGGLE_CANDIDATE_SELECT";
export const DNP_TOGGLE_CANDIDATES_SELECT_ALL =
  "DNP_TOGGLE_CANDIDATES_SELECT_ALL";
export const DNP_TOGGLE_INPROGRESS = "DNP_TOGGLE_INPROGRESS";
export const DNP_TOGGLE_SUCCESS = "DNP_TOGGLE_SUCCESS";
export const DNP_SET_SUCCESS_MESSAGE = "DNP_SET_SUCCESS_MESSAGE";
export const DNP_SET_ERROR_MESSAGE = "DNP_SET_ERROR_MESSAGE";
export const DNP_SET_METER_ERROR = "DNP_SET_METER_ERROR";
export const DNP_CLEAR_ALL_ERRORS = "DNP_CLEAR_ALL_ERRORS";
export const DNP_TOGGLE_LOADING = "DNP_TOGGLE_LOADING";

export const DNP_RNP_LOAD_TRANSACTIONS = "DNP_RNP_LOAD_TRANSACTIONS";
export const DNP_RNP_UPDATE_FILTERS = "DNP_RNP_UPDATE_FILTERS";
export const DNP_RNP_CLOSE_SNACKBAR = "DNP_RNP_CLOSE_SNACKBAR";
export const DNP_RNP_OPEN_SNACKBAR = "DNP_RNP_OPEN_SNACKBAR";

export const ACCOUNT_FLAG_FETCH_URL = "/billing/accountflags";
export const ACCOUNT_FLAGS_MAIN_URL = "/billing/account";
export const ACCOUNT_FLAG_TYPES_LOAD = "ACCOUNT_FLAG_TYPES_LOAD";
export const ACCOUNT_FLAGS_LOAD = "ACCOUNT_FLAGS_LOAD";
export const ACCOUNT_FLAGS_ADD = "ACCOUNT_FLAGS_ADD";
export const ACCOUNT_FLAGS_DELETE = "ACCOUNT_FLAGS_DELETE";
export const ACCOUNT_FLAG_DNP_TOGGLE_LOADING =
  "ACCOUNT_FLAG_DNP_TOGGLE_LOADING";
export const ACCOUNT_FLAG_DISMISS_MESSAGE = "ACCOUNT_FLAG_DISMISS_MESSAGE";
export const ACCOUNT_FLAG_SET_SUCCESS = "ACCOUNT_FLAG_SET_SUCCESS";
export const ACCOUNT_FLAG_SET_ERROR = "ACCOUNT_FLAG_SET_ERROR";
export const ACCOUNT_FLAG_TOGGLE_CATEGORY_VIEW =
  "ACCOUNT_FLAG_TOGGLE_CATEGORY_VIEW";
export const ACCOUNT_FLAG_TOGGLE = "ACCOUNT_FLAG_TOGGLE";
export const ACCOUNT_FLAG_CANCEL_CHANGES = "ACCOUNT_FLAG_CANCEL_CHANGES";
export const ACCOUNT_FLAG_TOGGLE_LOADING = "ACCOUNT_FLAG_TOGGLE_LOADING";

export const TAX_EXCEMPTION_SUCCESS = "TAX_EXCEMPTION_SUCCESS";
export const TAX_EXCEMPTIONS = "/tax/get_exemption_types";
export const TAX_OPEN_SNACKBAR = "TAX_OPEN_SNACKBAR";
export const TAX_HIDE_SNACKBAR = "TAX_HIDE_SNACKBAR";

export const ITERABLE_OPEN_SNACKBAR = "ITERABLE_OPEN_SNACKBAR";
export const ITERABLE_CLOSE_SNACKBAR = "ITERABLE_CLOSE_SNACKBAR";
export const ITERABLE_UPDATE_STATE = "ITERABLE_UPDATE_STATE";
export const ITERABLE_REQUEST_SUCCESS = "ITERABLE_REQUEST_SUCCESS";
export const ITERABLE_USER_MESSAGES = "/users/getSentMessages";
export const ITERABLE_USER_INFO = "/user";
export const ITERABLE_USER_EVENTS = "/events";
export const ITERABLE_VIEW_EMAIL_IN_BROWSER = "/emailView";
export const ITERABLE_CHANNELS = "/channels";
export const ITERABLE_GET_EMAIL_TEMPLATE = "/emailTemplate";
export const ITERABLE_CAMPAIGNS = "/campaigns";

export const ITERABLE_UPDATE_USER_INFO = "ITERABLE_UPDATE_USER_INFO";
export const ITERABLE_UPDATE_USER_EVENTS = "ITERABLE_UPDATE_USER_EVENTS";
export const ITERABLE_UPDATE_USER_MESSAGES = "ITERABLE_UPDATE_USER_MESSAGES";
export const ITERABLE_SET_EMAIL_VIEW = "ITERABLE_SET_EMAIL_VIEW";
export const CLEAR_ITERABLE_DATA = "CLEAR_ITERABLE_DATA";
export const ITERABLE_SET_CAMPAIGN_COLLECTION =
  "ITERABLE_SET_CAMPAIGN_COLLECTION";
export const ITERABLE_SET_CHANNEL_COLLECTION =
  "ITERABLE_SET_CHANNEL_COLLECTION";
export const ITERABLE_ADD_TO_TEMPLATES = "ITERABLE_ADD_TO_TEMPLATES";
export const ITERABLE_CLEAR_USER_DATA = "ITERABLE_CLEAR_USER_DATA";
