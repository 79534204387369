import React, { Component } from "react";
import { injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";
import moment from "moment";
import ListItem from "@material-ui/core/ListItem";
import { FormControl } from "@material-ui/core/";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CardElement } from "react-stripe-elements";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { addAdditionalMeter } from "../actions/members";

class CreditCardForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            esiID: "",
            startDate: "",
            enrollType: "movein",
            cardContent: false,
        };
    }

    componentWillReceiveProps(props) {
        if (props.member.getIn(["response", "success"])) {
            this.clearFields();
        }
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleDateChange = (name) => (value) => {
        this.setState({
            [name]: value,
        });
    };

    handleCC = (name) => ({ complete }) => {
        this.setState({
            [name]: complete,
        });
    };

    handleSubmit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();

        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        const { member } = this.props;
        const { enrollType, startDate, esiID } = this.state;

        const name = member.get("first_name") + " " + member.get("last_name");
        const formattedStartDate = moment.utc(startDate).format("yyyy-MM-DD");

        this.props.stripe.createToken({ name: name }).then(({ token }) => {
            this.props.onAddNewMeter(
                esiID,
                formattedStartDate,
                token,
                enrollType,
                member
            );
        });
    };

    isButtonDisabled = () => {
        let isFormValid;
        if (this.state.enrollType === "switch") {
            isFormValid = this.state.esiID !== "" && this.state.cardContent;
        } else {
            isFormValid =
                this.state.esiID !== "" &&
                this.state.cardContent &&
                this.state.startDate !== "";
        }
        return !isFormValid;
    };

    clearFields = () => {
        this.setState({
            esiID: "",
            startDate: "",
            enrollType: "movein",
            cardContent: false,
        });
        // Clear the Element:
        if (this.cardElement) {
            this.cardElement.clear();
        }
    };

    render() {
        return (
            <div style={{ border: "1px solid lightgrey", marginLeft: "25px" }}>
                <h4 style={{ marginLeft: "25px" }}>Add New Meter </h4>
                <ListItem>
                    <TextField
                        style={{ marginRight: "20px", width: "200px" }}
                        label="ESI ID"
                        id="esiID"
                        onChange={this.handleChange("esiID")}
                        value={this.state.esiID}
                        type="string"
                    />
                    <KeyboardDatePicker
                        margin="normal"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        autoOk
                        value={
                            (this.state.startDate &&
                                moment
                                    .utc(this.state.startDate)
                                    .format("l LT")) ||
                            null
                        }
                        onChange={this.handleDateChange("startDate")}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                    />
                </ListItem>
                <div style={{ paddingLeft: "20px", marginTop: "20px" }}>
                    <CardElement
                        style={{ base: { fontSize: "18px", margin: "200px" } }}
                        onChange={this.handleCC("cardContent")}
                        elementRef={(c) => (this.cardElement = c)}
                    />
                </div>
                <ListItem>
                    <FormControl
                        style={{ marginRight: "20px", marginTop: "20px" }}
                    >
                        <InputLabel htmlFor="enroll-type">Type</InputLabel>
                        <Select
                            value={this.state.enrollType}
                            onChange={this.handleChange("enrollType")}
                            inputProps={{
                                name: "enrollType",
                                id: "enroll-type",
                            }}
                        >
                            <MenuItem value="movein">Move In</MenuItem>
                            <MenuItem value="switch">Switch</MenuItem>
                        </Select>
                    </FormControl>
                </ListItem>
                <div style={{ margin: "20px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={this.isButtonDisabled()}
                        onClick={this.handleSubmit}
                    >
                        Add New Meter
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
    onAddNewMeter: (esiID, startDate, token, enrollType, member) =>
        dispatch(
            addAdditionalMeter(esiID, startDate, token, enrollType, member)
        ),
});

const ConnectedCreditCardForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCardForm);

export default injectStripe(ConnectedCreditCardForm);
