import Immutable from "immutable";
import * as constants from "../../constants";

const defualtSavingsData = Immutable.fromJS({});

export default function savingsData(state = defualtSavingsData, action) {
    switch (action.type) {
    case constants.RECEIVE_SAVINGS:
        return Immutable.fromJS({
            data: action.data,
            isFetching: action.isFetching,
        });
    case constants.REQUEST_SAVINGS:
        return Immutable.fromJS({
            isFetching: action.isFetching,
        });
    default:
        return state;
    }
}
