import sha256 from "sha256";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import moment from "moment";

function requestBillingSummary() {
  return {
    type: constants.REQUEST_BILLING_SUMMARY,
    isFetching: true,
  };
}

function requestOnlyBillingTransactions() {
  return {
    type: constants.REQUEST_BILLING_TRANSACTIONS,
  };
}

function receiveBillingSummary(data) {
  return {
    type: constants.RECEIVE_BILLING_SUMMARY,
    isFetching: false,
    data,
  };
}
function requestAdjustment() {
  return {
    type: constants.REQUEST_BILLING_ADJUSTMENT,
    isFetching: true,
  };
}

function recieveAdjustment(response, property, value) {
  if (response.success === true) {
    response.msg = `Success: ${property} updated to ${value}`;
  } else {
    response.msg = response.error || `Error - Updating ${property}`;
  }
  return {
    type: constants.RECEIVE_BILLING_ADJUSTMENT,
    isFetching: false,
    response,
  };
}

function requestBillingUsageReport() {
  return {
    type: constants.REQUEST_BILLING_USAGE_REPORT,
    isFetching: true,
  };
}

function receiveBillingUsageReport(data) {
  return {
    type: constants.RECEIVE_BILLING_USAGE_REPORT,
    isFetching: false,
    data,
  };
}

function formatEnergyObject(obj) {
  const reordered = [];
  const newObj = obj;

  newObj.group_items = obj.group_items || [];
  const titleList = [
    "Energy Generation",
    "Energy Distribution",
    "Taxes and Fees",
    "adjustments",
    "Griddy Membership",
  ];
  for (let i = 0; i < 5; i += 1) {
    const groupItem = {
      amount_dollars: "0.00",
      title: titleList[i],
      line_items: [],
    };

    reordered.push(groupItem);
  }

  for (let j = 0; j < newObj.group_items.length; j += 1) {
    switch (newObj.group_items[j].type) {
      case "wholesale_energy_cost":
        reordered[0] = newObj.group_items[j];
        reordered[0].name = "Energy Generation";
        break;
      case "tdsp_cost":
        reordered[1] = newObj.group_items[j];
        reordered[1].name = "Energy Distribution";
        break;
      case "taxes_fees":
        reordered[2] = newObj.group_items[j];
        reordered[2].name = "Taxes and Fees";
        break;
      case "adjustments":
        reordered[3] = newObj.group_items[j];
        reordered[3].name = "adjustments";
        break;
      case "membership_fee":
        reordered[4] = newObj.group_items[j];
        reordered[4].name = "Griddy Membership";
        break;
      default:
        break;
    }
  }
  return reordered;
}

export function fetchBillingSummary(obj) {
  return (dispatch) => {
    dispatch(requestBillingSummary());
    return fetch(constants.BILLING_SUMMARY, {
      memberID: obj.memberID,
      month: obj.month,
      year: obj.year,
    }).then((data) => {
      const newData = data;
      const formattedObj = formatEnergyObject(data);
      newData.membership = formattedObj[4].line_items[0] || {};
      newData.rechargeList = formattedObj[3].line_items;
      formattedObj.pop();
      formattedObj.pop();
      newData.group_items = formattedObj;
      dispatch(receiveBillingSummary(newData));
      dispatch(requestAcceptedTypeSubtypePairs());
      dispatch(requestBillingTransactions(obj.memberID));
    });
  };
}

// This action is similar to fetchBillingSummary in that it fetches the billing transactions instead of deposits
export function fetchBillingInfo(obj) {
  let data = {
    memberID: obj.memberID,
    month: obj.month,
    year: obj.year,
  };
  return (dispatch) => {
    dispatch(requestBillingSummary());
    return fetch(constants.BILLING_SUMMARY, data)
      .then(async (res) => {
        const newData = res;
        const formattedObj = formatEnergyObject(res);
        newData.membership = formattedObj[4].line_items[0] || {};
        newData.rechargeList = formattedObj[3].line_items;
        formattedObj.pop();
        formattedObj.pop();
        newData.group_items = formattedObj;
        await dispatch(requestAcceptedTypeSubtypePairs());
        return newData;
      })
      .then(async (newData) => {
        await dispatch(requestAllBillingTransactions(obj.memberID));
        try {
          await dispatch(requestBillingSummaryDetails(data));
        } catch (err) {
          dispatch(setFetchingState(false));
        }
        return newData;
      })
      .then((newData) => {
        return dispatch(receiveBillingSummary(newData));
      })
      .catch((err) => {
        dispatch(setFetchingState(false));
      });
  };
}

function setFetchingState(isFetching) {
  return {
    type: constants.SET_BILLING_FETCHING_STATE,
    isFetching,
  };
}

export function requestUsageSummaryByDate(day, month, year, memberID) {
  let data = {
    day: parseInt(day),
    month: parseInt(month),
    year,
    memberID,
  };

  return (dispatch) => {
    dispatch(setFetchingState(true));
    return fetch(constants.BILLING_SUMMARY_USAGE_DETAILS, data)
      .then((res) => {
        if (res.items && res.items.length > 0) {
          const payload = {
            [`${year}-${month}-${day}`]: res?.items,
          };
          dispatch(receiveUsageSummary(payload));
        }
        dispatch(setFetchingState(false));
      })
      .catch(() => {
        dispatch(setFetchingState(false));
      });
  };
}

function receiveUsageSummary(payload) {
  return {
    type: constants.RECEIVE_BILLING_SUMMARY_USAGE_DETAILS,
    payload: payload,
  };
}

function requestBillingSummaryDetails(data) {
  return (dispatch) =>
    fetch(constants.BILLING_SUMMARY_DETAILS, data).then((res) => {
      const payload = {
        chargeDetails: res?.charge_details,
        usageDetails: res?.usage_details,
      };
      dispatch(receiveBillingSummaryDetails(payload));
    });
}

function receiveBillingSummaryDetails(payload) {
  return {
    type: constants.RECEIVE_BILLING_SUMMARY_DETAILS,
    payload: payload,
  };
}

function receivedAcceptedTypeSubtypePairs(payload) {
  return {
    type: constants.RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS,
    payload,
  };
}

function requestAcceptedTypeSubtypePairs() {
  return (dispatch) =>
    fetch(constants.GET_ACCEPTED_TYPE_SUBTYPE_PAIRS).then((res) => {
      dispatch(receivedAcceptedTypeSubtypePairs(res));
    });
}

function receivedBillingTransactions(payload) {
  return {
    type: constants.RECEIVED_BILLING_TRANSACTIONS,
    payload,
  };
}

function receivedAllBillingTransactions(payload) {
  return {
    type: constants.RECEIVED_ALL_BILLING_TRANSACTIONS,
    payload,
  };
}

export function requestBillingTransactions(memberID, page = 1) {
  return (dispatch) =>
    fetch(constants.BILLING_ACCOUNT_DEPOSITS, {
      memberID,
      page,
      limit: 10,
    }).then((res) => {
      dispatch(receivedBillingTransactions(res));
    });
}

export function requestAllBillingTransactions(memberID, page = 1) {
  return (dispatch) =>
    fetch(constants.BILLING_TRANSACTIONS, {
      memberID,
      page,
      limit: 10,
    }).then((res) => {
      dispatch(receivedAllBillingTransactions(res));
    });
}

export const loadMoreBillingTransactions = (memberID, page = 1) => (
  dispatch
) => {
  dispatch(requestOnlyBillingTransactions());
  fetch(constants.BILLING_TRANSACTIONS, {
    memberID,
    page,
    limit: 10,
  }).then((res) => {
    dispatch(receivedAllBillingTransactions(res));
  });
};

export function applyAdjustment(
  userId,
  memberId,
  type,
  subType,
  applyType,
  amount,
  comment
) {
  return (dispatch) => {
    dispatch(requestAdjustment());
    const metaData = {
      notes: comment,
      userID: String(userId),
    };
    const today = new Date().toISOString();
    const multiplier = applyType === "debit" ? -1 : 1;
    const payload = {
      date: today,
      memberID: memberId,
      amount: `${Math.abs(amount) * multiplier}`,
      meta_data: metaData,
    };
    payload.hashID = sha256(JSON.stringify(payload));
    try {
      payload.credit_type = type;
      payload.credit_subtype = subType;
      fetch(constants.BILLING_CREDIT, payload)
        .then((response) => dispatch(recieveAdjustment(response, type, amount)))
        .then(() => {
          const date = moment();
          dispatch(
            fetchBillingInfo({
              month: date.month() + 1,
              year: date.year(),
              memberID: memberId,
            })
          );
        });
    } catch (err) {
      const response = {
        success: false,
        msg: "not credit or debit",
      };
      return dispatch(recieveAdjustment(response, type, amount));
    }
  };
}

export function fetchBillingUsageReport(startDate, endDate) {
  return (dispatch) => {
    dispatch(requestBillingUsageReport());
    return fetch(constants.BILLING_USAGE_REPORT, {
      start_date: startDate,
      end_date: endDate,
    }).then((data) => {
      const newData = data;
      newData.total_usage = Math.round(data.total_usage * 100) / 100;
      newData.total_usage = Number(data.total_usage).toLocaleString();
      dispatch(receiveBillingUsageReport(newData));
    });
  };
}

export function fetchMeterReport() {
  return (dispatch) => {
    dispatch(requestBillingUsageReport());
    return fetch(constants.METER_REPORT, {}).then((data) => {
      dispatch(receiveBillingUsageReport(data));
    });
  };
}

export function hideSnackBar() {
  return {
    type: constants.HIDE_SNACKBAR,
  };
}