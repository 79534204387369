import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Modal from "react-modal";
import modalStyle from "../../styles/modal";

const ServiceAddressInfo = ({
    userID,
    meter_id,
    esi,
    streetAddress,
    streetAddress2,
    postalCode,
    serviceAddresses,
    setServiceAddress,
    lookupServiceAddressMeter,
    handleChange,
}) => {
    return (
        <div>
            {userID && (
                <List>
                    <h4
                        style={{
                            marginLeft: "25px",
                            display: "inline-block",
                        }}
                    >
                        Service Address Information
                    </h4>

                    <ListItem>
                        <TextField
                            name="streetAddress"
                            label="Street Address"
                            onChange={handleChange}
                            value={streetAddress}
                            type="string"
                            style={{ width: "100%" }}
                        />
                    </ListItem>
                    <ListItem>ESI: {esi}</ListItem>
                </List>
            )}
            {userID && !meter_id && !esi && (
                <div>
                    <List>
                        <ListItem>
                            <TextField
                                name="streetAddress2"
                                label="Apt #"
                                onChange={handleChange}
                                value={streetAddress2}
                                type="string"
                            />
                            <TextField
                                name="postalCode"
                                label="Postal Code"
                                onChange={handleChange}
                                value={postalCode}
                                type="string"
                                style={{ marginLeft: 20 }}
                            />
                        </ListItem>
                    </List>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            lookupServiceAddressMeter();
                        }}
                        style={{ marginLeft: 20 }}
                    >
                        Search Address
                    </Button>
                </div>
            )}
            {serviceAddresses && serviceAddresses.length > 0 && (
                <div>
                    <Modal
                        isOpen={true}
                        style={modalStyle.serviceAddressListModal}
                    >
                        <h4 style={{ marginTop: -25 }}>
                            SELECT CUSTOMER ADDRESS
                        </h4>
                        {serviceAddresses.slice(0, 4).map((sa, i) => (
                            <List key={i}>
                                <ListItem>
                                    <span>
                                        Address: {sa.address.line1}
                                        {sa.address.line2 &&
                                            `, ${sa.address.line2}`}
                                        <br />
                                        {sa.address.city}, {sa.address.state}{" "}
                                        {sa.address.postal_code}
                                        <br />
                                        ESI ID:{sa.esi}
                                        <br />
                                    </span>

                                    <Button
                                        onClick={() => {
                                            setServiceAddress(i);
                                        }}
                                        color="secondary"
                                        variant="contained"
                                        key={sa.esi}
                                        name="esi"
                                        value={sa.esi}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            marginTop: 10,
                                        }}
                                    >
                                        Select Address
                                    </Button>
                                </ListItem>
                            </List>
                        ))}
                        <Button
                            onClick={() => {
                                setServiceAddress();
                            }}
                            color="primary"
                            variant="contained"
                        >
                            Close
                        </Button>
                    </Modal>
                </div>
            )}
        </div>
    );
};

ServiceAddressInfo.propTypes = {
    birthday: PropTypes.any,
    first_name: PropTypes.any,
    handleChange: PropTypes.any,
    last_name: PropTypes.any,
    memberID: PropTypes.any,
    phone: PropTypes.any,
    userID: PropTypes.any,
};

export default ServiceAddressInfo;
