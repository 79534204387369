import React from "react";
import { connect } from "react-redux";
import UserNotes from "../components/UserNotes";
import {
  fetchUserIDWithFullMemberInfoNeeded,
  fetchFullMemberInfoNeeded,
} from "../actions/search";

import selectedMemberNotes from "../selectors/selectedMember";

const UserNotesContainer = (props) => <UserNotes {...props} />;

const mapStateToProps = selectedMemberNotes();

const mapDispatchToProps = (dispatch) => ({
  onfetchFullMemberInfoNeeded: (member) =>
    dispatch(fetchFullMemberInfoNeeded(member)),
  onFetchUserIDWithFullMemberInfoNeeded: (member) =>
    dispatch(fetchUserIDWithFullMemberInfoNeeded(member)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserNotesContainer);
