import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { fetchPromos, clearPromosSearchResults } from "../actions/promos";
import { selectPromoSearchResults } from "../selectors/promos";

const styles = {
    container: {
        padding: "0 15px",
    },
    orSeperator: {
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: "16px",
        fontStyle: "italic",
    },
};

class PromoSearch extends Component {
    componentDidMount() {
        this.props.fetchPromos();
    }

    componentWillUnmount() {
        this.props.clearPromosSearchResults();
    }

    render() {
        const { promoSearchResults, classes } = this.props;

        return (
            <div className={classes.container}>
                <h4>Promos</h4>
                <div>
                    {promoSearchResults.map((promo) => (
                        <div key={promo.code}>
                            <Link to={`promo/${promo.code}`}>{promo.code}</Link>
                        </div>
                    ))}
                </div>
                <h4 className={classes.orSeperator}>or...</h4>
                <Link to="/promo/new">New promo</Link>
            </div>
        );
    }
}

PromoSearch.propTypes = {
    promoSearchResults: PropTypes.array,
    fetchPromos: PropTypes.func.isRequired,
    clearPromosSearchResults: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

PromoSearch.defaultProps = {
    promoSearchResults: [],
};

const mapState = createStructuredSelector({
    promoSearchResults: selectPromoSearchResults,
});

const mapDispatch = {
    fetchPromos,
    clearPromosSearchResults,
};

export default withStyles(styles)(connect(mapState, mapDispatch)(PromoSearch));
