import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import { noselect } from "../../styles/common.css";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  adjustmentForm: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const ManualAdjustmentPanel = (props) => {
  const classes = useStyles();
  const [commentFocus, setCommentFocus] = useState(false);
  const {
    authData,
    params,
    doApplyAdjustment,
    onNoteCreate,
    closeManualAdjustmentPopup,
  } = props;

  const [amount, setAmount] = useState("");
  const [adjustmentType, setAdjustmentType] = useState("");
  const [adjustmentSubType, setAdjustmentSubType] = useState("");
  const [applyType, setApplyType] = useState("credit");
  const [userComment, setUserComment] = useState("");
  const [acceptedPairs, setAcceptedPairs] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    const acceptedPairsFiltered = props.acceptedPairs
      .get("pairs")
      .filter((el) => el.get("type_name") !== "adjustments");
    setAcceptedPairs(acceptedPairsFiltered);
  }, [props.acceptedPairs]);

  useEffect(() => {
    if (openPopup) {
      if (
        adjustmentType &&
        adjustmentSubType &&
        amount &&
        applyType &&
        userComment !== ""
      ) {
        setFormError(false);
      } else {
        setFormError(true);
      }
    }
  }, [openPopup]);

  const handleApplyTypeChange = (e) => {
    setApplyType(e.target.value);
    setAdjustmentSubType("");
    setAdjustmentType("");
  };
  const handleAcceptedPairChange = (e) => {
    let [adjustmentType, adjustmentSubType] = e.target.value.split("&split&");
    setAdjustmentSubType(adjustmentSubType);
    setAdjustmentType(adjustmentType);
  };
  const handleAmountChange = (e) => {
    const amount = Math.abs(e.target.value);
    setAmount(amount);
  };

  const handleChange = (e) => {
    setUserComment(e.target.value);
  };
  const clearForm = () => {
    setUserComment("");
    setAmount("");
    setAdjustmentType("");
    setAdjustmentSubType("");
    setApplyType("credit");
  };
  const submitForm = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleConfirm = () => {
    if (!formError) {
      const body = `Applying ${applyType} of $${amount} for ${adjustmentType} ${adjustmentSubType}\n\n${userComment}`;

      doApplyAdjustment(
        authData.userID,
        params.memberId,
        adjustmentType,
        adjustmentSubType,
        applyType,
        amount,
        userComment
      );

      onNoteCreate(
        Number(authData.userID),
        authData.email,
        authData.userID,
        params.memberId,
        body
      );

      setOpenPopup(false);
      closeManualAdjustmentPopup(false);
      clearForm();
    }
  };
  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Manual Adjustment</span>
          <CloseIcon onClick={() => closeManualAdjustmentPopup()} />
        </div>
        <div className={classes.adjustmentForm}>
          <FormControl style={{ marginRight: "20px" }}>
            <InputLabel htmlFor="adjustment-type">Apply</InputLabel>
            <Select
              value={applyType}
              onChange={(e) => handleApplyTypeChange(e)}
              inputProps={{
                name: "applyType",
                id: "apply-type",
              }}
              style={{ width: "100px" }}
            >
              <MenuItem value="debit">Debit</MenuItem>
              <MenuItem value="credit">Credit</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ marginRight: "20px" }}>
            <InputLabel htmlFor="adjustment-type">Type</InputLabel>
            <Select
              value={
                adjustmentType !== "" && adjustmentSubType !== ""
                  ? `${adjustmentType}&split&${adjustmentSubType}`
                  : ""
              }
              onChange={(e) => handleAcceptedPairChange(e)}
              inputProps={{
                name: "adjustmentType",
                id: "adjustment-type",
              }}
              style={{ width: "190px" }}
            >
              <MenuItem
                key={"adjustments"}
                value={`adjustments&split&${applyType}`}
              >
                Adjustment:
                {applyType}
              </MenuItem>
              {acceptedPairs.map((pair) => {
                return (
                  <MenuItem
                    key={`${pair.get("type_description")}-${pair.get(
                      "subtype_description"
                    )}`}
                    value={`${pair.get("type_name")}&split&${pair.get(
                      "subtype_name"
                    )}`}
                  >
                    {pair.get("type_description")}:{" "}
                    {pair.get("subtype_description")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label="Amount"
            id="amount"
            onChange={(e) => handleAmountChange(e)}
            value={amount}
            inputProps={{ min: 0 }}
            type="number"
          />
        </div>
        <div className={classes.textAreaContainer}>
          <textarea
            onFocus={() => setCommentFocus(true)}
            onBlur={() => setCommentFocus(false)}
            className={classes.textArea}
            value={userComment}
            placeholder={"Comments ..."}
            onChange={(e) => handleChange(e)}
            autoFocus
            id="adjustment-note"
            style={{ height: "100%", border: "none", overflowY: "auto" }}
          />
        </div>
        <div
          className={classes.optionContainer}
          style={{
            borderTop: commentFocus ? "2px solid #3f51b5" : "2px solid #ddd",
          }}
        >
          <Button color="secondary" varian="raised" onClick={() => clearForm()}>
            Clear
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={() => submitForm()}
          >
            Apply
          </Button>
        </div>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        <DialogTitle
          className={classes.confirmDialog}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {formError && (
            <WarningIcon style={{ color: "gold", marginRight: "10px" }} />
          )}
          {!formError ? (
            <span>Please Confirm Transaction</span>
          ) : (
            <span>Warning</span>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.contentFullView}>
            {!formError ? (
              <div>
                {`Applying ${applyType} of $${amount} for ${adjustmentType} ${adjustmentSubType}`}
              </div>
            ) : (
              <div>
                Please complete every field of the form, including the comments.
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="secondary">
            Close
          </Button>
          <Button
            className={
              !formError ? classes.enableButton : classes.disableButton
            }
            autoFocus
            onClick={handleConfirm}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManualAdjustmentPanel;
