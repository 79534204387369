import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Workbook from "react-excel-workbook";
import { KeyboardDatePicker } from "@material-ui/pickers";
import fetchErcotInvoice from "../actions/ercot";

class Ercot extends Component {
    constructor(props) {
        super(props);
        this.startDate = moment().subtract(1, "months");
        this.endDate = moment();
    }

    handleChangeForStartDate = (date) => {
        this.startDate = moment(date);
        this.forceUpdate();
    };
    handleChangeForEndDate = (date) => {
        this.endDate = moment(date);
        this.forceUpdate();
    };

    pickerStyle = {
        marginRight: 10,
    };

    buttonStyle = {
        marginTop: 5,
        marginRight: 10,
    };
    render() {
        const { rows, dofetchErcotInvoice } = this.props;

        const columns = [
            {
                Header: "Operating Day",
                accessor: "operating_date",
                Cell: (row) => (
                    <div>
                        {moment.tz(row.value, "UTC").format("MMM DD, h:mm a")}
                    </div>
                ),
            },
            {
                Header: "Energy Shadow",
                accessor: "energy_shadow",
                Cell: (row) => <div>${row.value ? row.value : 0}</div>,
            },
            {
                Header: "RTM Invoice Amount",
                id: "rtm_invoice",
                accessor: (d) => d.invoice_amounts.rtm_invoice,
                Cell: (row) => <div>${row.value ? row.value : 0}</div>,
            },
        ];

        return (
            <div>
                <KeyboardDatePicker
                    margin="normal"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={
                        (this.startDate &&
                            moment.utc(this.startDate).format("l LT")) ||
                        null
                    }
                    onChange={this.handleChangeForStartDate}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    style={this.pickerStyle}
                />
                <KeyboardDatePicker
                    margin="normal"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={
                        (this.endDate &&
                            moment.utc(this.endDate).format("l LT")) ||
                        null
                    }
                    onChange={this.handleChangeForEndDate}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    style={this.pickerStyle}
                />
                <button
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                    onClick={() =>
                        dofetchErcotInvoice(
                            this.startDate.format(),
                            this.endDate.format()
                        )
                    }
                    style={this.buttonStyle}
                >
                    {"Show"}
                </button>
                <Workbook
                    filename="ercot_charges.xlsx"
                    element={
                        <button
                            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                            style={this.buttonStyle}
                        >
                            {"Export"}
                        </button>
                    }
                >
                    <Workbook.Sheet data={rows} name="TDSP">
                        <Workbook.Column
                            label="Operating Day"
                            value={(row) =>
                                row.operating_date.replace("T", " ")
                            }
                        />
                        <Workbook.Column
                            label="Energy Shadow"
                            value="energy_shadow"
                        />
                        <Workbook.Column
                            label="RTM Invoice Amount"
                            value={(row) => row.invoice_amounts.rtm_invoice}
                        />
                    </Workbook.Sheet>
                </Workbook>
                <ReactTable
                    data={rows}
                    columns={columns}
                    style={{ height: "600px", width: "600px" }}
                    className="-striped -highlight"
                    filterable
                    noDataText=""
                    defaultPageSize={100}
                />
            </div>
        );
    }
}

Ercot.propTypes = {
    rows: PropTypes.object,
    dofetchErcotInvoice: PropTypes.func.isRequired,
};

Ercot.defaultProps = {
    rows: [],
};

const mapStateToProps = (state) => ({
    rows: state.ercotResult,
});

const mapDispatchToProps = (dispatch) => ({
    dofetchErcotInvoice: (s, e) => {
        dispatch(fetchErcotInvoice(s, e));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Ercot);
