import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { ClipLoader } from "react-spinners";
import { MenuItem } from "@material-ui/core/Menu";

const styles = (theme) => ({
    list: {
        width: "100%",
    },
});

const mapStateToProps = (state) => ({
    guest: state.guest,
});

function GuestDetails(props) {
    const { guest } = props;

    return (
        <Grid item xs={6} md={3}>
            <h4 style={{ marginLeft: "25px" }}>Guest account information</h4>
            <List className={props.classes.list}>
                <ListItem divider>
                    <ListItemText primary="Email: " />
                    {guest.get("email")}
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="UserId: " />
                    {props.params.guestUserId}
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="MemberId: " />
                    {guest.get("memberID")}
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Zip code: " />
                    {guest.get("zip_code")}
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Load zone: " />
                    {guest.get("settlement_point")}
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Out of area: " />
                    {guest.get("provided_zip_code") ? "Yes" : "No"}
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Home type: " />
                    {guest.get("home_size")}
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Contract experation: " />
                    {guest.get("contract_end_date")}
                </ListItem>
            </List>
        </Grid>
    );
}

GuestDetails.propTypes = {
    guest: PropTypes.object,
};

GuestDetails.defaultProps = {
    guest: {},
};

const ConnectedGuestDetails = connect(mapStateToProps)(GuestDetails);

export default withStyles(styles)(ConnectedGuestDetails);
