import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ClipLoader } from "react-spinners";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker } from "@material-ui/pickers";

const TextItemTypeControl = ({
    recordSequence,
    transaction,
    recordType,
    recordKey,
    index,
    updateNewTransaction,
    validateTransactionField,
}) => (
    <TextField
        label={recordType.label}
        type="text"
        size="small"
        color="primary"
        value={transaction[recordType.label]}
        error={transaction[`${recordType.label}Error`]}
        helperText={transaction[`${recordType.label}Error`]}
        width="100%"
        disabled={!recordType.is_editable}
        InputLabelProps={{
            shrink: true,
        }}
        variant="outlined"
        required={recordType.is_required}
        onChange={(e) => {
            updateNewTransaction(recordKey, index, {
                [recordType.label]: e.target.value,
            });
        }}
        onBlur={(e) => {
            validateTransactionField(
                recordKey,
                recordSequence.record_indicator,
                index,
                recordType.label,
                e.target.value
            );
        }}
    />
);

const DateItemTypeControl = ({
    recordSequence,
    transaction,
    recordType,
    recordKey,
    index,
    updateNewTransaction,
    validateTransactionField,
}) => (
    <KeyboardDatePicker
        label={recordType.label}
        inputVariant="outlined"
        margin="normal"
        format="yyyyMMdd"
        placeholder="yyyyMMdd"
        error={transaction[`${recordType.label}Error`]}
        helperText={transaction[`${recordType.label}Error`]}
        required={recordType.is_required}
        autoOk
        size="small"
        value={moment(transaction[recordType.label], "YYYYMMDD")}
        onChange={(e) => {
            updateNewTransaction(recordKey, index, {
                [recordType.label]: moment(e).format("YYYYMMDD"),
            });
        }}
        onBlur={() => {
            validateTransactionField(
                recordKey,
                recordSequence.record_indicator,
                index,
                recordType.label,
                transaction[recordType.label]
            );
        }}
        InputLabelProps={{
            shrink: true,
            variant: "outlined",
        }}
        KeyboardButtonProps={{
            "aria-label": "change date",
        }}
    />
);

const SelectItemTypeControl = ({
    recordSequence,
    transaction,
    recordType,
    recordKey,
    index,
    updateNewTransaction,
    validateTransactionField,
}) => (
    <TextField
        select
        size="small"
        variant="outlined"
        label={recordType.label}
        style={{
            width: "100%",
        }}
        value={transaction[recordType.label]}
        error={transaction[`${recordType.label}Error`]}
        helperText={transaction[`${recordType.label}Error`]}
        required={recordType.is_required}
        onChange={(e) => {
            updateNewTransaction(recordKey, index, {
                [recordType.label]: e.target.value,
            });
        }}
        onBlur={(e) => {
            validateTransactionField(
                recordKey,
                recordSequence.record_indicator,
                index,
                recordType.label,
                e.target.value
            );
        }}
        InputLabelProps={{
            shrink: true,
        }}
        disabled={!recordType.is_editable}
    >
        <MenuItem value="">&nbsp;</MenuItem>
        {recordType.options.map((o, i) => (
            <MenuItem
                value={typeof o === "object" ? Object.keys(o)[0] : o}
                key={i}
            >
                {typeof o === "object"
                    ? `${Object.keys(o)[0]} ${
                          Object.values(o)[0] && `- ${Object.values(o)[0]}`
                      }`
                    : o}
            </MenuItem>
        ))}
    </TextField>
);

const RecordInfoItems = ({
    index,
    recordKey,
    classes,
    recordTypes,
    recordSequence,
    recordOverridePart,
    transaction,
    updateNewTransaction,
    validateTransactionField,
}) => (
    <Container fixed>
        <Grid container>
            {Object.values(recordTypes[Number(recordSequence.record_indicator)])
                .map((f) => {
                    const { is_visible, is_editable, is_required, options } =
                        recordOverridePart.find((o) => o.label === f.label) ||
                        {};
                    return {
                        ...f,
                        is_visible: is_visible || f.is_visible,
                        is_editable: is_editable || f.is_editable,
                        is_required: is_required || f.is_required,
                        options: options || f.options,
                    };
                })
                .filter((f) => !f.is_view_disabled && f.is_visible)
                .map((rt, rti) => (
                    <Grid
                        key={rti}
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ paddingRight: 25, marginBottom: 10 }}
                    >
                        {rt.options && rt.options.length && (
                            <SelectItemTypeControl
                                classes={classes}
                                recordSequence={recordSequence}
                                transaction={transaction}
                                recordType={rt}
                                recordTypeIndex={rti}
                                recordKey={recordKey}
                                index={index}
                                updateNewTransaction={updateNewTransaction}
                                validateTransactionField={
                                    validateTransactionField
                                }
                            />
                        )}
                        {rt.data_type === "date" && rt.is_editable && (
                            <DateItemTypeControl
                                classes={classes}
                                recordSequence={recordSequence}
                                transaction={transaction}
                                recordType={rt}
                                recordTypeIndex={rti}
                                recordKey={recordKey}
                                index={index}
                                updateNewTransaction={updateNewTransaction}
                                validateTransactionField={
                                    validateTransactionField
                                }
                            />
                        )}
                        {!(rt.data_type === "date" && rt.is_editable) &&
                            !rt.options && (
                                <TextItemTypeControl
                                    classes={classes}
                                    recordSequence={recordSequence}
                                    transaction={transaction}
                                    recordType={rt}
                                    recordTypeIndex={rti}
                                    recordKey={recordKey}
                                    index={index}
                                    updateNewTransaction={updateNewTransaction}
                                    validateTransactionField={
                                        validateTransactionField
                                    }
                                />
                            )}
                    </Grid>
                ))}
        </Grid>
    </Container>
);

const RecordInfo = ({
    classes,
    recordSequence,
    recordTypes,
    transaction,
    transactionOverride,
    recordKey,
    index,
    updateNewTransaction,
    validateTransactionField,
}) => {
    const recordOverride = transactionOverride.records.find(
        (r) => r[Number(recordSequence.record_indicator)]
    );
    const recordOverridePart =
        recordOverride &&
        recordOverride[Number(recordSequence.record_indicator)][index];

    const anyVisible = Object.values(
        recordTypes[Number(recordSequence.record_indicator)]
    )
        .map((f) => ({
            ...f,
            ...recordOverridePart.find((o) => o.label === f.label),
        }))
        .find((f) => !f.is_view_disabled && f.is_visible);

    if (!anyVisible) {
        return null;
    }
    return (
        <Paper elevation={3}>
            <Typography
                variant="h5"
                className={classes.transactionDetailHeader}
            >
                {recordSequence.label
                    .split("_")
                    .map((o) => `${o[0].toUpperCase()}${o.substring(1)} `)}
            </Typography>
            <Typography className={classes.secondaryHeading}>
                ({recordSequence.record_indicator})
            </Typography>
            <RecordInfoItems
                index={index}
                recordKey={recordKey}
                classes={classes}
                recordTypes={recordTypes}
                recordSequence={recordSequence}
                recordOverridePart={recordOverridePart}
                transaction={transaction}
                updateNewTransaction={updateNewTransaction}
                validateTransactionField={validateTransactionField}
            />
        </Paper>
    );
};

const TransactionDetail = ({
    transaction,
    transactionType,
    schema,
    classes,
    updateNewTransaction,
    validateTransactionField,
}) => {
    const { record_sequence, record_types, transaction_types } = schema;
    const transactionOverride = transaction_types.find(
        (t) => t.transaction_type === transactionType
    );
    return (
        <form autoComplete="off">
            {/* <Typography variant="h5">Transaction Detail</Typography> */}
            <div className={classes.transactionDetailRoot}>
                {
                    //loop all record sequence to render the data in proper order
                    Object.values(record_sequence).map((r) =>
                        //map each transaction section to create a record container
                        Object.keys(transaction).map((key) =>
                            // match each key from the record sequence schema to the transaction data
                            transaction[key]
                                .filter(
                                    (t) =>
                                        t["Record Indicator"] ===
                                        r.record_indicator
                                )
                                .map((record, index) => (
                                    <RecordInfo
                                        key={`${key}${index}`}
                                        recordSequence={r}
                                        recordTypes={record_types}
                                        transaction={record}
                                        transactionOverride={
                                            transactionOverride
                                        }
                                        recordKey={key}
                                        index={index}
                                        classes={classes}
                                        updateNewTransaction={
                                            updateNewTransaction
                                        }
                                        validateTransactionField={
                                            validateTransactionField
                                        }
                                    />
                                ))
                        )
                    )
                }
            </div>
        </form>
    );
};

const EditTransactionView = ({
    classes,
    newTransactionData,
    transaction,
    schema,
    updateState,
    isProcessing,
    createNewTransaction,
    updateNewTransaction,
    sendTransaction,
    validateTransactionField,
}) => (
    <div className={classes.editTransactionView}>
        <DialogContent>
            <TransactionDetail
                transaction={transaction}
                transactionType={newTransactionData.transactionTypeName}
                schema={schema}
                classes={classes}
                updateNewTransaction={updateNewTransaction}
                validateTransactionField={validateTransactionField}
            />
        </DialogContent>

        <DialogActions>
            <Button
                onClick={() => {
                    schema
                        ? sendTransaction(transaction, schema)
                        : createNewTransaction();
                }}
                color="secondary"
                variant="contained"
                style={{
                    float: "right",
                    marginRight: 10,
                }}
                disabled={!(transaction.transactionTypeName || schema)}
            >
                {(isProcessing && (
                    <ClipLoader
                        sizeUnit="px"
                        size={20}
                        color="#ED0874"
                        loading
                    />
                )) ||
                schema
                    ? "Send"
                    : "Create"}
            </Button>
            <Button
                onClick={() => {
                    updateState({
                        openCreateTransaction: false,
                        newTransactionData: {
                            fileType: "gaa",
                            transactionTypeName: "",
                            transactionSubType: "Request",
                        },
                    });
                }}
                color="primary"
                variant="contained"
                Close
                style={{
                    float: "right",
                    marginRight: 20,
                }}
            >
                Close
            </Button>
        </DialogActions>
    </div>
);

const NewTransactionView = ({
    newTransactionData,
    allTransactionTypes,
    handleChange,
    updateState,
    isProcessing,
    createNewTransaction,
}) => (
    <div>
        <DialogContent>
            <div>
                <List>
                    <ListItem>
                        <TextField
                            select
                            label="Document Type"
                            style={{
                                width: "100%",
                            }}
                            value={newTransactionData.fileType}
                            onChange={handleChange}
                            inputProps={{
                                name: "fileType",
                            }}
                        >
                            {Object.keys(allTransactionTypes).map(
                                (fileType, i) => (
                                    <MenuItem value={fileType} key={i}>
                                        {fileType.toUpperCase()}
                                    </MenuItem>
                                )
                            )}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <TextField
                            select
                            label="Transaction Type"
                            style={{
                                width: "100%",
                            }}
                            value={newTransactionData.transactionTypeName}
                            onChange={handleChange}
                            inputProps={{
                                name: "transactionTypeName",
                            }}
                        >
                            {allTransactionTypes[
                                newTransactionData.fileType
                            ].map((transactionType) => (
                                <MenuItem
                                    value={transactionType}
                                    key={transactionType}
                                >
                                    {transactionType}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                </List>
            </div>
        </DialogContent>

        <DialogActions>
            <Button
                onClick={() => {
                    createNewTransaction();
                }}
                color="primary"
                variant="contained"
                style={{
                    float: "right",
                    marginRight: 10,
                    marginBottom: 10,
                }}
                disabled={!newTransactionData.transactionTypeName}
            >
                {(isProcessing && (
                    <ClipLoader
                        sizeUnit="px"
                        size={20}
                        color="#ED0874"
                        loading
                    />
                )) ||
                    "Create"}
            </Button>
            <Button
                onClick={() => {
                    updateState({ openCreateTransaction: false });
                }}
                color="secondary"
                variant="contained"
                disabled={isProcessing}
                Close
                style={{
                    float: "right",
                    marginRight: 20,
                    marginBottom: 10,
                }}
            >
                Close
            </Button>
        </DialogActions>
    </div>
);

const MeterTransactionCreate = ({
    createNewTransaction,
    handleChange,
    updateState,
    updateNewTransactionDetail,
    isProcessing,
    openCreateTransaction,
    newTransactionData,
    sendTransaction,
    validateTransactionField,
    allTransactionTypes,
    classes,
}) => {
    return (
        <Dialog
            open={openCreateTransaction}
            contentlabel="Transaction Raw Data"
            ariaHideApp={false}
            fullWidth={true}
            maxWidth={newTransactionData.schema ? "lg" : "xs"}
            maxHeight="80%"
        >
            <DialogTitle>
                <Typography color="secondary" variant="h4">
                    Create Transaction
                </Typography>
                <Typography color="primary" variant="h6">
                    Type: {newTransactionData.transactionTypeName} Request
                </Typography>
            </DialogTitle>
            {!newTransactionData.schema && (
                <NewTransactionView
                    createNewTransaction={createNewTransaction}
                    handleChange={handleChange}
                    updateState={updateState}
                    isProcessing={isProcessing}
                    openCreateTransaction={openCreateTransaction}
                    newTransactionData={newTransactionData}
                    allTransactionTypes={allTransactionTypes}
                    classes={classes}
                />
            )}
            {newTransactionData.schema && (
                <EditTransactionView
                    newTransactionData={newTransactionData}
                    transaction={newTransactionData.transaction.transactionData}
                    schema={newTransactionData.schema}
                    handleChange={handleChange}
                    updateState={updateState}
                    updateNewTransaction={updateNewTransactionDetail}
                    isProcessing={isProcessing}
                    openCreateTransaction={openCreateTransaction}
                    newTransactionData={newTransactionData}
                    sendTransaction={sendTransaction}
                    validateTransactionField={validateTransactionField}
                    classes={classes}
                />
            )}
        </Dialog>
    );
};

MeterTransactionCreate.propTypes = {
    isProcessing: PropTypes.any,
    openCreateTransaction: PropTypes.any,

    transactionIsModified: PropTypes.any,
    updateState: PropTypes.func,
};

export default MeterTransactionCreate;
