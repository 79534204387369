import axios from "axios";

const iterableAxiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://mx-admin-base.app.gogriddy.com"
      : "https://mx-admin-base.development.gogriddy.com",
});

export default iterableAxiosInstance;
