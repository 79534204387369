import Immutable from "immutable";
import * as constants from "../../constants";

const defaultTdspResultState = Immutable.List();

export default function tdspResult(state = defaultTdspResultState, action) {
    switch (action.type) {
    case constants.RECEIVE_TDSP_INVOICE_REPORT:
        return Immutable.List(action.rows);
    case constants.CLEAR_DISPUTE_INFO:
	return defaultTdspResultState;
    default:
        return state;
    }
}
