import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import ReactTable from "react-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import getUsage from "../actions/usage";

class Usage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timeInterval: "daily",
        };
    }

    componentDidMount() {
        const { doGetUsage, params, guest } = this.props;
        const guestId = params.guestUserId;
        const memberId = guestId ? guest.get("memberID") : params.memberId;

        if (guestId && guest.get("memberID")) {
            doGetUsage(memberId, guestId);
        } else if (!guestId) {
            doGetUsage(memberId);
        }
    }

    handleChange = (selectObj) => {
        this.setState({ timeInterval: selectObj.target.value });
    };

    render() {
        const { usage } = this.props;
        const { timeInterval } = this.state;
        const LineData = usage.get(timeInterval).toJSON();

        const columns = [
            {
                Header: "Time",
                accessor: "time",
                Cell: (row) => (
                    <div>
                        {moment
                            .tz(row.value, "America/Chicago")
                            .format("MMM DD, YYYY, h:mm a")}
                    </div>
                ),
            },
            {
                Header: "Price",
                accessor: "price_ckwh",
                Cell: (row) => <div>{row.value}¢</div>,
            },
            {
                Header: "Usage",
                accessor: "usage_kwh",
                Cell: (row) => <div>{row.value} kW/h</div>,
            },
            {
                Header: "Cost",
                accessor: "cost_dollars",
                Cell: (row) => <div>${row.value}</div>,
            },
        ];

        return (
            <div>
                <Select
                    value={this.state.timeInterval}
                    onChange={this.handleChange}
                    inputProps={{
                        name: "Interval",
                    }}
                >
                    <MenuItem value={"hourly"}>Hourly</MenuItem>
                    <MenuItem value={"daily"}>Daily</MenuItem>
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                </Select>
                <ReactTable
                    data={LineData.reverse()}
                    columns={columns}
                    style={{ height: "600px", width: "1600px", marginTop: 15 }}
                    className="-striped -highlight"
                    filterable
                    noDataText=""
                    defaultPageSize={100}
                />
            </div>
        );
    }
}

Usage.propTypes = {
    usage: PropTypes.object,
    doGetUsage: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    guest: PropTypes.object,
};

Usage.defaultProps = {
    usage: {
        hourly: [],
        daily: [],
        monthly: [],
    },
    guest: {},
};

const mapStateToProps = (state) => ({
    usage: state.usage,
    guest: state.guest,
});

const mapDispatchToProps = (dispatch) => ({
    doGetUsage: (memberId, guestUserId) =>
        dispatch(getUsage(memberId, guestUserId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usage);
