import Immutable from "immutable";
import * as constants from "../../constants";

const defaultPricesState = Immutable.fromJS([]);

export default function prices(state = defaultPricesState, action) {
    switch (action.type) {
    case constants.RECEIVE_PRICES:
        return Immutable.fromJS(action.data);
    default:
        return state;
    }
}
