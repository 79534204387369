import * as C from "../../constants";

const initialState = {
    initialized: false,
    isLoading: false,
    has_next: false,
    limit: "0",
    total: "0",
    accounts: [],
};

const loadPendingTransactions = (state, action) => {
    return {
        ...state,
        ...action,
    };
};

const updateCandidateState = (state, action) => {
    const { index, updatedInfo } = action;
    const account = { ...state.accounts[index], ...updatedInfo };

    return {
        ...state,
        accounts: [
            ...state.accounts.slice(0, index),
            account,
            ...state.accounts.slice(index + 1),
        ],
    };
};

const toggleCandidatesSelectAll = (state) => {
    const { allSelected } = state;
    return {
        ...state,
        allSelected: !allSelected,
        accounts: state.accounts.map((a) => ({
            ...a,
            meters: [
                ...a.meters.map((m) => ({ ...m, selected: !allSelected })),
            ],
        })),
    };
};

const toggleCandidateSelect = (state, action) => {
    const { accountID, meterId } = action;
    const accountIndex = state.accounts.findIndex(
        (a) => a.accountID === accountID
    );
    const selectedAccount = state.accounts[accountIndex];
    const meterIndex = selectedAccount.meters.findIndex(
        (m) => m.ID === meterId
    );
    selectedAccount.meters = [
        ...selectedAccount.meters.slice(0, meterIndex),
        {
            ...selectedAccount.meters[meterIndex],
            selected: !Boolean(selectedAccount.meters[meterIndex].selected),
        },
        ...selectedAccount.meters.slice(meterIndex + 1),
    ];

    return {
        ...state,
        accounts: [
            ...state.accounts.slice(0, accountIndex),
            selectedAccount,
            ...state.accounts.slice(accountIndex + 1),
        ],
    };
};

const toggleDnpInprogress = (state, action) => {
    const accountIndex = state.accounts.findIndex((a) =>
        a.meters.find((m) => m.ID === action.meter_recordID)
    );
    const account = state.accounts[accountIndex];
    const meterIndex =
        account &&
        account.meters.findIndex((m) => m.ID === action.meter_recordID);

    account.meters = [
        ...account.meters.slice(0, meterIndex),
        {
            ...account.meters[meterIndex],
            isProcessing: !account.meters[meterIndex].isProcessing,
        },
        ...account.meters.slice(meterIndex + 1),
    ];

    return {
        ...state,
        accounts: [
            ...state.accounts.slice(0, accountIndex),
            account,
            ...state.accounts.slice(accountIndex + 1),
        ],
    };
};

const processDnpSuccess = (state, action) => {
    const accountIndex = state.accounts.findIndex((a) =>
        a.meters.find((m) => m.ID === action.meter_recordID)
    );
    const account = state.accounts[accountIndex];
    const meterIndex =
        account &&
        account.meters.findIndex((m) => m.ID === action.meter_recordID);

    account.meters = [
        ...account.meters.slice(0, meterIndex),
        {
            ...account.meters[meterIndex],
            isProcessing: false,
            processed: true,
        },
        ...account.meters.slice(meterIndex + 1),
    ];

    return {
        ...state,
        accounts: [
            ...state.accounts.slice(0, accountIndex),
            account,
            ...state.accounts.slice(accountIndex + 1),
        ],
    };
};

const setMeterLevelError = (state, action) => {
    const accountIndex = state.accounts.findIndex((a) =>
        a.meters.find((m) => m.ID === action.meter_recordID)
    );
    const account = state.accounts[accountIndex];
    const meterIndex =
        account &&
        account.meters.findIndex((m) => m.ID === action.meter_recordID);

    account.meters = [
        ...account.meters.slice(0, meterIndex),
        {
            ...account.meters[meterIndex],
            errorMessage: action.message,
        },
        ...account.meters.slice(meterIndex + 1),
    ];

    return {
        ...state,
        accounts: [
            ...state.accounts.slice(0, accountIndex),
            account,
            ...state.accounts.slice(accountIndex + 1),
        ],
    };
};

const clearAllError = (state) => {
    return {
        ...state,
        accounts: state.accounts.map((a) => ({
            ...a,
            meters: [...a.meters.map((m) => ({ ...m, errorMessage: "" }))],
        })),
    };
};

const loadCandidates = (state, action) => {
    return {
        ...state,
        ...action,
        initialized: true,
    };
};

const toggleLoading = (state) => {
    return {
        ...state,
        isLoading: !state.isLoading,
    };
};

export default function dnp(state = initialState, action) {
    switch (action.type) {
        case C.DNP_LOAD_PENDING_TRANSACTIONS:
            return loadPendingTransactions(state, action);
        case C.DNP_UPDATE_CANDIDATE:
            return updateCandidateState(state, action);
        case C.DNP_TOGGLE_CANDIDATE_SELECT:
            return toggleCandidateSelect(state, action);
        case C.DNP_TOGGLE_CANDIDATES_SELECT_ALL:
            return toggleCandidatesSelectAll(state);
        case C.DNP_TOGGLE_INPROGRESS:
            return toggleDnpInprogress(state, action);
        case C.DNP_TOGGLE_SUCCESS:
            return processDnpSuccess(state, action);
        case C.DNP_SET_METER_ERROR:
            return setMeterLevelError(state, action);
        case C.DNP_CLEAR_ALL_ERRORS:
            return clearAllError(state);
        case C.DNP_LOAD_CANDIDATES:
            return loadCandidates(state, action);
        case C.DNP_TOGGLE_LOADING:
            return toggleLoading(state);
        default:
            return state;
    }
}
