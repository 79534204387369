import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import NotesTable from "./NotesTable.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
    },
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  noteCardsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
}));

const UserNotes = (props) => {
  const {
    member,
    notes,
    onFetchUserIDWithFullMemberInfoNeeded,
    onfetchFullMemberInfoNeeded,
  } = props;

  const classes = useStyles();
  const [noteCards, setNoteCards] = useState([]);

  useEffect(() => {
    if (member.get("userID") === undefined) {
      onFetchUserIDWithFullMemberInfoNeeded(props.params.memberId);
    } else {
      onfetchFullMemberInfoNeeded(props.member);
    }
  }, []);

  useEffect(() => {
    const notesData = Array.from(notes).reduce(
      (acc, note) => {
        if (Number(note.get("userID")) !== 0) {
          acc.userNotes.push(note);
        } else {
          acc.memberNotes.push(note);
        }
        return acc;
      },
      { userNotes: [], memberNotes: [] }
    );

    notesData.userNotes.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.get("created")) - new Date(a.get("created"));
    });

    setNoteCards(notesData.userNotes);
  }, [notes]);

  return (
    <div className={classes.root}>
      <h1 id="page-title">User Notes</h1>
      <div className={classes.noteCardsContainer}>
          <NotesTable notesData={noteCards}/>
      </div>
    </div>
  );
};

UserNotes.propTypes = {
  member: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default UserNotes;