import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmTransactionProcessing = ({
    handleProcessTransaction,
    handleClose,
}) => {
    return (
        <Dialog
            open
            contentlabel="Transaction Raw Data"
            ariaHideApp={false}
            maxWidth={"xs"}
        >
            <DialogTitle>Process Transaction</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Transaction successfully linked to meter. Do you want to
                    retry processing this transaction?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleProcessTransaction();
                    }}
                    color="secondary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 10,
                        marginBottom: 10,
                    }}
                >
                    Process
                </Button>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    color="primary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 10,
                        marginBottom: 10,
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmTransactionProcessing.propTypes = {};

export default ConfirmTransactionProcessing;
