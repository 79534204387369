import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestErcotInvoiceReport() {
    return {
        type: constants.REQUEST_ERCOT_INVOICE_REPORT,
        isFetching: true,
    };
}

function recieveErcotInvoiceReport(data) {
    return {
        type: constants.RECEIVE_ERCOT_INVOICE_REPORT,
        isFetching: false,
        rows: data,
    };
}

export default function fetchErcotInvoice(startDate, endDate) {
    return (dispatch) => {
        dispatch(requestErcotInvoiceReport());
        return fetch(constants.ERCOT_INVOICE, { start_date: startDate, end_date: endDate })
            .then((data) => {
                const newRows = data.rows.map((v) => {
                    const newTdsp = v;
                    newTdsp.energy_shadow = v.energy_shadow * -1 || 0;
                    newTdsp.rtm_amount = v.rtm_amount || 0;
                    newTdsp.rtm_final = v.rtm_final || 0;
                    newTdsp.rtm_trueup = v.rtm_trueup || 0;
                    newTdsp.rtm_settlement = v.rtm_settlement || 0;
                    newTdsp.dam = v.dam || 0;
                    newTdsp.dam_resettlement = v.dam_resettlement || 0;

                    newTdsp.invoice_amounts = v.invoice_amounts || {};

                    newTdsp.invoice_amounts.rtm_invoice = v.invoice_amounts.rtm_invoice || 0;
                    newTdsp.invoice_amounts.rtm_final_invoice = v.invoice_amounts.rtm_final_invoice || 0;
                    newTdsp.invoice_amounts.rtm_trueup_invoice = v.invoice_amounts.rtm_trueup_invoice || 0;
                    newTdsp.invoice_amounts.rtm_settlement_invoice = v.invoice_amounts.rtm_settlement_invoice || 0;
                    newTdsp.invoice_amounts.dam_resettlement_invoice = v.invoice_amounts.dam_resettlement_invoice || 0;

                    const sum1 = Number(v.energy_shadow) +
                                Number(v.rtm_amount) +
                                Number(v.rtm_final) +
                                Number(v.rtm_trueup) +
                                Number(v.rtm_settlement) +
                                Number(v.dam) +
                                Number(v.dam_resettlement);

                    const sum2 = Number(v.invoice_amounts.rtm_invoice) +
                                Number(v.invoice_amounts.rtm_final_invoice) +
                                Number(v.invoice_amounts.rtm_trueup_invoice) +
                                Number(v.invoice_amounts.rtm_settlement_invoice) +
                                Number(v.invoice_amounts.dam_resettlement_invoice);

                    newTdsp.difference = sum2 - (sum1 * -1);

                    newTdsp.correct_sum = v.difference < 0.01 || sum1 === sum2;
                    return newTdsp;
                });
                dispatch(recieveErcotInvoiceReport(newRows));
            });
    };
}
