import * as constants from "../../constants";
import selectAccountFlagsContainer from "../selectors/accountFlags";
import fetch from "../util/api-ajax";
import { updateCandidateState } from "./dnp";

const createErrorMessage = (message) => ({
    type: constants.ACCOUNT_FLAG_SET_ERROR,
    message,
});

const createSuccessMessage = (message) => ({
    type: constants.ACCOUNT_FLAG_SET_SUCCESS,
    message,
});

export const setErrorMessage = (message) => (dispatch) => {
    dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
    dispatch(createSuccessMessage(message));
};

const toggleIsLoading = () => ({
    type: constants.ACCOUNT_FLAG_TOGGLE_LOADING,
});

export const fetchAccountFlagTypes = () => (dispatch) => {
    return fetch(`${constants.ACCOUNT_FLAG_FETCH_URL}/`)
        .then((accountFlagTypes) => {
            dispatch({
                type: constants.ACCOUNT_FLAG_TYPES_LOAD,
                accountFlagTypes: accountFlagTypes.Items,
            });
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
        });
};

export const fetchAccountFlags = () => (dispatch, getState) => {
    const { selectedAccountID } = selectAccountFlagsContainer()(getState());
    if (!selectedAccountID) {
        return;
    }
    dispatch(toggleIsLoading());
    return fetch(
        `${constants.ACCOUNT_FLAGS_MAIN_URL}/${selectedAccountID}/accountflags`,
        {
            is_expired: false,
            filter_by_expired: true,
        }
    )
        .then((accountFlags) => {
            dispatch({
                type: constants.ACCOUNT_FLAGS_LOAD,
                selectedAccountID,
                accountFlags: accountFlags.Items,
            });
            dispatch(toggleIsLoading());
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
            dispatch(toggleIsLoading());
        });
};

const setDnpQueFlag = (dispatch, getState) => {
    const {
        selectedAccountID,
        selectedAccountFlags,
    } = selectAccountFlagsContainer()(getState());
    const dnpQueueIndex = getState().dnp.accounts.findIndex(
        (a) => a.accountID === selectedAccountID
    );
    updateCandidateState(dnpQueueIndex, {
        hasDnpFlag: Boolean(selectedAccountFlags.length),
    })(dispatch);
};

export const cancelChanges = () => (dispatch, getState) => {
    const { selectedAccountID } = selectAccountFlagsContainer()(getState());
    return dispatch({
        type: constants.ACCOUNT_FLAG_CANCEL_CHANGES,
        selectedAccountID,
    });
};

export const sendAccountsFlagRequest = () => (dispatch, getState) => {
    const {
        selectedAccountID,
        selectedAccountFlags,
    } = selectAccountFlagsContainer()(getState());
    const accountFlagsToAdd = selectedAccountFlags.filter(
        (af) => af.action === "add"
    );
    const accountFlagsToRemove = selectedAccountFlags.filter(
        (af) => af.action === "remove"
    );

    const promises = [];
    dispatch(toggleIsLoading());
    promises.push(
        accountFlagsToAdd.map((af) => {
            fetch(
                `${constants.ACCOUNT_FLAGS_MAIN_URL}/${selectedAccountID}/accountflags/create`,
                { account_flag_type_id: af.flag_type }
            ).then((accountFlag) => {
                dispatch({
                    type: constants.ACCOUNT_FLAGS_ADD,
                    selectedAccountID,
                    accountFlag,
                });
                return setDnpQueFlag(dispatch, getState);
            });
        })
    );

    promises.push(
        accountFlagsToRemove.map((af) => {
            fetch(
                `${constants.ACCOUNT_FLAGS_MAIN_URL}/${selectedAccountID}/accountflags//${af.account_flag_id}/delete`,
                { account_flag_type_id: af.flag_type }
            ).then((accountFlag) => {
                dispatch({
                    type: constants.ACCOUNT_FLAGS_DELETE,
                    selectedAccountID,
                    accountFlag,
                });
                return setDnpQueFlag(dispatch, getState);
            });
        })
    );

    Promise.all(promises)
        .then(() => dispatch(setSuccessMessage("Flag has been updated!")))
        .catch((e) => dispatch(setErrorMessage(e.message)));
    cancelChanges()(dispatch, getState);
    dispatch(toggleIsLoading());
};

export const toggleAccountFlag = (flagId) => (dispatch, getState) => {
    const { selectedAccountID } = selectAccountFlagsContainer()(getState());
    return dispatch({
        type: constants.ACCOUNT_FLAG_TOGGLE,
        flagId,
        selectedAccountID,
    });
};

export const closeSnackbar = () => (dispatch) => {
    return dispatch({ type: constants.ACCOUNT_FLAG_DISMISS_MESSAGE });
};

export const toggleCategoryView = (category) => (dispatch) => {
    return dispatch({
        type: constants.ACCOUNT_FLAG_TOGGLE_CATEGORY_VIEW,
        category,
    });
};
