import { createSelector } from "reselect";

const dnpDomain = () => (state) => state.dnp;
const adminUser = () => (state) => state.user;
const selectAccounts = () => (state) => state.dnp.accounts;

const selectDataByMeters = () =>
    createSelector(selectAccounts(), (accounts) => {
        return (
            (accounts &&
                accounts
                    .filter((a) => a.meters && !a.hasDnpFlag)
                    .map((a) =>
                        a.meters
                            .filter((m) => !m.processed)
                            .map((m) => ({
                                ...a,
                                ...m,
                                lastPaymentAmount:
                                    a.last_successful_payment &&
                                    a.last_successful_payment.amount,
                                lastPaymentstatus:
                                    a.last_successful_payment &&
                                    a.last_successful_payment.status,
                                lastPaymentDate:
                                    a.last_successful_payment &&
                                    a.last_successful_payment.created.substring(
                                        0,
                                        10
                                    ),
                            }))
                    )
                    .reduce((p, c) => [...p, ...c], [])) ||
            []
        );
    });

const selectDnpContainer = () =>
    createSelector(
        dnpDomain(),
        adminUser(),
        selectDataByMeters(),
        (subState, adminUser, accountsByMeters) =>
            Object.assign({}, subState, {
                adminUser,
                accountsByMeters,
            })
    );

export default selectDnpContainer;
