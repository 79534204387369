import React, { Component } from "react";
import { bindActionCreators } from "redux";
import moment from "moment";
import selectAccountFlagsContainer from "../selectors/accountFlags";
import * as actions from "../actions/accountFlags";
import { injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { ClipLoader } from "react-spinners";
import Snackbar from "@material-ui/core/Snackbar";
import { confirmationError, confirmationSuccess } from "../styles/common.css";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const AccountFlagList = ({
    category,
    selectedAccountFlags,
    accountFlagTypes,
    isLoading,
    sendAccountsFlagRequest,
    toggleAccountFlag,
    cancelChanges,
    userAccountFlagsChanged,
    expandedView,
    toggleCategoryView,
}) => {
    const count =
        selectedAccountFlags &&
        selectedAccountFlags.filter((fa) => {
            const c = accountFlagTypes.find((ft) => {
                return ft.ID === fa.flag_type && fa.action !== "remove";
            });
            return c && c.Category === category;
        }).length;

    return (
        <div>
            <hr style={{ margin: "0 25px" }} />
            <div style={{ padding: "5px", marginLeft: "25px" }}>
                {category != null && category.replace("_", " ")} ({count})
                <div style={{ float: "right", paddingRight: "25px" }}>
                    {!expandedView && (
                        <KeyboardArrowDownIcon
                            onClick={() => {
                                toggleCategoryView(category);
                            }}
                        />
                    )}
                    {expandedView && (
                        <KeyboardArrowUpIcon
                            onClick={() => {
                                toggleCategoryView(category);
                            }}
                        />
                    )}
                </div>
            </div>

            {isLoading && expandedView && (
                <div style={{ textAlign: "center" }}>
                    <ClipLoader
                        sizeUnit="px"
                        size={150}
                        color="#ED0874"
                        loading
                    />
                </div>
            )}

            {!isLoading &&
                expandedView &&
                accountFlagTypes.map((f, i) => {
                    const flag = selectedAccountFlags.find(
                        (fa) => fa.flag_type === f.ID
                    );
                    return (
                        <ListItem key={i} style={{ padding: "0 15px" }}>
                            <Checkbox
                                type="checkbox"
                                className="checkbox"
                                checked={Boolean(
                                    flag && flag.action !== "remove"
                                )}
                                onChange={() => toggleAccountFlag(f.ID)}
                                color={
                                    flag && flag.account_flag_id
                                        ? "secondary"
                                        : "primary"
                                }
                            />

                            <p
                                style={{
                                    paddingTop: 15,
                                    textDecoration:
                                        flag &&
                                        flag.account_flag_id &&
                                        flag.action === "remove"
                                            ? "line-through"
                                            : "",
                                }}
                            >
                                {`${f.ID} - ${f.Description}  `}
                                <small style={{ marginLeft: 5, color: "red" }}>
                                    {flag &&
                                        flag.expired &&
                                        `(Expires: ${moment(
                                            flag.expired
                                        ).format("MMM DD, YYYY")})`}
                                </small>
                            </p>
                        </ListItem>
                    );
                })}
            {!isLoading && expandedView && (
                <div>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => sendAccountsFlagRequest()}
                        style={{ margin: 20 }}
                        disabled={!userAccountFlagsChanged}
                    >
                        Save
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => cancelChanges()}
                        disabled={!userAccountFlagsChanged}
                    >
                        Cancel
                    </Button>
                </div>
            )}
        </div>
    );
};

class AccountsFlag extends Component {
    componentDidMount() {
        const {
            initialized,
            fetchAccountFlagTypes,
            fetchAccountFlags,
        } = this.props;
        if (!initialized) {
            fetchAccountFlagTypes();
            fetchAccountFlags();
        }
    }

    componentWillReceiveProps() {}

    render() {
        const {
            isLoading,
            accountFlagTypes,
            selectedAccountFlags,
            sendAccountsFlagRequest,
            toggleAccountFlag,
            userAccountFlagsChanged,
            cancelChanges,
            successMessage,
            errorMessage,
            showSnackBar,
            closeSnackbar,
            toggleCategoryView,
            expandedViews,
        } = this.props;
        return (
            <div
                style={{
                    border: "1px solid lightgrey",
                    marginTop: "20px",
                    marginLeft: "25px",
                    maxWidth: "470px",
                }}
            >
                <h4 style={{ marginLeft: "25px" }}>Account Flags</h4>
                {[...new Set([...accountFlagTypes.map((t) => t.Category)])].map(
                    (category, i) => (
                        <AccountFlagList
                            key={i}
                            category={category}
                            selectedAccountFlags={selectedAccountFlags}
                            accountFlagTypes={accountFlagTypes.filter(
                                (t) => t.Category === category
                            )}
                            isLoading={isLoading}
                            sendAccountsFlagRequest={sendAccountsFlagRequest}
                            expandedView={
                                expandedViews.indexOf(category) !== -1
                            }
                            toggleCategoryView={toggleCategoryView}
                            toggleAccountFlag={toggleAccountFlag}
                            userAccountFlagsChanged={userAccountFlagsChanged}
                            cancelChanges={cancelChanges}
                        />
                    )
                )}
                <Snackbar
                    id="message-id"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    open={showSnackBar}
                    autoHideDuration={3000}
                    onClose={closeSnackbar}
                    className={
                        successMessage ? confirmationSuccess : confirmationError
                    }
                    message={successMessage || errorMessage}
                />
            </div>
        );
    }
}

const mapStateToProps = selectAccountFlagsContainer();

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actions, dispatch),
    dispatch,
});

const ConnectedAccountsFlag = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountsFlag);

export default injectStripe(ConnectedAccountsFlag);
