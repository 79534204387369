import Immutable from "immutable";
import * as constants from "../../constants";

const defaultUsageState = Immutable.fromJS({
    hourly: [],
    daily: [],
    monthly: [],
});

export default function usage(state = defaultUsageState, action) {
    switch (action.type) {
    case constants.RECEIVE_USAGE:
        return Immutable.fromJS(action.data);
    default:
        return state;
    }
}
