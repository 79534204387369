import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";

const MemberInfo = ({
    userID,
    first_name,
    last_name,
    phone,
    birthday,
    canCreateMember,
    createMember,
    handleChange,
    handleDateChange,
}) => {
    return (
        <div>
            {userID && (
                <List>
                    <h4
                        style={{
                            marginLeft: "25px",
                            display: "inline-block",
                        }}
                    >
                        Member Information
                    </h4>

                    <ListItem>
                        <TextField
                            name="first_name"
                            label="First Name"
                            onChange={handleChange}
                            value={first_name}
                            type="string"
                        />
                        <TextField
                            name="last_name"
                            label="Last Name"
                            onChange={handleChange}
                            value={last_name}
                            type="string"
                            style={{ marginLeft: 20 }}
                        />
                    </ListItem>

                    <ListItem>
                        <TextField
                            name="phone"
                            label="Phone"
                            onChange={handleChange}
                            value={phone}
                            type="string"
                        />
                        <KeyboardDatePicker
                            format="MM/dd/yyyy"
                            placeholder="MM/dd/yyyy"
                            label="Birthday"
                            value={
                                (birthday &&
                                    moment.utc(birthday).format("l LT")) ||
                                null
                            }
                            onChange={handleDateChange("birthday")}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            style={{ marginLeft: 20, marginTop: 15 }}
                        />
                    </ListItem>
                </List>
            )}
            {canCreateMember && (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        createMember();
                    }}
                    style={{ marginLeft: 20 }}
                >
                    Create Member
                </Button>
            )}
        </div>
    );
};

MemberInfo.propTypes = {
    birthday: PropTypes.any,
    canCreateMember: PropTypes.any,
    createMember: PropTypes.func,
    first_name: PropTypes.any,
    handleChange: PropTypes.any,
    last_name: PropTypes.any,
    memberID: PropTypes.any,
    phone: PropTypes.any,
    userID: PropTypes.any,
};

export default MemberInfo;
