import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Elements } from "react-stripe-elements";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Modal from "react-modal";
import { Link } from "react-router";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { FormControl } from "@material-ui/core/";
import { ClipLoader } from "react-spinners";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import CreditCardForm from "./CreditCardForm";
import AccountFlags from "./AccountFlags";
import modalStyle from "../styles/modal";
import { confirmationError, confirmationSuccess } from "../styles/common.css";
import {
  fetchUserIDWithFullMemberInfoNeeded,
  fetchFullMemberInfoNeeded,
} from "../actions/search";
import {
  userUpdate,
  applyAddFunds,
  applyRefund,
  closeAccount,
  memberUpdate,
  meterUpdate,
  clearConfMsg,
  activateAccount,
  refundAndCloseAccount,
  changeExemptionCode,
  resetPassword,
  activateUser,
  deActivateUser,
  clearSelectedMember,
} from "../actions/members";
import { requestTaxExcemptions, hideTaxSnackBar } from "../actions/tax";
import selectTaxInfoContainer from "../selectors/tax";
import * as constants from "../../constants";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});
const modalTypes = {
  ADD_FUND: 0,
  REFUND: 1,
  OPEN_ACCOUNT: 2,
  CLOSE_ACCOUNT: 3,
  DEACTIVATE_ACCOUNT: 4,
  CREDITS: 5,
  DEBITS: 6,
  REMOVE_START_DATE: 7,
  REMOVE_END_DATE: 8,
  ENABLE_USER: 9,
  DISABLE_USER: 10,
};

const RelatedMembers = ({ members, meters }) => (
  <List
    style={{
      border: "1px solid lightgrey",
      marginLeft: "25px",
      marginTop: "25px",
    }}
  >
    <h4 style={{ marginLeft: "25px" }}> Related Members </h4>
    <div
      style={{
        marginLeft: "25px",
        maxHeight: "185px",
        overflowY: "auto",
      }}
    >
      <Grid alignItems="flex-start" container>
        <Grid item xs={5}>
          ESI ID
        </Grid>
        <Grid item xs={4}>
          START/END
        </Grid>
        <Grid item xs={3}>
          STATUS
        </Grid>
      </Grid>

      {members.map((m, i) => {
        const meter = meters?.find(
          (met) => met.get("memberID") === m.get("memberID")
        );
        const meterID = meter
          ? `${meter.get("meterID")} `
          : `Incomplete (no meter)`;
        const meterDates = meter
          ? `${meter.get("start_date").substring(0, 10)} ${
              meter.get("end_date") ? "/" : ""
            } ${meter.get("end_date")?.substring(0, 10)}`
          : "";
        const meterStatus = meter ? meter.get("status")?.get("status") : "";

        return (
          <Grid
            alignItems="flex-start"
            container
            key={i}
            style={{ padding: "5px 0" }}
          >
            <Grid item xs={5}>
              <Link
                to={`/members/${m.get("memberID")}/details`}
                activeStyle={{
                  textDecoration: "none",
                  color: "black",
                  position: "abosolute",
                  right: 0,
                }}
                component="button"
              >
                {meterID}
              </Link>
            </Grid>
            <Grid item xs={4}>
              {meterDates}
            </Grid>
            <Grid item xs={3}>
              {meterStatus}
            </Grid>
          </Grid>
        );
      })}
    </div>
  </List>
);

RelatedMembers.propTypes = {
  members: PropTypes.shape({
    map: PropTypes.func,
  }),
  meters: PropTypes.shape({
    find: PropTypes.func,
  }),
};

class MemberDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      addFunds: "",
      applyRefund: "",
      nameEdit: false,
      emailEdit: false,
      phoneEdit: false,
      secondaryNameEdit: false,
      secondaryEmailEdit: false,
      secondaryPhoneEdit: false,
      rechargeEdit: false,
      balanceEdit: false,
      showModal: false,
      modalType: -1,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleModalConfirm = this.handleModalConfirm.bind(this);
    this.handleExemptionChange = this.handleExemptionChange.bind(this);
  }

  componentWillMount() {
    const {
      member,
      onFetchUserIDWithFullMemberInfoNeeded,
      onfetchFullMemberInfoNeeded,
      requestTaxExcemptions,
      taxInfo,
    } = this.props;
    
    if (member.get("userID") === undefined) {
      onFetchUserIDWithFullMemberInfoNeeded(this.props.params.memberId);
    } else {
      onfetchFullMemberInfoNeeded(this.props.member);
    }
    if (taxInfo.exemptionList.length === 0) {
      requestTaxExcemptions();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.member !== this.member) {
      // set meter states only when it is not set
      nextProps.member.get("meters").forEach((meter, index) => {
        if (
          !this.state[`startDateMeter_${index}`] ||
          nextProps.member.get("meters") !== this.props.member.get("meters")
        ) {
          this.state[`startDateMeter_${index}`] = meter.get("start_date");
        }
        if (
          !this.state[`endDateMeter_${index}`] ||
          nextProps.member.get("meters") !== this.props.member.get("meters")
        ) {
          this.state[`endDateMeter_${index}`] = meter.get("end_date");
        }
      });

      // set birthday
      this.state["birthday"] = nextProps.member.get("birthday")
        ? nextProps.member.get("birthday")
        : "";

      // set secondary birthday
      this.state["secondaryBirthday"] = nextProps.member.get(
        "secondary_account_holder"
      )
        ? nextProps.member.get("secondary_account_holder").get("birthday")
        : "";
      if (
        this.props.member.get("userID") !== undefined &&
        this.props.member.get("memberID") != undefined &&
        nextProps.params.memberId !== this.props.member.get("memberID")
      ) {
        this.props.onClearSelectedMember();
        this.props.onFetchUserIDWithFullMemberInfoNeeded(
          this.props.params.memberId
        );
      }
    }
  }

  componentWillUnmount() {
    const { onClearConfMsg } = this.props;
    onClearConfMsg();
  }

  handleOpenModal(modalType) {
    this.setState({ showModal: true, modalType });
  }
  handleCloseModal() {
    this.setState({ showModal: false });
  }
  handleOpenModalForMeter(modalType, meterID) {
    this.setState({
      showModal: true,
      modalType,
      removeDateMeterID: meterID,
    });
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = (name) => (value) => {
    const dateValue = `${moment(value).format("yyyy-MM-DD")}`;
    this.setState({
      [name]: dateValue,
    });
  };

  sendPasswordResetLink = () => {};
  handleModalConfirm() {
    switch (this.state.modalType) {
      case modalTypes.ADD_FUND:
        this.props.onApplyAddFunds(
          this.props.params.memberId,
          "new_payment_amount",
          this.state.addFunds
        );
        break;
      case modalTypes.REFUND:
        this.props.onApplyRefund(
          this.props.params.memberId,
          "amount_dollars",
          this.state.applyRefund
        );
        break;
      case modalTypes.OPEN_ACCOUNT:
        this.props.onOpenAccount(this.props.params.memberId);
        break;
      case modalTypes.CLOSE_ACCOUNT:
        this.props.onCloseAccount(this.props.params.memberId);
        break;
      case modalTypes.DEACTIVATE_ACCOUNT:
        this.props.onRefundAndClose(this.props.params.memberId);
        break;
      case modalTypes.ENABLE_USER:
        this.props.onActivateUser(this.props.member.get("userID"));
        break;
      case modalTypes.DISABLE_USER:
        this.props.onDeActivateUser(this.props.member.get("userID"));
        break;
      case modalTypes.REMOVE_START_DATE:
        this.props.onMeterUpdate(
          this.state.removeDateMeterID,
          "null_start_date",
          true
        );
        break;
      case modalTypes.REMOVE_END_DATE:
        this.props.onMeterUpdate(
          this.state.removeDateMeterID,
          "null_end_date",
          true
        );
        break;
      default:
        break;
    }
    this.handleCloseModal();
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.onHideSnackBar();
  };

  handleExemptionChange = (update, meterID, meters) => {
    return function (event) {
      update(meters, meterID, event.target.value);
    };
  };

  handleToggle = (name) => () => {
    const toggled = this.state[name];

    this.setState({
      [name]: !toggled,
    });
  };

  pressEnter = (update, id, property, value, handleToggle) => (ev) => {
    if (ev.key === "Enter") {
      if (
        update === this.props.onMeterUpdate &&
        value === "Invalid dateT00:00:00Z"
      ) {
        this.handleOpenModalForMeter(
          property === "start_date"
            ? modalTypes.REMOVE_START_DATE
            : modalTypes.REMOVE_END_DATE,
          id
        );
      } else if (update === this.props.onApplyAddFunds) {
        this.handleOpenModal(modalTypes.ADD_FUND);
      } else if (update === this.props.onApplyRefund) {
        this.handleOpenModal(modalTypes.onApplyRefund);
      } else {
        const updateValue = value.value ? value.value : value;
        const secondaryObject = value.value ? value : undefined;
        update(id, property, updateValue, secondaryObject);
        handleToggle();
        ev.preventDefault();
      }
    }
  };
  renderModalText() {
    switch (this.state.modalType) {
      case modalTypes.ADD_FUND:
        return (
          <div>
            Are you sure you want to add ${this.state.addFunds} to the
            member&#39;s account?
          </div>
        );
      case modalTypes.REFUND:
        return (
          <div>
            Are you sure you want to refund ${this.state.applyRefund} to the
            member&#39;s account?
          </div>
        );
      case modalTypes.OPEN_ACCOUNT:
        return (
          <div>Are you sure you want to open member&#39;s billing account?</div>
        );
      case modalTypes.CLOSE_ACCOUNT:
        return (
          <div>
            Are you sure you want to close member&#39;s billing account?
          </div>
        );
      case modalTypes.DEACTIVATE_ACCOUNT:
        return (
          <div>
            Are you sure you want to deactive member&#39;s billing account?
            Note: this will close the account and refund all the amount.
          </div>
        );
      case modalTypes.DISABLE_USER:
        return (
          <div>
            Are you sure you want to deactivate the user&#39;s login account?
            Note: this will prevent the user to login.
          </div>
        );
      case modalTypes.ENABLE_USER:
        return (
          <div>
            Are you sure you want to activate the user&#39;s login account?
            Note: this will enable the user to login.
          </div>
        );

      case modalTypes.REMOVE_START_DATE:
        return (
          <div>Are you sure you want to remove start date for this meter?</div>
        );
      case modalTypes.REMOVE_END_DATE:
        return (
          <div>Are you sure you want to remove end date for this meter?</div>
        );
      default:
        return "";
    }
  }

  render() {
    let meterAddr;
    const {
      member,
      adminUser,
      onApplyAddFunds,
      onApplyRefund,
      onUserUpdate,
      onMemberUpdate,
      onChangeExemptionCode,
      onMeterUpdate,
      classes,
    } = this.props;

    const userID = member.get("userID");
    const allMetersDisconnected = member
      .get("meters")
      .every((m) => m.get("status").get("status") === "disconnected");

    if (member.get("meters") && member.get("meters").first()) {
      //this.state.exemptionCode = member.get("meters").first().get("meta_data").get("tax_exemption_code");
      meterAddr = (
        <span>
          {member.get("meters").first().get("service_address").get("lineOne")}{" "}
          {member.get("meters").first().get("service_address").get("city")}
          {", "}
          {member
            .get("meters")
            .first()
            .get("service_address")
            .get("state")}{" "}
          {member
            .get("meters")
            .first()
            .get("service_address")
            .get("postalCode")}{" "}
          {member.get("meters").first().get("service_address").get("country")}{" "}
        </span>
      );
    } else {
      meterAddr = <span />;
    }

    return (
      <Elements>
        <form noValidate autoComplete="off">
          <Modal
            isOpen={member.get("isFetching")}
            contentLabel="Minimal Modal Example"
            style={modalStyle.loadingModal}
          >
            <ClipLoader
              sizeUnit="px"
              size={150}
              color="#ED0874"
              loading={member.get("isFetching")}
            />
          </Modal>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.props.taxInfo.showSnackBar}
            autoHideDuration={3000}
            onClose={this.handleClose}
          >
            {this.props.taxInfo.successMessage ? (
              <span id="message-id" className={confirmationSuccess}>
                {this.props.taxInfo.successMessage}
              </span>
            ) : (
              <span id="message-id" className={confirmationError}>
                {this.props.taxInfo.errorMessage}
              </span>
            )}
          </Snackbar>
          <Modal isOpen={this.state.showModal} style={modalStyle.modal}>
            {this.renderModalText()}
            <Button
              style={modalStyle.negativeButton}
              onClick={this.handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              style={modalStyle.positiveButton}
              onClick={this.handleModalConfirm}
            >
              Confirm
            </Button>
          </Modal>
          <Grid alignItems="flex-start" container>
            {/* Left coulumn */}
            <List>
              <h4
                style={{
                  marginLeft: "25px",
                  display: "inline-block",
                }}
              >
                Primary Account Holder
              </h4>
              {member.get("meterName") && (
                <h6
                  style={{
                    marginLeft: "15px",
                    display: "inline-block",
                  }}
                >
                  (Meter: {member.get("meterName")})
                </h6>
              )}
              {/* Primary Account Holder */}
              <ListItem divider>
                <ListItemText primary="Name: " />
                {this.state.nameEdit ? (
                  <div>
                    <TextField
                      autoFocus
                      label="First Name"
                      id="firstName"
                      defaultValue={member.get("first_name")}
                      onChange={this.handleChange("firstName")}
                      onKeyPress={this.pressEnter(
                        onMemberUpdate,
                        this.props.params.memberId,
                        "first_name",
                        this.state.firstName,
                        this.handleToggle("nameEdit")
                      )}
                      value={this.state.firstName}
                      type="string"
                    />
                    <TextField
                      label="Last Name"
                      id="lastName"
                      defaultValue={member.get("last_name")}
                      onChange={this.handleChange("lastName")}
                      onKeyPress={this.pressEnter(
                        onMemberUpdate,
                        this.props.params.memberId,
                        "last_name",
                        this.state.lastName,
                        this.handleToggle("nameEdit")
                      )}
                      value={this.state.lastName}
                      type="string"
                    />
                  </div>
                ) : (
                  `${member.get("first_name")} ${member.get("last_name")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("nameEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Email: " />
                {this.state.emailEdit ? (
                  <div>
                    <ListItem divider>
                      <TextField
                        autoFocus
                        label="Email: "
                        id="email"
                        defaultValue={member.get("email")}
                        onChange={this.handleChange("email")}
                        onKeyPress={this.pressEnter(
                          onUserUpdate,
                          userID,
                          "email",
                          this.state.email,
                          this.handleToggle("emailEdit")
                        )}
                        value={this.state.email}
                        type="string"
                      />
                    </ListItem>
                  </div>
                ) : (
                  `${member.get("email")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("emailEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Phone: " />
                {this.state.phoneEdit ? (
                  <div>
                    <ListItem divider>
                      <TextField
                        autoFocus
                        label="Phone: "
                        id="phone"
                        defaultValue={member.get("phone")}
                        onChange={this.handleChange("phone")}
                        onKeyPress={this.pressEnter(
                          onUserUpdate,
                          userID,
                          "phone",
                          this.state.phone,
                          this.handleToggle("phoneEdit")
                        )}
                        value={this.state.phone}
                        type="string"
                      />
                    </ListItem>
                  </div>
                ) : (
                  `${member.get("phone")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("phoneEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Birthday: " />
                {this.state.birthdayEdit ? (
                  <div>
                    <ListItem divider>
                      <KeyboardDatePicker
                        margin="normal"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        value={
                          (this.state.birthday &&
                            moment.utc(this.state.birthday).format("l LT")) ||
                          null
                        }
                        onChange={this.handleDateChange(`birthday`)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        onKeyPress={this.pressEnter(
                          onMemberUpdate,
                          this.props.params.memberId,
                          "birthday",
                          this.state.birthday,
                          this.handleToggle("birthdayEdit")
                        )}
                      />
                    </ListItem>
                  </div>
                ) : (
                  <Moment tz="Greenwich" format="MM/DD/YYYY">
                    {member.get("birthday")}
                  </Moment>
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("birthdayEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Enrollment Consent to Agreement: " />
                <Moment format="MM/DD/YYYY HH:MM:SS">
                  {member.get("created_date")}
                </Moment>{" "}
                UTC
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Enrollment Confirmation #: " />
                <Moment tz="America/Los_Angeles" format="YYYYMMDDHHMMSS">
                  {member.get("created_date")}
                </Moment>
                {member.get("userID")}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Account Number: " />
                {member.get("userID")}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="MemberID: " />
                {member.get("memberID")}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Critical Care / Chronic Condition: " />
                {member.get("critical_care") ? "yes" : "no"}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Balance: " />
                {`$${member.get("account_balance")} + $${member.get(
                  "account_pending_balance"
                )} pending`}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("balanceEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {this.state.balanceEdit ? (
                <div>
                  <ListItem divider>
                    <TextField
                      autoFocus
                      label="Add Funds"
                      id="addFunds"
                      onChange={this.handleChange("addFunds")}
                      onKeyPress={this.pressEnter(
                        onApplyAddFunds,
                        this.props.params.memberId,
                        "new_payment_amount",
                        this.state.addFunds,
                        this.handleToggle("balanceEdit")
                      )}
                      value={this.state.addFunds}
                      type="string"
                    />
                    <Button
                      color="primary"
                      onClick={() => this.handleOpenModal(modalTypes.ADD_FUND)}
                    >
                      Save
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.handleToggle("balanceEdit")}
                    >
                      Cancel
                    </Button>
                  </ListItem>
                  <ListItem divider>
                    <TextField
                      label="Apply Refund"
                      id="applyRefund"
                      onChange={this.handleChange("applyRefund")}
                      onKeyPress={this.pressEnter(
                        onApplyRefund,
                        this.props.params.memberId,
                        "amount_dollars",
                        this.state.applyRefund,
                        this.handleToggle("balanceEdit")
                      )}
                      value={this.state.applyRefund}
                      type="string"
                    />
                    <Button
                      color="primary"
                      onClick={() => this.handleOpenModal(modalTypes.REFUND)}
                    >
                      Save
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.handleToggle("balanceEdit")}
                    >
                      Cancel
                    </Button>
                  </ListItem>
                </div>
              ) : null}

              <ListItem divider>
                <ListItemText primary="Recharge Amt: " />
                {this.state.rechargeEdit ? (
                  <div>
                    <ListItem divider>
                      <TextField
                        autoFocus
                        label="Recharge Amount"
                        id="rechargeAmount"
                        defaultValue={member.get("recharge_dollars")}
                        onChange={this.handleChange("rechargeAmount")}
                        onKeyPress={this.pressEnter(
                          onMemberUpdate,
                          this.props.params.memberId,
                          "recharge_dollars",
                          this.state.rechargeAmount,
                          this.handleToggle("rechargeEdit")
                        )}
                        value={this.state.rechargeAmount}
                        type="string"
                      />
                    </ListItem>
                  </div>
                ) : (
                  `$${member.get("recharge_dollars")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("rechargeEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Address: " />
                {meterAddr}
              </ListItem>
              <List>
                {member.get("meters").map((meter, index) => (
                  <div key={index}>
                    <List>
                      <ListItem>
                        <ListItemText primary="Est. Recharge Date: " />
                        <Moment
                          tz={meter
                            .get("service_address")
                            .get("time_zone_name")}
                          format="MM/DD/YYYY"
                        >
                          {member.get("est_top_off_date")}
                        </Moment>
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Tax Exemption" />
                        {this.props.taxInfo.exemptionList !== null &&
                        this.props.taxInfo.exemptionList.length > 0 ? (
                          <FormControl style={{ width: "200px" }}>
                            <InputLabel htmlFor="tax-exemption-code">
                              Exemption Code
                            </InputLabel>
                            <Select
                              value={
                                meter
                                  .get("meta_data")
                                  .get("tax_exemption_code") || ""
                              }
                              onChange={this.handleExemptionChange(
                                onChangeExemptionCode,
                                meter.get("ID"),
                                member.get("meters")
                              )}
                              inputProps={{
                                name: "exemptionCode",
                                id: "tax-exemption-code",
                              }}
                              id="exemptionCode"
                            >
                              {this.props.taxInfo.exemptionList.map(
                                (excemption, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={excemption.code}
                                    >
                                      {excemption.code} -{" "}
                                      {excemption.description}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        ) : (
                          <div>Unavailable - (Try to reload)</div>
                        )}
                      </ListItem>

                      <ListItem divider>
                        <ListItemText primary="Enrollment completed: " />
                        <Moment format="MM/DD/YYYY HH:MM:SS Z">
                          {meter.get("enrollment_date")}
                        </Moment>
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Consent to Critical Care / Chronic Condition: " />
                        <Moment format="MM/DD/YYYY HH:MM:SS Z">
                          {meter.get("enrollment_date")}
                        </Moment>
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="ESI: " />
                        {meter.get("meterID")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="ID: " />
                        {meter.get("ID")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Meter Status: " />
                        {meter.get("status").get("status")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Load Zone: " />
                        {meter.get("settlement_point")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Meter Type: " />
                        {meter.get("tdsp_ams_indicator")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Switch Hold: " />
                        {meter.get("switch_hold") ? "True" : "False"}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Requested Start Date: " />
                        {meter.get("requested_start_date")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Start Date: " />
                        <KeyboardDatePicker
                          margin="normal"
                          format="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          onChange={this.handleChange(
                            `startDateMeter_${index}`
                          )}
                          value={
                            (this.state[`startDateMeter_${index}`] &&
                              moment
                                .utc(this.state[`startDateMeter_${index}`])
                                .format("l LT")) ||
                            null
                          }
                          onChange={this.handleDateChange(
                            `startDateMeter_${index}`
                          )}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onKeyPress={this.pressEnter(
                            onMeterUpdate,
                            meter.get("ID"),
                            "start_date",
                            `${moment(
                              this.state[`startDateMeter_${index}`]
                            ).format("yyyy-MM-DD")}T00:00:00Z`,
                            this.handleToggle()
                          )}
                        />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="End Date: " />
                        <KeyboardDatePicker
                          margin="normal"
                          format="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          value={
                            (this.state[`endDateMeter_${index}`] &&
                              moment
                                .utc(this.state[`endDateMeter_${index}`])
                                .format("l LT")) ||
                            null
                          }
                          onChange={this.handleDateChange(
                            `endDateMeter_${index}`
                          )}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onKeyPress={this.pressEnter(
                            onMeterUpdate,
                            meter.get("ID"),
                            "end_date",
                            `${moment(
                              this.state[`endDateMeter_${index}`]
                            ).format("yyyy-MM-DD")}T00:00:00Z`,
                            this.handleToggle()
                          )}
                        />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Customer Type: " />
                        {meter.get("polr_customer_class")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="TDSP: " />
                        {meter.get("duns_number")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Enrollment Type (Switch / Move-in): " />
                        {meter.get("enrollMode")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Billing Account ID: " />
                        {member.get("accountID")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Stripe Account ID: " />
                        {member.get("stripeAccountId")}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Auto Recharge Status: " />
                        {member.get("active") ? "Enabled" : "Disabled"}
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="User Login Status: " />
                        {member.get("userIsActive") ? "Enabled" : "Disabled"}
                      </ListItem>
                    </List>
                  </div>
                ))}
              </List>
              {!member.get("active") && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  onClick={() => this.handleOpenModal(modalTypes.OPEN_ACCOUNT)}
                >
                  Activate Recharge
                </Button>
              )}
              {member.get("active") && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  onClick={() => this.handleOpenModal(modalTypes.CLOSE_ACCOUNT)}
                >
                  Deactivate Recharge
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                style={{ margin: "3px" }}
                onClick={() =>
                  this.handleOpenModal(modalTypes.DEACTIVATE_ACCOUNT)
                }
                disabled={!allMetersDisconnected}
              >
                Refund Account
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{ margin: "3px" }}
                onClick={() => this.props.onResetPassword(member.get("email"))}
              >
                Reset Password
              </Button>
              {member.get("userIsActive") && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  onClick={() => this.handleOpenModal(modalTypes.DISABLE_USER)}
                >
                  DISABLE USER
                </Button>
              )}
              {!member.get("userIsActive") && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  onClick={() => this.handleOpenModal(modalTypes.ENABLE_USER)}
                >
                  ENABLE USER
                </Button>
              )}
            </List>
            {/* Right coulumn */}
            <List>
              <h4 style={{ marginLeft: "25px" }}> Secondary Account Holder </h4>
              {/* Secondayr Account Holder */}
              <div style={{ marginLeft: "15px" }}>
                <ListItem divider>
                  <ListItemText primary="Name: " />
                  {this.state.secondaryNameEdit ? (
                    <div>
                      <TextField
                        autoFocus
                        label="Name"
                        id="secondaryName"
                        defaultValue={
                          member.get("secondary_account_holder")
                            ? member.get("secondary_account_holder").get("name")
                            : ""
                        }
                        onChange={this.handleChange("secondaryName")}
                        onKeyPress={this.pressEnter(
                          onMemberUpdate,
                          this.props.params.memberId,
                          "secondary_account_holder",
                          {
                            value: {
                              name: this.state.secondaryName,
                              email: member.get("secondary_account_holder")
                                ? member
                                    .get("secondary_account_holder")
                                    .get("email")
                                : "",
                              phone: member.get("secondary_account_holder")
                                ? member
                                    .get("secondary_account_holder")
                                    .get("phone")
                                : "",
                              birthday: member.get("secondary_account_holder")
                                ? member
                                    .get("secondary_account_holder")
                                    .get("birthday")
                                : "",
                            },
                            title: this.state.secondaryName,
                            property: "name",
                          },
                          this.handleToggle("secondaryNameEdit")
                        )}
                        value={this.state.secondaryName}
                        type="string"
                      />
                    </div>
                  ) : (
                    `${
                      member.get("secondary_account_holder")
                        ? member.get("secondary_account_holder").get("name")
                        : ""
                    }`
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryNameEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Email: " />
                  {this.state.secondaryEmailEdit ? (
                    <div>
                      <ListItem divider>
                        <TextField
                          autoFocus
                          label="Email: "
                          id="secondaryEmail"
                          defaultValue={
                            member.get("secondary_account_holder")
                              ? member
                                  .get("secondary_account_holder")
                                  .get("email")
                              : ""
                          }
                          onChange={this.handleChange("secondaryEmail")}
                          onKeyPress={this.pressEnter(
                            onMemberUpdate,
                            this.props.params.memberId,
                            "secondary_account_holder",
                            {
                              value: {
                                name: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("name")
                                  : "",
                                email: this.state.secondaryEmail,
                                phone: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("phone")
                                  : "",
                                birthday: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("birthday")
                                  : "",
                              },
                              title: this.state.secondaryEmail,
                              property: "email",
                            },
                            this.handleToggle("secondaryEmailEdit")
                          )}
                          value={this.state.secondaryEmail}
                          type="string"
                        />
                      </ListItem>
                    </div>
                  ) : (
                    `${
                      member.get("secondary_account_holder")
                        ? member.get("secondary_account_holder").get("email")
                        : ""
                    }`
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryEmailEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Phone: " />
                  {this.state.secondaryPhoneEdit ? (
                    <div>
                      <ListItem divider>
                        <TextField
                          autoFocus
                          label="Phone: "
                          id="secondaryPhone"
                          defaultValue={
                            member.get("secondary_account_holder")
                              ? member
                                  .get("secondary_account_holder")
                                  .get("phone")
                              : ""
                          }
                          onChange={this.handleChange("secondaryPhone")}
                          onKeyPress={this.pressEnter(
                            onMemberUpdate,
                            this.props.params.memberId,
                            "secondary_account_holder",
                            {
                              value: {
                                name: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("name")
                                  : "",
                                email: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("email")
                                  : "",
                                phone: this.state.secondaryPhone,
                                birthday: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("birthday")
                                  : "",
                              },
                              title: this.state.secondaryPhone,
                              property: "phone",
                            },
                            this.handleToggle("secondaryPhoneEdit")
                          )}
                          value={this.state.secondaryPhone}
                          type="string"
                        />
                      </ListItem>
                    </div>
                  ) : (
                    `${
                      member.get("secondary_account_holder")
                        ? member.get("secondary_account_holder").get("phone")
                        : ""
                    }`
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryPhoneEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Birthday: " />
                  {this.state.secondaryBirthdayEdit ? (
                    <div>
                      <ListItem divider>
                        <KeyboardDatePicker
                          margin="normal"
                          format="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          value={
                            (this.state.secondaryBirthday &&
                              moment
                                .utc(this.state.secondaryBirthday)
                                .format("l LT")) ||
                            null
                          }
                          onChange={this.handleDateChange(`secondaryBirthday`)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onKeyPress={this.pressEnter(
                            onMemberUpdate,
                            this.props.params.memberId,
                            "secondary_account_holder",
                            {
                              value: {
                                name: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("name")
                                  : "",
                                email: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("email")
                                  : "",
                                phone: member.get("secondary_account_holder")
                                  ? member
                                      .get("secondary_account_holder")
                                      .get("phone")
                                  : "",
                                birthday: this.state.secondaryBirthday,
                              },
                              title: this.state.secondaryBirthday,
                              property: "birthday",
                            },
                            this.handleToggle("secondaryBirthdayEdit")
                          )}
                        />
                      </ListItem>
                    </div>
                  ) : (
                    member
                      ?.get("secondary_account_holder")
                      ?.get("birthday") && (
                      <Moment tz="Greenwich" format="MM/DD/YYYY">
                        {member.get("secondary_account_holder")
                          ? member
                              .get("secondary_account_holder")
                              .get("birthday")
                              .split("T")[0]
                          : null}
                      </Moment>
                    )
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryBirthdayEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
              <CreditCardForm />
              {member.get("accountID") && <AccountFlags />}
              {member.get("relatedMembers") && (
                <RelatedMembers
                  members={member.get("relatedMembers")}
                  meters={member.get("relatedMeters")}
                />
              )}
            </List>
          </Grid>
        </form>
      </Elements>
    );
  }
}

MemberDetails.propTypes = {
  member: PropTypes.object,
  params: PropTypes.object.isRequired,
  onApplyRefund: PropTypes.func.isRequired,
  onApplyAddFunds: PropTypes.func.isRequired,
  onCloseAccount: PropTypes.func.isRequired,
  onOpenAccount: PropTypes.func.isRequired,
  onfetchFullMemberInfoNeeded: PropTypes.func.isRequired,
  onFetchUserIDWithFullMemberInfoNeeded: PropTypes.func.isRequired,
  onClearConfMsg: PropTypes.func.isRequired,
  onUserUpdate: PropTypes.func.isRequired,
  onMemberUpdate: PropTypes.func.isRequired,
  onRefundAndClose: PropTypes.func.isRequired,
  onChangeExemptionCode: PropTypes.func.isRequired,
  requestTaxExcemptions: PropTypes.func.isRequired,
};

MemberDetails.defaultProps = {
  member: {},
};


const mapStateToProps = (state) => ({
  member: state.selectedMember,
  adminUser: state.user,
  taxInfo: (selectTaxInfoContainer(state))(state)
});

const mapDispatchToProps = (dispatch) => ({
  onApplyRefund: (memId, property, value) =>
    dispatch(applyRefund(memId, property, value)),
  onApplyAddFunds: (memId, property, value) =>
    dispatch(applyAddFunds(memId, property, value)),
  onCloseAccount: (memId) => dispatch(closeAccount(memId)),
  onRefundAndClose: (memId) => dispatch(refundAndCloseAccount(memId)),
  onOpenAccount: (memId) => dispatch(activateAccount(memId)),
  onfetchFullMemberInfoNeeded: (member) =>
    dispatch(fetchFullMemberInfoNeeded(member)),
  onFetchUserIDWithFullMemberInfoNeeded: (member) =>
    dispatch(fetchUserIDWithFullMemberInfoNeeded(member)),
  onClearConfMsg: () => dispatch(clearConfMsg()),
  onUserUpdate: (userId, property, value) =>
    dispatch(userUpdate(userId, property, value)),
  onMemberUpdate: (memberId, property, value, secondaryObject) =>
    dispatch(memberUpdate(memberId, property, value, secondaryObject)),
  onChangeExemptionCode: (meters, meterId, exemptionCode) =>
    dispatch(changeExemptionCode(meters, meterId, exemptionCode)),
  onMeterUpdate: (meterID, property, value) =>
    dispatch(meterUpdate(meterID, property, value)),
  onResetPassword: (email) => dispatch(resetPassword(email)),
  onActivateUser: (userID) => dispatch(activateUser(userID)),
  onDeActivateUser: (userID) => dispatch(deActivateUser(userID)),
  onClearSelectedMember: () => {
    dispatch(clearSelectedMember());
  },
  requestTaxExcemptions: () => dispatch(requestTaxExcemptions()),
  onHideSnackBar: () => dispatch(hideTaxSnackBar()),
  dispatch,
});

const ConnectedMemberDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDetails);

export default withStyles(styles)(ConnectedMemberDetails);
