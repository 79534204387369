import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const UserLoginInfo = ({
    userID,
    email,
    phone,
    canCreateUser,
    getUserByEmail,
    createUser,
    handleChange,
    isNewUser,
}) => {
    return (
        <div style={{ width: "100%" }}>
            <List>
                <h4
                    style={{
                        marginLeft: "25px",
                        display: "inline-block",
                    }}
                >
                    User Login
                </h4>
                <ListItem>
                    {!userID && (
                        <div style={{ width: "100%" }}>
                            <TextField
                                autoFocus
                                name="email"
                                label="Email"
                                onChange={handleChange}
                                value={email}
                                type="string"
                                style={{ width: "20%" }}
                            />

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    getUserByEmail();
                                }}
                                style={{ margin: 20 }}
                            >
                                Validate Email
                            </Button>
                        </div>
                    )}
                    {userID && `Email: ${email}`}
                </ListItem>
                {!userID && isNewUser && (
                    <ListItem>
                        <TextField
                            name="phone"
                            label="Phone"
                            onChange={handleChange}
                            value={phone}
                            type="string"
                        />
                    </ListItem>
                )}
            </List>
            {canCreateUser && (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        createUser();
                    }}
                    style={{ marginLeft: 20 }}
                >
                    Create User
                </Button>
            )}
        </div>
    );
};

UserLoginInfo.propTypes = {
    canCreateUser: PropTypes.any,
    createUser: PropTypes.func,
    email: PropTypes.any,
    getUserByEmail: PropTypes.func,
    handleChange: PropTypes.any,
    phone: PropTypes.any,
    resetPassword: PropTypes.func,
    userID: PropTypes.any,
};

export default UserLoginInfo;
