import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestUsage() {
    return {
        type: constants.REQUEST_USAGE,
        isFetching: true,
    };
}

function recieveUsage(data) {
    return {
        type: constants.RECEIVE_USAGE,
        isFetching: false,
        data,
    };
}

export default function getUsage(memberID, guestUserID) {
    return (dispatch) => {
        dispatch(requestUsage());

        const meterQuery = {
            memberID,
            include_inactive: false,
        };

        const searchObj = {
            iana_timezone: "America/Chicago",
            load_zone: "LZ_HOUSTON",
        };

      	
		  	const endpointMeter = guestUserID ? constants.GUEST_METER_SEARCH_URL : constants.METER_SEARCH_URL;
		  	const endpointUsage = guestUserID ? constants.GUEST_GET_USAGE : constants.GET_USAGE;

        return fetch(endpointMeter, meterQuery)
            .then((json) => {
                searchObj.meterID = json.meters[0].ID;
                return fetch(endpointUsage, searchObj).then(data => dispatch(recieveUsage(data)));
            });
    };
}
