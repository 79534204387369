import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { noselect } from "../../styles/common.css";

const useStyles = makeStyles({
  tableContainer: {
    margin: "15px",
    width: "auto !important",
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      padding: "10px",
      fontSize: "14px",
      color: "white",
      backgroundColor: "#333",
      whitespace: "pre",
    },
  },
  chargesTotalRow: {
    borderTop: "2px solid rgba(0, 0, 0, 0.35)",
    "& #total-label-cell": {
      fontWeight: "bold",
      textAlign: "end",
    },
    "& #total-label-cell, #total-cell": {
      fontSize: "15px",
      paddingLeft: "10px",
    },
  },
  overflowFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: "10px",
    position: "relative",
    top: "-100px",
    marginBottom: "-100px",
    "& #overflowObject": {
      height: "100px",
      width: "100%",
      transition: "all 0.3s linear",
      backgroundImage: "linear-gradient(180deg, transparent, white)",
    },
    "& #overflowOptions": {
      background: "white",
      display: "flex",
      justifyContent: "flex-end",
      padding: "5px 0px",
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        color: "black",
        transition: "all 0.3s ease",
        "&:hover": {
          color: "grey",
        },
      },
    },
  },
});

const ChargesByUsageTable = (props) => {
  const classes = useStyles();
  const { details, money } = props;
  const tableView = useRef();
  const [toggleOverflowFooter, setToggleOverFlowFooter] = useState(false);
  const TABLE_HEIGHT = 550;
  useEffect(() => {
    // Add fading footer if the scroll height exceeds the table height
    const scrollHeight = tableView.current.scrollHeight;
    if (scrollHeight > TABLE_HEIGHT) {
      setToggleOverFlowFooter(true);
    } else {
      setToggleOverFlowFooter(false);
    }
  }, [tableView]);

  // When reaching the end of the scroll amount, clear out the fading footer
  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === TABLE_HEIGHT) {
      setToggleOverFlowFooter(false);
    } else {
      setToggleOverFlowFooter(true);
    }
  };

  return (
    <div>
      <div
        onScroll={(e) => handleScroll(e)}
        ref={tableView}
        style={{
          height: "550px",
          overflow: "auto",
        }}
      >
        <Table className={classes.tableContainer} stickyHeader>
          <TableHead className={`${classes.tableHeaderRow} ${noselect}`}>
            <TableRow>
              <TableCell align="center">Operating Date</TableCell>
              <TableCell align="center">Usage</TableCell>
              <TableCell align="center">Electricity Usage</TableCell>
              <TableCell align="center">Ancillary Services</TableCell>
              <TableCell align="center">TDU Delivery Charges</TableCell>
              <TableCell align="center">Griddy Membership</TableCell>
              <TableCell align="center">Tax &amp; Fees</TableCell>
              <TableCell align="center">Other</TableCell>
              <TableCell align="center">All in Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details && details.length > 0 ? (
              details.map((transactions, index) => {
                const {
                  transaction_date,
                  usage_kwh,
                  electricity_usage,
                  ancillary_services,
                  tdu_delivery_charges,
                  griddy_membership,
                  taxes_and_fees,
                  other,
                  all_in_rate,
                } = transactions;

                return (
                  <TableRow key={index}>
                    <TableCell style={{ width: 65 }}>
                      {moment(transaction_date.substring(0, 10)).format(
                        "YYYY-MM-DD"
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {usage_kwh}
                      {" kwh"}
                    </TableCell>
                    <TableCell align="center">
                      {money(electricity_usage)}
                    </TableCell>
                    <TableCell align="center">
                      {money(ancillary_services)}
                    </TableCell>
                    <TableCell align="center">
                      {money(tdu_delivery_charges)}
                    </TableCell>
                    <TableCell align="center">
                      {money(griddy_membership)}
                    </TableCell>
                    <TableCell align="center">
                      {money(taxes_and_fees)}
                    </TableCell>
                    <TableCell align="center">{money(other)}</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      {(all_in_rate * 100).toFixed(1)}
                      {" c/kwh"}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9}>No Records Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {/* Footer Fade Tempoarily Removed due to different browser views */}
      {false && (
        <div className={classes.overflowFooter}>
          <div id="overflowObject"></div>
        </div>
      )}
    </div>
  );
};

export default ChargesByUsageTable;
